/* eslint-disable no-console */
import { get } from 'lodash';
import {
  SET_ALERT,
  FETCH_SYSTEM_ANNOUNCEMENTS_LOADING,
  FETCH_SYSTEM_ANNOUNCEMENTS_SUCCESS,
  FETCH_SYSTEM_ANNOUNCEMENTS_ERROR,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_LOADING,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_SUCCESS,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_ERROR,
  FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_LOADING,
  FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_SUCCESS,
  FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_ERROR,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_LOADING,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_SUCCESS,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_ERROR,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};
const initialState = {
  isVisible: false,
};

export const allSystemAnnouncements = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_SYSTEM_ANNOUNCEMENTS_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_SYSTEM_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getAllSystemAnnouncementsV2'),
      };
    case FETCH_SYSTEM_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const currentSystemAnnouncement = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getCurrentSystemAnnouncementV2'),
      };
    case FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const currentSystemAnnouncementByTarget = (
  state = DEFAULT_STATE,
  action
) => {
  switch (action.type) {
    case FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getCurrentSystemAnnouncementV2'),
      };
    case FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allSystemAnnouncementTargets = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getAllSystemAnnouncementTargets'),
      };
    case FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const announcementAlert = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        isVisible: action.value,
      };
    default:
      return state;
  }
};
