import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { DEFAULT_ITEMS_PER_PAGE } from 'utils/constants';
import { replaceUrlParam } from '../../../utils';
import { PARENT_INTERACTION_DETAILS } from '../../../routes/constants';
import DatadogHandler from '../../../utils/datadog';

const ParentInteractionHandler = props => {
  const {
    fetchAllPIMCentres,
    urlParams,
    changeParam,
    history,
    t,
    pimCentres,
    totalCount,
  } = props;
  const [showTableLoader, setShowTableLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { activeSort, page, searchVal } = urlParams;
  const searchTimer = value => {
    setSearchText(value);
  };

  const handleChange = e => {
    const { name, value = '' } = e.target;
    changeParam(
      {
        [name]: value,
        page: 1,
        perPage: DEFAULT_ITEMS_PER_PAGE,
      },
      () => {
        if (name === 'searchVal') {
          searchTimer(value);
        }
      }
    );
  };

  const handleSearchClear = () => {
    changeParam(
      {
        searchVal: '',
      },
      () => {
        setSearchText('');
      }
    );
  };

  const fetchCentres = async (sortVal = '', rPage, rPerPage) => {
    const pagination = {
      page: rPage || page,
      perPage: rPerPage || DEFAULT_ITEMS_PER_PAGE,
    };
    setShowTableLoader(true);
    if (sortVal || activeSort) {
      pagination.sort = [sortVal || get(activeSort.split('---'), '[0]')];
    }
    try {
      await fetchAllPIMCentres({
        pagination,
        searchTerm: {
          NameOrCodeLike: searchVal,
        },
      });
      setShowTableLoader(false);
    } catch (ex) {
      DatadogHandler.sendLog(get(ex, 'message', {}, 'error'));
    }
  };

  const handleUpdateCentre = rowData => {
    history.push({
      pathname: replaceUrlParam(PARENT_INTERACTION_DETAILS, {
        id: rowData.ID,
      }),
      state: {
        data: rowData,
      },
    });
  };

  const onPaginationChanged = (rPage, rPerPage) => {
    changeParam(
      {
        page: rPage,
        perPage: rPerPage,
      },
      () => {
        fetchCentres('', rPage, rPerPage);
      }
    );
  };

  const redirectToCentreDetailsPage = centre => {
    const id = centre.ID;
    // change to pim url
    history.push({
      pathname: replaceUrlParam('/parentInteraction/:id', {
        id,
      }),
    });
  };

  useEffect(() => {
    fetchCentres();
    return () => {};
  }, []);

  useEffect(() => {
    if (searchText !== undefined) {
      fetchCentres();
    }
  }, [searchVal]);

  return {
    showLoader,
    handleChange,
    handleSearchClear,
    showTableLoader,
    redirectToCentreDetailsPage,
    onPaginationChanged,
    handleUpdateCentre,
    setShowLoader,
    t,
    pimCentres,
    totalCount,
    urlParams,
  };
};
export default ParentInteractionHandler;
