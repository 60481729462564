import get from 'lodash/get';

const getFieldValue = (allSystemAnnouncements, itemId) =>
  get(allSystemAnnouncements, 'data.length')
    ? allSystemAnnouncements.data
        .filter(
          item =>
            item &&
            item.ID === itemId &&
            item.systemAnnouncementTargetMappings &&
            item.systemAnnouncementTargetMappings.data
        )
        .map(t => {
          const values = t.systemAnnouncementTargetMappings.data.map(
            mapping => ({
              value: mapping.systemAnnouncementTarget.ID,
            })
          );
          return values;
        })
        .flat()
    : [];

export default getFieldValue;
