import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import { has, get } from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  Input,
  Grid,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { withAuth0 } from '@auth0/auth0-react';
import Dialog from 'components/common/Modal';
import { showSnackBarMessage as showSnackBarMessageAction } from 'redux/actions';
import moment from 'moment';

import { CheckBoxLabel } from 'components/common/InputFields';
import {
  loginUser,
  loginForEpToken,
  linkUserWithLinkPassAndLogin as linkUserWithLinkPassAndLoginAction,
  updateElectronicConsentStatus as updateElectronicConsentStatusAction,
  linkpassSn2UserInfo,
  forgotPassword,
  registerPassword,
  resetPassword,
  validateUser,
  getSchoolOtpValidate,
  sendOtp,
  validateOtp,
  getSSOURL,
} from '../../../redux/actions';
import ForgotPassword from './ForgotPassword';
import PasswordField from './PasswordField';
import BtnComp from './BtnComp';
import Loader from '../../common/Loader';
import ActionType from './helper';
import SubmitAction from './buttonActions';

import {
  tokenString,
  tokenData,
  getPrimaryColor,
  getQueryParams,
  isStaffPortal,
  getSchoolShortName,
} from '../../../utils';
import {
  LOGIN,
  FORGOT,
  CONNECT_TO_MFS_ACCOUNT,
  OVERVIEW,
  REGISTER,
  RESET,
} from '../../../routes/constants';
import Text from '../../common/Text';
import {
  DEFAULT_ERROR,
  SAVE_DATE_FORMAT,
  schoolIDMap,
} from '../../../utils/constants';

import Linkpass from './LinkPass/index';
import ContactDetailsNotMatch from './ContactDetailsNotMatch';
import { getLinkpassInfo, getSn2UserInfo } from './LinkpassHandler';
import localStorage from '../../../utils/localStorage';
import ImageLazyLoader from '../../common/ImageLazyLoader';

const isStaffDomain = isStaffPortal();

const getSchoolName = schoolID => {
  const currentSchoolDetails = schoolIDMap[schoolID];
  const schoolName = get(currentSchoolDetails, 'name');
  return schoolName;
};
class Login extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      isAccountDetailsNotMatch: false,
      loginLabel: isStaffDomain
        ? t('auth.staffSignIn')
        : t('auth.parentPortal'),
      isConsentECDA: null,
      email: '',
      password: '',
      oneTimePsd: '',
      userId: '',
      confirmPassword: '',
      formIsValid: false,
      errors: {},
      dialogTitle: '',
      otpValidated: false,
      dialogMsg: '',
      disableEmailInput: false,
      actionType: '',
      showPassword: false,
      showConfirmPassword: false,
      formLabel: '',
      dialogPrimaryClick: null,
      dialogSecondaryClick: null,
      emailInputLabel: '',
      isOpen: false,
      resendButtonDisable: false,
      disableSSO: false,
      resendOTPInterval: 30,
      showLinkPassLoginOption: true,
      isOpenLinkpassAccountAlreadyInUseModal: false,
      errorCode: '',
      showPasswordValidationInfo: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formFrag = this.formFrag.bind(this);
    this.hanldeOnClick = this.hanldeOnClick.bind(this);
    this.submitAction = SubmitAction.bind(this);
    this.setCompType = this.setCompType.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleClickShowConfirmPassword = this.handleClickShowConfirmPassword.bind(
      this
    );
    this.redirectSSO = this.redirectSSO.bind(this);
    this.countDownTimer = this.countDownTimer.bind(this);
    this.onLinkpassBtnClick = this.onLinkpassBtnClick.bind(this);
    this.onLoginWithEmail = this.onLoginWithEmail.bind(this);
    this.onProceedLinkAccount = this.onProceedLinkAccount.bind(this);
    this.onCancelContactDetailsNotMatch = this.onCancelContactDetailsNotMatch.bind(
      this
    );
  }

  componentDidMount() {
    const { location, history, validateUser } = this.props;
    const token = tokenString(location.search);
    let data = null;

    if (
      getQueryParams().linkFailError ||
      localStorage.getValue('linkFailError')
    ) {
      this.setState({
        isOpenLinkpassAccountAlreadyInUseModal: true,
      });
    }

    if (
      (!token || !token.token) &&
      location.pathname !== LOGIN &&
      location.pathname !== FORGOT &&
      location.pathname !== CONNECT_TO_MFS_ACCOUNT
    ) {
      history.push(LOGIN);
    } else if (token && token.token) {
      this.setState({
        registerToken: token.token,
        token: token.token,
      });
      localStorage.setValue('registerToken', token.token);
      data = tokenData(token.token);
      validateUser(token.token);
      if (!tokenData(token.token)) {
        history.push(LOGIN);
      }
    }
    if ((!token || !token.token) && location.pathname.includes(LOGIN)) {
      this.setCompType('authorization_token');
    }
    if (get(data, 'email') && data.id) {
      this.setCompType();
      this.setState({
        email: data.email,
        userId: data.id,
        disableEmailInput: true,
      });
      this.setCompType(data.type);
    }
    if (location.pathname === CONNECT_TO_MFS_ACCOUNT) {
      this.setState({
        showLinkPassLoginOption: false,
      });
      this.setCompType('connect_to_mfs_account');
    }
    if (location.pathname === FORGOT) {
      this.setState({
        showLinkPassLoginOption: false,
      });
    }
  }

  componentWillUnmount() {
    const { intervalID } = this.state;
    clearInterval(intervalID);
  }

  onLoginWithEmail() {
    this.setState({
      showLinkPassLoginOption: false,
      showPasswordValidationInfo: true,
    });
  }

  onCancelContactDetailsNotMatch() {
    const { auth0 } = this.props;
    const { logout } = auth0;
    localStorage.setValue('linkpassIDToken', null);
    logout({
      returnTo: window.location.origin,
    });
  }

  onProceedLinkAccount() {
    const {
      linkUserWithLinkPassAndLogin,
      t,
      showSnackBarMessage,
      auth0,
    } = this.props;
    const { email, password } = this.state;
    const linkpassIDToken = localStorage.getValue('linkpassIDToken');
    linkUserWithLinkPassAndLogin(email, password, linkpassIDToken).then(
      resp => {
        const { logout } = auth0;
        const errors = get(resp, 'error', null);
        if (!errors) {
          const userToken = get(resp, 'data.linkUserWithLinkPassAndLogin');
          localStorage.setValue('userToken', userToken);
          window.location.href = `${OVERVIEW}?linkedAccountSuccessfully=true`;
        } else {
          const errMsg = get(resp, 'error[0].message');
          const errorCode = get(resp, 'error[0].extensions.errorCode');

          localStorage.setValue('linkpassIDToken', null);

          errors.password = DEFAULT_ERROR;
          if (errMsg.includes('does not exist')) {
            errors.password = t('auth.userNotExistTryAgainMessage');
          } else if (errMsg.includes('incorrect password')) {
            errors.password = t('auth.enterRightPassword');
          } else if (
            errorCode === 'ERR_ACCOUNT_CONNECTED_TO_ANOTHER_LINKPASS'
          ) {
            // show immediately here
            this.setState({
              isOpenLinkpassAccountAlreadyInUseModal: true,
              errorCode: 'ERR_ACCOUNT_CONNECTED_TO_ANOTHER_LINKPASS',
            });
          } else {
            showSnackBarMessage(errMsg, 'error');
            logout({
              returnTo: window.location.origin,
            });
            this.setState({
              isAccountDetailsNotMatch: false,
            });
          }
          this.setState({
            errors,
          });
        }
      }
    );
  }

  onLinkpassBtnClick() {
    const { registerToken } = this.state;
    const { auth0 } = this.props;
    const { loginWithRedirect } = auth0;
    // Executing loginWithRedirect() redirects your users to the Auth0 Universal Login Page,
    //  where Auth0 can authenticate them. Upon successful authentication,
    // Auth0 will redirect your users back to your application.

    loginWithRedirect({ appState: { registerToken } });
  }

  setCompType(myTokenType) {
    const { t, schoolName } = this.props;
    const { loginLabel } = this.state;
    let myType = null;
    let label = null;
    let inputLabel = null;
    switch (myTokenType) {
      case 'reset_password_token':
        myType = 'reset';
        label = t('auth.createPassword');
        inputLabel = t('auth.loginID');
        this.setState({
          showLinkPassLoginOption: false,
          showPasswordValidationInfo: true,
        });
        break;
      case 'register_token':
        myType = 'register';
        label = t('auth.parentPortal');
        inputLabel = t('auth.loginID');

        break;
      case 'authorization_token':
        myType = 'login';
        label = loginLabel;
        inputLabel = t('auth.loginID');
        break;
      case 'connect_to_mfs_account':
        myType = 'connectToMFSAccount';
        label = t('auth.connectToMFSAccount', {
          schoolName,
        });
        inputLabel = t('auth.loginID');
        this.setState({
          showLinkPassLoginOption: false,
        });
        break;
      default:
        myType = 'login';
        label = this.loginLabel;
        inputLabel = t('auth.loginID');
    }
    this.setState({
      actionType: myType,
      formLabel: label,
      emailInputLabel: inputLabel,
    });
  }

  handleChange(e) {
    const { errors = {} } = this.state;
    const { name, value } = e.target;
    errors[e.target.name] = '';
    this.setState({ [name]: value }, () => {
      if (name === 'email') {
        const { email } = this.state;
        this.setState({ email: email.toLowerCase() });
      }
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const { validateData, t, location } = this.props;
    const { loginLabel } = this.state;
    // refer
    if (
      get(validateData, 'data.EConsentStatus') !==
      get(newProps.validateData, 'data.EConsentStatus')
    ) {
      this.setState({
        isConsentECDA: get(newProps.validateData, 'data.EConsentStatus'),
      });
    }
    const newPropsRegistered = get(newProps.validateData, 'data.registered');
    const oldPropsRegistered = get(validateData, 'data.registered');

    // reset the component back to login if this is an existing user
    if (
      !location?.pathname?.includes(RESET) &&
      newPropsRegistered !== oldPropsRegistered &&
      newPropsRegistered
    ) {
      this.setState({
        actionType: 'login',
        formLabel: loginLabel,
        emailInputLabel: t('auth.loginID'),
      });
    }
  }

  countDownTimer() {
    const { resendOTPInterval, errors, intervalID } = this.state;
    if (resendOTPInterval > 0) {
      this.setState({ resendOTPInterval: resendOTPInterval - 1 });
    } else {
      errors.oneTimePsd = '';
      this.setState(
        {
          resendButtonDisable: false,
          errors,
          resendOTPInterval: 30,
        },
        () => clearInterval(intervalID)
      );
    }
  }

  async resendCode(mode) {
    const { errors = {}, userId } = this.state;
    const { sendAndResentOtp, t } = this.props;
    const data = { userId };

    try {
      const response = await sendAndResentOtp(data);
      if (get(response, 'data.sendOTP')) {
        if (mode === 'resend') {
          errors.oneTimePsd = t('auth.newoneTimeOtp');
          errors.otpMisMatch = '';
          this.setState({
            errors,
            resendButtonDisable: true,
          });
        } else {
          errors.oneTimePsd = get(response, 'error[0]');
          this.setState({
            errors,
          });
        }
      }
    } catch (error) {
      errors.oneTimePsd = t('common.defaultErrorMessage');
      this.setState({
        errors,
      });
    }

    if (mode === 'resend') {
      const intervalID = setInterval(() => this.countDownTimer(), 1000);
      this.setState({
        intervalID,
      });
    }
  }

  async handleSubmit(e, action) {
    e.preventDefault();

    const { email, password, oneTimePsd, userId, confirmPassword } = this.state;
    const newState = Object.assign({}, this.state);
    const newErrors = Object.assign({}, newState.errors);
    const { validateOtpSend, forceOtp, t } = this.props;
    // Email validation
    newState.formIsValid = true;
    if (!email) {
      newState.formIsValid = false;
      newErrors.email = t('auth.enterYourEmail');
    }
    if (email) {
      if (typeof email !== 'undefined') {
        const lastAtPos = email.lastIndexOf('@');
        const lastDotPos = email.lastIndexOf('.');

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf('@@') === -1 &&
            lastDotPos > 2 &&
            email.length - lastDotPos > 2
          )
        ) {
          newState.formIsValid = false;
          newErrors.email = t('auth.emailValidationError');
        }
      }
    }
    // password validation
    const passRegex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])[a-zA-Z0-9\W_]{8,20}$/;
    if (action !== 'forgot') {
      if (!password) {
        newState.formIsValid = false;
        newErrors.password = t('auth.enterPassword');
      }

      if (action === 'register' || action === 'reset') {
        if (!passRegex.test(password)) {
          newState.formIsValid = false;
          newErrors.password = t('auth.passwordRequirements');
        }

        if (!confirmPassword) {
          newState.formIsValid = false;
          newErrors.confirmPassword = t('auth.enterPassword');
        }

        if (confirmPassword !== password) {
          newState.formIsValid = false;
          newErrors.confirmPassword = t('auth.passwordTryAgain');
        }
      }
      if (action === 'otp') {
        const data = {
          userId,
          otpReceived: oneTimePsd,
        };
        await validateOtpSend(data)
          .then(res => {
            if (res.success) {
              newState.formIsValid = true;
              newState.otpValidated = true;
            } else if (get(res, 'error.length')) {
              newState.formIsValid = false;
              newErrors.otpMisMatch =
                t('auth.inCorrectOtp') || res.error[0].message;
              newErrors.oneTimePsd = '';
            }
          })
          .catch(() => {
            newState.formIsValid = false;
            newErrors.otpMisMatch = t('auth.inCorrectOtp');
            newErrors.oneTimePsd = '';
          });
      }
    }
    this.setState(
      {
        ...newState,
        errors: newErrors,
      },
      () => {
        const { formIsValid, otpValidated } = this.state;
        if (formIsValid) {
          if (action === 'register' || action === 'otp') {
            if (forceOtp) {
              this.setState({
                actionType: 'otp',
              });
              if (action === 'register') {
                this.resendCode('send');
              }
              if (otpValidated) {
                this.submitAction(this.state, action, t);
              }
            } else {
              this.submitAction(this.state, 'register', t);
            }
          } else {
            this.submitAction(this.state, action, t);
          }
        }
      }
    );
  }

  hanldeOnClick(e) {
    e.preventDefault();
    const { history, t } = this.props;
    let { formIsValid } = this.state;
    const { email, errors } = this.state;
    formIsValid = true;
    if (!email) {
      formIsValid = false;
      errors.email = t('auth.enterYourEmail');
    }
    if (email) {
      if (typeof email !== 'undefined') {
        const lastAtPos = email.lastIndexOf('@');
        const lastDotPos = email.lastIndexOf('.');

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf('@@') === -1 &&
            lastDotPos > 2 &&
            email.length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          errors.email = t('auth.notValidEmailAuth');
        }
      }
    }
    this.setState({ errors, formIsValid }, () => {
      if (formIsValid === true) {
        history.push(FORGOT);
      }
    });
  }

  handleClickShowPassword() {
    this.setState(state => ({ showPassword: !state.showPassword }));
  }

  handleClickShowConfirmPassword() {
    this.setState(state => ({
      showConfirmPassword: !state.showConfirmPassword,
    }));
  }

  formFrag() {
    const {
      email,
      password,
      errors,
      oneTimePsd,
      disableEmailInput,
      confirmPassword,
      actionType,
      showPassword,
      showConfirmPassword,
      formLabel,
      dialogPrimaryClick,
      dialogSecondaryClick,
      emailInputLabel,
      dialogMsg,
      dialogTitle,
      isOpen,
      resendButtonDisable,
      disableSSO,
      resendOTPInterval,
      showLinkPassLoginOption,
      isOpenLinkpassAccountAlreadyInUseModal,
      loginLabel,
      registerToken,
      errorCode,
      showPasswordValidationInfo,
      isConsentECDA,
    } = this.state;
    const {
      loginData,
      forgotData,
      location,
      theme,
      history,
      validateData,
      isTCC,
      enquiryUrl,
      t,
      schoolID,
      auth0,
    } = this.props;
    const { logout } = auth0;
    let logo = '';
    if (get(theme, 'logo')) {
      ({ logo } = theme);
    }
    let phoneNumber = '';
    if (get(validateData, 'data.phoneNumber')) {
      phoneNumber = get(validateData, 'data.phoneNumber').replace(
        /\d(?=\d{4})/g,
        '*'
      );
    }
    const linkColor = getPrimaryColor(this.props);
    const linkStyle = {
      color: linkColor,
    };
    const hidePassword = (
      <InputAdornment position="end">
        <IconButton
          aria-label="Toggle password visibility"
          onClick={this.handleClickShowPassword}
        >
          {showPassword ? (
            <Visibility className="s7t-text-lightgrey" />
          ) : (
            <VisibilityOff className="s7t-text-lightgrey" />
          )}
        </IconButton>
      </InputAdornment>
    );
    const hideConfirmPassword = (
      <InputAdornment position="end">
        <IconButton
          aria-label="Toggle password visibility"
          onClick={this.handleClickShowConfirmPassword}
        >
          {showConfirmPassword ? (
            <Visibility className="s7t-text-lightgrey" />
          ) : (
            <VisibilityOff className="s7t-text-lightgrey" />
          )}
        </IconButton>
      </InputAdornment>
    );
    const actionData = ActionType(this.props, t);
    let action = actionData.type;
    let labelForm = loginLabel;
    labelForm = actionData.label;
    let emailLabel = actionData.inputLabel;
    let registerdesc = null;
    let passFirst = null;
    let conditionalRender = null;
    let btn = null;
    let loglink = null;
    const disableLoginAndForgot =
      (loginData && loginData.inProgress) ||
      (forgotData && forgotData.inProgress);
    if (location.search) {
      action = actionType;
      labelForm = formLabel;
      emailLabel = emailInputLabel;
    }
    if (action === 'register') {
      // aaa
      registerdesc = (
        <Typography>
          <Trans i18nKey="auth.firstTimeUser" />
        </Typography>
      );
    }
    if (isTCC && action === 'register') {
      registerdesc = null;
    }
    const showRegisterRequire =
      location?.pathname?.includes(REGISTER) &&
      !get(validateData, 'data.EConsentStatus');

    const showResetRequire =
      location?.pathname?.includes(RESET) &&
      get(validateData, 'data.CreatedAt') &&
      moment(get(validateData, 'data.CreatedAt'), SAVE_DATE_FORMAT).isAfter(
        moment('2023-09-15 00:00:00', SAVE_DATE_FORMAT)
      ) &&
      !get(validateData, 'data.EConsentStatus');
    const showConsentRequire = showRegisterRequire || showResetRequire;

    const currentSchoolDetails = schoolIDMap[schoolID];
    const schoolName = get(currentSchoolDetails, 'name');
    if (action === 'forgot') {
      registerdesc = (
        <div>
          <Typography
            variant="body1"
            className="s7t-text-brownish-grey w3-margin-bottom w3-margin-top"
          >
            <Trans
              i18nKey="auth.emailAddressProvideText"
              values={{ schoolName }}
            />
          </Typography>
          <Typography
            variant="body1"
            className="s7t-text-brownish-grey w3-margin-bottom"
          >
            <Trans i18nKey="auth.emailAllowReset" />
          </Typography>
        </div>
      );
    }
    if (isTCC && action === 'forgot') {
      registerdesc = (
        <div>
          <Typography
            variant="body1"
            className="s7t-text-brownish-grey w3-margin-bottom w3-margin-top"
          >
            <Trans i18nKey="auth.enterRegisteredEmail" />
          </Typography>
        </div>
      );
    }

    if (action === 'otp') {
      registerdesc = (
        <p style={{ textAlign: 'justify' }}>
          <Trans i18nKey="auth.otpPhoneNumber" values={{ phoneNumber }} />
        </p>
      );
      labelForm = t('auth.OtpLabel');
    }

    if (action === 'connectToMFSAccount') {
      registerdesc = (
        <Text style={{ marginTop: '20px' }}>
          <Trans
            i18nKey="auth.connectToMFSAccountDesc"
            values={{ schoolName }}
          />
        </Text>
      );
    }

    switch (action) {
      case 'connectToMFSAccount':
        passFirst = (
          <PasswordField
            passClass="s7t-margin-bottom10"
            adornment={hidePassword}
            passType={showPassword ? 'text' : 'password'}
            fieldName={t('auth.enterPasswordSimple')}
            name="password"
            id="password"
            value={password}
            errmsg={errors.password}
            placeHolder={t('auth.enterPasswordSimple')}
          />
        );
        conditionalRender = !isStaffDomain && (
          <ForgotPassword
            disableForgot={disableLoginAndForgot}
            forgot={() => {
              history.push(FORGOT);
            }}
          />
        );
        btn = (
          <BtnComp disabled={disableLoginAndForgot} name={t('auth.connect')} />
        );
        loglink = !isStaffDomain &&
          process.env.REACT_APP_ENRICHMENT_PLATFORM !== 'true' && (
            <Typography className="w3-center">
              <Trans i18nKey="auth.newUsers" />{' '}
              <span
                onClick={() => window.open(enquiryUrl)}
                className="s7t-login-a s7t-cursor"
                style={linkStyle}
              >
                <Trans i18nKey="auth.clickHereLogin" />
              </span>
            </Typography>
          );
        break;
      case 'login':
        passFirst = (
          <PasswordField
            passClass="s7t-margin-bottom10"
            adornment={hidePassword}
            passType={showPassword ? 'text' : 'password'}
            fieldName={t('auth.enterPasswordSimple')}
            name="password"
            id="password"
            value={password}
            errmsg={errors.password}
            placeHolder={t('auth.enterPasswordSimple')}
          />
        );
        conditionalRender = !isStaffDomain && (
          <ForgotPassword
            disableForgot={disableLoginAndForgot}
            forgot={() => {
              history.push(FORGOT);
            }}
          />
        );
        btn = (
          <BtnComp disabled={disableLoginAndForgot} name={t('auth.logIn')} />
        );
        break;
      case 'register':
        passFirst = (
          <PasswordField
            passClass="s7t-margin-bottom10"
            adornment={hidePassword}
            passType={showPassword ? 'text' : 'password'}
            fieldName={t('auth.createPasswordSimple')}
            name="password"
            id="password"
            value={password}
            errmsg={errors.password}
            placeHolder={t('auth.enterPasswordSimple')}
            disabled={showRegisterRequire && !isConsentECDA}
          />
        );
        conditionalRender = (
          <PasswordField
            passClass="s7t-margin-bottom10"
            adornment={hideConfirmPassword}
            passType={showConfirmPassword ? 'text' : 'password'}
            fieldName={t('auth.confirmAuthPassword')}
            name="confirmPassword"
            id="confirmPassword"
            value={confirmPassword}
            errmsg={errors.confirmPassword}
            placeHolder={t('auth.reEnterPassword')}
            disabled={showRegisterRequire && !isConsentECDA}
          />
        );
        btn = (
          <BtnComp
            disabled={showRegisterRequire && !isConsentECDA}
            name={t('auth.activateAccount')}
          />
        );
        loglink = (
          <Typography className="w3-center">
            <Trans i18nKey="auth.alreadyHaveAccount" />
            <Link to={LOGIN} className="s7t-login-a" style={linkStyle}>
              <Trans i18nKey="auth.loginHere" />
            </Link>
          </Typography>
        );
        break;
      case 'reset':
        passFirst = (
          <PasswordField
            passClass="s7t-margin-bottom10"
            adornment={hidePassword}
            passType={showPassword ? 'text' : 'password'}
            fieldName={t('auth.newPassword')}
            name="password"
            id="password"
            value={password}
            errmsg={errors.password}
            placeHolder={t('auth.enterPasswordSimple')}
            disabled={showConsentRequire && !isConsentECDA}
          />
        );
        conditionalRender = (
          <PasswordField
            passClass="s7t-margin-bottom10"
            adornment={hideConfirmPassword}
            passType={showConfirmPassword ? 'text' : 'password'}
            fieldName={t('auth.confirmAuthPassword')}
            name="confirmPassword"
            id="confirmPassword"
            value={confirmPassword}
            errmsg={errors.confirmPassword}
            placeHolder={t('auth.reEnterPassword')}
            disabled={showConsentRequire && !isConsentECDA}
          />
        );
        btn = (
          <BtnComp
            disabled={showConsentRequire && !isConsentECDA}
            name={t('common.submitButton')}
          />
        );
        break;
      case 'forgot':
        passFirst = null;
        conditionalRender = null;
        btn = (
          <BtnComp
            name={t('auth.getResetEmail')}
            disabled={disableLoginAndForgot}
          />
        );
        loglink = (
          <Typography className="w3-center">
            <Link to={LOGIN} className="s7t-login-a" style={linkStyle}>
              <Trans i18nKey="auth.backtoLogin" />
            </Link>
          </Typography>
        );
        break;
      case 'otp':
        passFirst = (
          <PasswordField
            passClass="s7t-margin-bottom10"
            adornment={hidePassword}
            passType={showPassword ? 'text' : 'password'}
            fieldName={t('auth.oneTimePass')}
            name="oneTimePsd"
            id="oneTimePsd"
            value={oneTimePsd}
            errmsg={errors.oneTimePsd}
            placeHolder={t('auth.otpEnter')}
          />
        );
        conditionalRender = null;
        btn = (
          <BtnComp
            name={t('common.submitButton')}
            passClass="w3-padding w3-margin-bottom"
          />
        );
        loglink = (
          <Button
            fullWidth
            variant="text"
            disabled={resendButtonDisable}
            onClick={() => this.resendCode('resend')}
            className={`${
              resendButtonDisable ? 's7t-disabled' : 's7t-approved'
            }  s7t-cursor s7t-font-size-16px s7t-font-500`}
            style={{ textAlign: 'center' }}
          >
            <Trans i18nKey="auth.resendOtp" />
          </Button>
        );
        break;
      default:
        passFirst = (
          <PasswordField
            passClass="s7t-margin-bottom10"
            adornment={hidePassword}
            passType={showPassword ? 'text' : 'password'}
            fieldName={t('auth.enterPasswordSimple')}
            name="password"
            id="password"
            value={password}
            errmsg={errors.password}
          />
        );
        conditionalRender = !isStaffDomain && (
          <ForgotPassword
            disableForgot={disableLoginAndForgot}
            forgot={() => {
              history.push(FORGOT);
            }}
          />
        );
        btn = (
          <BtnComp disabled={disableLoginAndForgot} name={t('auth.signIn')} />
        );
        loglink = !isStaffDomain && (
          <Typography className="w3-center">
            <Trans i18nKey="auth.newUsers" />{' '}
            <span
              onClick={() => window.open(enquiryUrl)}
              className="s7t-login-a s7t-cursor"
              style={linkStyle}
            >
              <Trans i18nKey="auth.clickHereLogin" />
            </span>
          </Typography>
        );
        break;
    }

    const imageCntrCls = '';
    const formgrid = 12;
    const logoHeight = '132';

    const getLabelForm = () => {
      if (process.env.REACT_APP_ENRICHMENT_PLATFORM === 'true') {
        return t('common.enrichmentProgrammes');
      }
      if (showLinkPassLoginOption) {
        return loginLabel;
      }
      return labelForm;
    };

    const onChangeConsentStatus = () => {
      const { isConsentECDA, token } = this.state;
      const { updateElectronicConsentStatus } = this.props;
      const newIsConsentECDA = !isConsentECDA;
      this.setState({ isConsentECDA: newIsConsentECDA });
      updateElectronicConsentStatus(token, newIsConsentECDA);
    };

    return (
      <Grid container justify="center" className="s7t-display-content">
        <Grid item sm={formgrid} className="s7t-auto-margin">
          <Dialog
            isOpen={isOpen}
            dialogTitle={dialogTitle}
            dialogContent={dialogMsg}
            onPrimaryClick={dialogPrimaryClick}
            onSecondaryClick={dialogSecondaryClick}
          />
          <form
            onSubmit={e => {
              this.handleSubmit(e, action);
            }}
            onChange={this.handleChange}
            noValidate
          >
            <Grid
              item
              xs={12}
              className={`s7t-margin-bottom4pct ${imageCntrCls}`}
            >
              <img alt="" src={logo} height={logoHeight} />
            </Grid>

            <Grid item xs={12} className="s7t-width-100pct w3-left">
              <hr
                className="s7t-Rectangle-4"
                style={{
                  background: getPrimaryColor(this.props),
                }}
              />
              <Text type="header1">{getLabelForm()}</Text>
            </Grid>

            {showLinkPassLoginOption && !registerToken && !isStaffDomain && (
              <Grid
                item
                xs={12}
                className="w3-left w3-margin-right s7t-margin-bottom10 s7t-margin-top-10"
              >
                <Typography>
                  <Trans
                    i18nKey="auth.linkPassAnnouncement"
                    values={{ schoolName }}
                  />
                </Typography>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              className="s7t-margin-bottom4pct w3-left w3-margin-right"
            >
              {showRegisterRequire && (
                <CheckBoxLabel
                  value={isConsentECDA}
                  fromControlProps={{
                    style: {
                      'text-align': 'justify',
                    },
                  }}
                  label={
                    <Trans
                      i18nKey="auth.giveConsentECDA"
                      values={{ schoolName }}
                    />
                  }
                  className="s7t-font-500"
                  name="giveConsentECDA"
                  onChange={onChangeConsentStatus}
                />
              )}

              <div style={{ color: '#717171' }}>{registerdesc}</div>
            </Grid>
            {!isStaffDomain ? (
              <>
                {showLinkPassLoginOption ? (
                  <div>
                    <Linkpass
                      {...this.props}
                      onLinkpassBtnClick={this.onLinkpassBtnClick}
                      onLoginWithEmail={this.onLoginWithEmail}
                      isNewParent={!!registerToken}
                      isConsentECDA={isConsentECDA}
                      showConsentRequire={showConsentRequire}
                      schoolName={schoolName}
                    />
                    {!registerToken && (
                      <div style={{ marginTop: '30px' }}>{loglink}</div>
                    )}
                  </div>
                ) : (
                  <>
                    <Grid container alignItems="center">
                      {errors.otpMisMatch && (
                        <Grid
                          container
                          className="w3-margin-bottom w3-margin-top w3-padding"
                          style={{ background: '#ffe6de' }}
                        >
                          <Grid item xs={1}>
                            <ErrorOutline
                              style={{
                                color: '#e02020',
                                fontSize: '20px',
                              }}
                            />
                          </Grid>
                          <Grid item xs={11} style={{ color: '#e02020' }}>
                            {errors.otpMisMatch}
                          </Grid>
                        </Grid>
                      )}
                      {action !== 'reset' && action !== 'otp' ? (
                        <Grid
                          item
                          xs={12}
                          className="w3-center s7t-margin-bottom10"
                        >
                          <FormControl className="s7t-width-100pct">
                            <InputLabel htmlFor="email" className="s7t-font-16">
                              {emailLabel}
                            </InputLabel>
                            <Input
                              id="email"
                              type="email"
                              name="email"
                              value={email}
                              autoFocus
                              disabled={disableEmailInput}
                              placeholder={t('common.emailPlaceholder')}
                            />
                            <Grid item xs={12} className="w3-center">
                              <span className="s7t-error">{errors.email}</span>
                            </Grid>
                          </FormControl>
                        </Grid>
                      ) : null}
                    </Grid>

                    <>
                      {showResetRequire && (
                        <>
                          <CheckBoxLabel
                            value={isConsentECDA}
                            fromControlProps={{
                              style: {
                                'text-align': 'justify',
                              },
                            }}
                            label={
                              <Trans
                                i18nKey="auth.giveConsentECDAForResetPassword"
                                values={{ schoolName }}
                              />
                            }
                            className="s7t-font-500"
                            name="giveConsentECDA"
                            onChange={onChangeConsentStatus}
                          />
                        </>
                      )}
                      {showPasswordValidationInfo && registerToken && (
                        <Grid item xs={12} className="s7t-width-100pct w3-left">
                          <Typography>
                            {t('auth.passwordContain')}
                            <ul className="sn2-padding-left-25">
                              <li>{t('auth.characters')}</li>
                              <li>{t('auth.specialCharacter')}</li>
                              <li>{t('auth.number')}</li>
                              <li>{t('auth.alphabet')}</li>
                            </ul>
                          </Typography>
                        </Grid>
                      )}
                      {passFirst}
                      {conditionalRender}
                      {btn}
                      {loglink}

                      <Grid item xs={12} className="w3-center">
                        <Text fontSize="13px">
                          {resendOTPInterval === 30 ? (
                            ''
                          ) : (
                            <Trans
                              i18nKey="auth.resendInterval"
                              values={{
                                resendOTPInterval,
                              }}
                            />
                          )}
                        </Text>
                      </Grid>
                    </>
                  </>
                )}
              </>
            ) : (
              <Grid
                container
                justify="center"
                className="w3-margin-bottom w3-center"
              >
                <Grid item xs={12} className="s7t-margin-bottom10">
                  <Button
                    disabled={disableSSO}
                    type="button"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={this.redirectSSO}
                    className="s7t-button-text s7t-button-font s7t-button-border-radius s7t-width-100pct"
                  >
                    <Trans i18nKey="auth.signInNtuc" />
                  </Button>
                </Grid>
                <Grid item xs={12} className="w3-center">
                  <span className="s7t-error">
                    {getQueryParams().unauth === 'true' && 'User Not Found'}
                  </span>
                </Grid>
              </Grid>
            )}

            <Dialog
              isOpen={isOpenLinkpassAccountAlreadyInUseModal}
              fullWidth
              maxWidth="xs"
              buttonSize="lg"
              buttonPrimaryVariant="text"
              dialogTitle={
                errorCode === 'ERR_ACCOUNT_CONNECTED_TO_ANOTHER_LINKPASS'
                  ? t('auth.sn2AccountAlreadyConnected', {
                      schoolName,
                    })
                  : t('auth.linkpassAccountAlreadyInUse')
              }
              dialogContent={
                errorCode === 'ERR_ACCOUNT_CONNECTED_TO_ANOTHER_LINKPASS' ? (
                  <Trans
                    i18nKey="auth.sn2AccountAlreadyConnectedDesc"
                    values={{ schoolName }}
                  />
                ) : (
                  <Trans
                    i18nKey="auth.linkpassAccountAlreadyInUseDesc"
                    values={{ schoolName }}
                  />
                )
              }
              hideSecondaryBtn
              onPrimaryClick={() => {
                localStorage.setValue('linkpassIDToken', null);
                localStorage.setValue('userToken', null);
                localStorage.setValue('registerToken', null);
                logout({
                  returnTo: window.location.origin,
                });
                this.setState({
                  isOpenLinkpassAccountAlreadyInUseModal: false,
                  isAccountDetailsNotMatch: false,
                  errorCode: '',
                });

                localStorage.setValue('linkFailError', false);
              }}
              buttonPrimaryName={t('common.okay2')}
              customJustifyContent="flex-end"
            />
          </form>
        </Grid>
      </Grid>
    );
  }

  redirectSSO() {
    const { t } = this.props;
    this.submitAction(this.state, 'sso_url', t);
  }

  render() {
    const {
      isAccountDetailsNotMatch,
      isOpenLinkpassAccountAlreadyInUseModal,
      errorCode,
    } = this.state;
    let mainComp = <Loader />;
    const {
      cdnurl,
      theme,
      validateData,
      linkpassUserInfoFromClaimData,
      sn2UserInfo,
      linkPassCompareWithUser,
      auth0,
      t,
      schoolID,
      schoolName,
    } = this.props;

    const { logout } = auth0;

    if (this.props) {
      mainComp = this.formFrag();
    }
    let banner = `${cdnurl}/${getSchoolShortName()}/banner-right.png`;
    if (has(theme, 'banner')) {
      ({ banner } = theme);
    }

    if (isAccountDetailsNotMatch) {
      return (
        <>
          <ContactDetailsNotMatch
            onProceedLinkAccount={this.onProceedLinkAccount}
            onCancel={this.onCancelContactDetailsNotMatch}
            linkpassUserInfoFromClaim={linkpassUserInfoFromClaimData}
            sn2UserInfo={sn2UserInfo}
            schoolID={schoolID}
            isDiffPhone={linkPassCompareWithUser?.IsDiffPhone}
            isDiffEmail={linkPassCompareWithUser?.IsDiffEmail}
          />
          <Dialog
            isOpen={isOpenLinkpassAccountAlreadyInUseModal}
            fullWidth
            maxWidth="xs"
            buttonSize="lg"
            buttonPrimaryVariant="text"
            dialogTitle={
              errorCode === 'ERR_ACCOUNT_CONNECTED_TO_ANOTHER_LINKPASS'
                ? t('auth.sn2AccountAlreadyConnected', {
                    schoolName,
                  })
                : t('auth.linkpassAccountAlreadyInUse')
            }
            dialogContent={
              errorCode === 'ERR_ACCOUNT_CONNECTED_TO_ANOTHER_LINKPASS' ? (
                <Trans
                  i18nKey="auth.sn2AccountAlreadyConnectedDesc"
                  values={{ schoolName }}
                />
              ) : (
                <Trans
                  i18nKey="auth.linkpassAccountAlreadyInUseDesc"
                  values={{ schoolName }}
                />
              )
            }
            hideSecondaryBtn
            onPrimaryClick={() => {
              localStorage.setValue('linkpassIDToken', null);
              localStorage.setValue('userToken', null);
              localStorage.setValue('registerToken', null);
              logout({
                returnTo: window.location.origin,
              });
              this.setState({
                isOpenLinkpassAccountAlreadyInUseModal: false,
                isAccountDetailsNotMatch: false,
                errorCode: '',
              });
            }}
            buttonPrimaryName={t('common.okay2')}
            customJustifyContent="flex-end"
          />
        </>
      );
    }

    if (!validateData.inProgress) {
      return (
        <div className="s7t-auth-container">
          <Grid container className="s7t-loging-background s7t-full-height">
            <Grid item xs={12} sm={12} md={5}>
              <Card className="s7t-full-height s7t-auth-card-shadow s7t-auth-card-overflow">
                <CardContent className="s7t-center-div">{mainComp}</CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <ImageLazyLoader
                alt="school banner for login"
                className="s7t-full-height s7t-login-grid-image"
                src={banner}
              />
            </Grid>
          </Grid>
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = state => ({
  linkpassUserInfoFromClaimData: getLinkpassInfo(
    get(state, 'linkpassUserInfoFromClaim.data.data.linkpassUserInfoFromClaim'),
    get(
      state,
      'linkpassSN2UserInfo.data.data.linkpassSn2UserInfo.LinkPassAuthTokenClaimDto'
    )
  ),
  sn2UserInfo: getSn2UserInfo(
    get(state, 'linkpassSN2UserInfo.data.data.linkpassSn2UserInfo.User'),
    get(state, 'userDetails.data')
  ),

  linkPassCompareWithUser: get(
    state,
    'linkpassSN2UserInfo.data.data.linkpassSn2UserInfo.LinkPassCompareWithUser'
  ),
  loginData: get(state, 'login'),
  forgotData: get(state, 'forgot'),
  registerData: get(state, 'register'),
  resetData: get(state, 'reset'),
  validateData: get(state, 'validate'),
  cdnurl: get(state, 'cdnUrl.data'),
  enquiryUrl: get(state, 'schoolConfig.list.asObject.partner_school_url'),
  isTCC: get(state, 'isTCC'),
  schoolID: get(state, 'schoolID.data'),
  schoolName: getSchoolName(get(state, 'schoolID.data')),
  forceOtp: parseInt(
    get(state, 'schoolConfig.list.asObject.force_otp_on_registration', '0'),
    10
  ),
});

const mapDispatchToProps = {
  loginUser,
  loginForEpToken,
  linkUserWithLinkPassAndLogin: linkUserWithLinkPassAndLoginAction,
  updateElectronicConsentStatus: updateElectronicConsentStatusAction,
  linkpassSn2UserInfo,
  showSnackBarMessage: showSnackBarMessageAction,
  getSSOURL,
  forgotPassword,
  registerPassword,
  resetPassword,
  validateUser,
  getSchoolOtpValidate,
  sendAndResentOtp: sendOtp,
  validateOtpSend: validateOtp,
};

export default compose(
  withTranslation(),
  withTheme
)(withRouter(connect(mapStateToProps, mapDispatchToProps)(withAuth0(Login))));
