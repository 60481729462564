import gql from 'graphql-tag';

export const GET_BROADCASTING = gql`
  query getBroadcasting(
    $centreID: Int
    $centreIDs: [Int!]
    $filter: BroadcastingFilter
    $pagination: Pagination
    $expired: Boolean
  ) {
    getBroadcasting(
      centreID: $centreID
      centreIDs: $centreIDs
      filter: $filter
      pagination: $pagination
      expired: $expired
    ) {
      totalCount
      data {
        ID
        subject
        fkUser
        description
        status
        from
        to
        isSendEmail
        active
        createdAt
        updatedAt
        publishedDate
        recalledDate
        creator {
          displayName
        }
        user(filter: { active: [true, false] }) {
          ID
          firstname
          lastname
          imageKey
          imageKeyPresignedURL
          userRoleRelations {
            data {
              role {
                name
                label
              }
            }
          }
        }
        broadcastingScopes {
          data {
            class {
              ID
              label
            }
            child {
              ID
              firstname
              lastname
            }
            centre {
              ID
              label
            }
          }
        }
        broadcastingAttachments {
          data {
            fileKey
            fileSize
          }
        }
        isRead
      }
    }
  }
`;

export const SEARCH_BROADCASTING_SCOPE = gql`
  query searchBroadcastingScope(
    $q: String!
    $centreFilter: CentreFilter
    $classFilter: ClassFilter
    $childFilter: ChildFilter
    $pagination: Pagination
  ) {
    searchBroadcastingScope(
      q: $q
      centreFilter: $centreFilter
      classFilter: $classFilter
      childFilter: $childFilter
      pagination: $pagination
    ) {
      listCentre {
        totalCount
        data {
          ID
          label
          code
          classes {
            totalCount
            data {
              ID
              label
            }
          }
        }
      }
      listClass {
        totalCount
        data {
          ID
          label
          fkCentre
          profilePhotoKey
          profilePhotoKeyPresignedURL
          centre {
            label
          }
        }
      }
      listChild {
        totalCount
        data {
          ID
          firstname
          lastname
          birthCertificate
          imageKey
          imageKeyPresignedURL
        }
      }
    }
  }
`;

export const DELETE_BROADCASTING_BY_ID = gql`
  mutation deleteBroadcastingByID($ID: Int!) {
    deleteBroadcastingByID(ID: $ID)
  }
`;

export const RECALL_BROADCASTING_BY_ID = gql`
  mutation recallBroadcastingByID($ID: Int!) {
    recallBroadcastingByID(ID: $ID) {
      ID
      status
    }
  }
`;

export const MARK_BROADCAST_READ = gql`
  mutation markBroadcastingAsRead(
    $IDBroadcastings: [Int!]!
    $isRead: Boolean!
  ) {
    markBroadcastingAsRead(IDBroadcastings: $IDBroadcastings, isRead: $isRead)
  }
`;

export const GET_BROADCASTING_PHOTO_UPLOAD_URL = gql`
  mutation getBroadcastingPhotoUploadUrl($IDSchool: Int!, $file: Upload!) {
    getBroadcastingPhotoUploadUrl(IDSchool: $IDSchool, file: $file) {
      Url
      Key
    }
  }
`;
export const SAVE_BROADCASTING = gql`
  mutation saveBroadcasting($dto: BroadcastingCreateDTO!, $publish: Boolean!) {
    saveBroadcasting(dto: $dto, publish: $publish) {
      ID
    }
  }
`;
