import moment from 'moment';
import { get } from 'lodash';
import localStore from '../../../../../utils/localStorage';

const SystemAnnouncementHandler = (
  props,
  selectedEndID,
  setselectedEndID,
  setEndCount,
  setShowDeleteModal,
  setShowEndModal,
  endCount
) => {
  const {
    setAnnouncementAlert,
    currentSystemAnnouncement,
    endSystemAnnouncement,
    showSnackBarMessage,
    t,
  } = props;

  const handleDismiss = () => {
    const currentDate = moment().format('YYYY-MM-DD');
    const systemAnnouncementData =
      localStore.getValue('systemAnnouncementData') || {};
    if (
      !systemAnnouncementData.dismissedDate ||
      systemAnnouncementData.dismissedDate !== currentDate
    ) {
      systemAnnouncementData.dismissedDate = currentDate;
    }
    systemAnnouncementData.dismissed = true;
    systemAnnouncementData.lastDismissedID = get(
      currentSystemAnnouncement,
      'data.ID'
    );
    localStore.setValue('systemAnnouncementData', systemAnnouncementData);
    setAnnouncementAlert(false);
  };

  const endAnnouncement = () => {
    if (selectedEndID) {
      endSystemAnnouncement({ ID: selectedEndID }).then(response => {
        setEndCount();
        setShowDeleteModal(false);
        setShowEndModal(false);
        setselectedEndID(endCount + 1);
        if (response.success) {
          handleDismiss();
          showSnackBarMessage(t('systemAnnouncement.endSuccess'));
        } else {
          showSnackBarMessage(get(response, 'error[0].message'), 'error');
        }
      });
    }
  };

  return {
    handleDismiss,
    endAnnouncement,
  };
};
export default SystemAnnouncementHandler;
