/** *  Common  ** */
export const ACTION_SET_DATA = 'ACTION_SET_DATA';
export const FETCH_METADATA = 'FETCH_METADATA';
export const SHOW_APP_MESSAGE = 'SHOW_APP_MESSAGE';
export const HIDE_APP_MESSAGE = 'HIDE_APP_MESSAGE';
export const SHOW_CONNECT_TO_LINKPASS_MODAL = 'SHOW_CONNECT_TO_LINKPASS_MODAL';
export const SHOW_SNACKBAR_MESSAGE = 'SHOW_SNACKBAR_MESSAGE';
export const HIDE_SNACKBAR_MESSAGE = 'HIDE_SNACKBAR_MESSAGE';
export const SHOW_MOBILE_NAVIGATION = 'SHOW_MOBILE_NAVIGATION';
export const SHOW_ASSETS = 'SHOW_ASSETS';
export const HIDE_ASSETS = 'HIDE_ASSETS';
export const SET_ALERT = 'SET_ALERT';
/** *  -------  ** */

export const GET_WAITLIST_PARAMETERS = 'GET_WAITLIST_PARAMETERS';

export const GET_WAITLIST_PARAMETERS_SUCCESS =
  'GET_WAITLIST_PARAMETERS_SUCCESS';

export const GET_WAITLIST_PARAMETERS_ERROR = 'GET_WAITLIST_PARAMETERS_ERROR';

/** *  Auth    ** */
export const LOGIN_USER = 'LOGIN_USER';
export const LINKPASS_USER_INFO_FROM_CLAIM = 'LINKPASS_USER_INFO_FROM_CLAIM';
export const LOGIN_BY_LINKPASS = 'LOGIN_BY_LINKPASS';
export const LINK_USER_WITH_LINKPASS = 'LINK_USER_WITH_LINKPASS';
export const REGISTER_WITH_LINKPASS = 'REGISTER_WITH_LINKPASS';
export const LINK_USER_WITH_LINKPASS_AND_LOGIN =
  'LINK_USER_WITH_LINKPASS_AND_LOGIN';

export const LINKPASS_SN2_USER_INFO = 'LINKPASS_SN2_USER_INFO';

export const FETCH_SSO_URL = 'FETCH_SSO_URL';
export const APPROVER_ACCESS = 'APPROVER_ACCESS';
export const ACCESSIBLE_CENTRES = 'ACCESSIBLE_CENTRES';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const REGISTER_USER_PASSWORD = 'REGISTER_USER_PASSWORD';
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const FETCH_USER_FOR_TOKEN = 'FETCH_USER_FOR_TOKEN';
export const VALIDATE_REGISTERED_USER = 'VALIDATE_REGISTERED_USER';
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
/** *  -------  ** */

/** *  Parent Enquiry  ** */
export const PARENT_ENQUIRY = 'PARENT_ENQUIRY';
export const FIND_CENTERS = 'FIND_CENTERS';
export const FIND_ENROLLMENT_CENTERS = 'FIND_ENROLLMENT_CENTERS';
export const CLEAR_CENTRES = 'CLEAR_CENTRES';
export const CLEAR_TCC_CENTRES = 'CLEAR_TCC_CENTRES';
export const SELECTED_CENTERS = 'SELECTED_CENTERS';
export const STORE_SEARCH_DATA = 'STORE_SEARCH_DATA';
export const FETCH_AVAILABLE_DATES = 'FETCH_AVAILABLE_DATES';
export const FIND_CHILDREN = 'FIND_CHILDREN';
/** *  -------  ** */

/** *  Config  ** */
export const FETCH_ALL_OCCUPATIONS = 'FETCH_ALL_OCCUPATIONS';
export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const FETCH_LEVEL = 'FETCH_LEVEL';
export const FETCH_SCHOOL_CONFIG = 'FETCH_SCHOOL_CONFIG';
export const FETCH_ALL_TAGS = 'FETCH_ALL_TAGS';
export const FETCH_ALL_CENTRES = 'FETCH_ALL_CENTRES';
export const FETCH_ALL_SERVICE_LEVELS = 'FETCH_ALL_SERVICE_LEVELS';
export const FETCH_SIDEMENU_CONFIG = 'FETCH_SIDEMENU_CONFIG';
export const FETCH_CODES = 'FETCH_CODES';
export const FETCH_ALL_CENTRE_LEVELS = 'FETCH_ALL_CENTRE_LEVELS';
export const FETCH_APP_CONFIG = 'FETCH_APP_CONFIG';
export const FETCH_ALL_REGISTRATION_CONFIG = 'FETCH_ALL_REGISTRATION_CONFIG';
/** *  -------  ** */

/** *  Notifications  ** */
export const FETCH_NOTIFICATIONS_HISTORY = 'FETCH_NOTIFICATIONS_HISTORY';
/** *  -------------  ** */

/** *  Enrolment  ** */
export const SAVE_ENROLMENT_FORM_1 = 'SAVE_ENROLMENT_FORM_1';
export const SAVE_ENROLMENT_FORM_2 = 'SAVE_ENROLMENT_FORM_2';
export const SAVE_ENROLMENT_FORM_3 = 'SAVE_ENROLMENT_FORM_3';
export const SAVE_ENROLMENT_FORM_4 = 'SAVE_ENROLMENT_FORM_4';
export const SAVE_ENROLMENT_FORM_5 = 'SAVE_ENROLMENT_FORM_5';
export const SAVE_ENROLMENT_FORM_6 = 'SAVE_ENROLMENT_FORM_6';
export const SAVE_ENROLMENT_FORM_7 = 'SAVE_ENROLMENT_FORM_7';
export const SAVE_ENROLMENT_FORM_8 = 'SAVE_ENROLMENT_FORM_8';
export const SAVE_ENROLMENT_FORM_9 = 'SAVE_ENROLMENT_FORM_9';
export const FETCH_ENROLMENT_FORM = 'FETCH_ENROLMENT_FORM';
export const REG_ENROLMENT_FORM = 'REG_ENROLMENT_FORM';
/** *  -------  ** */

/** *  Blacklist  ** */
export const FETCH_BLACK_LIST_DATA = 'FETCH_BLACK_LIST_DATA';
export const FETCH_BLACK_LIST_REASON = 'FETCH_BLACK_LIST_REASON';
export const CHILD_RELATION_SEARCH = 'CHILD_RELATION_SEARCH';
export const SUBMIT_NEW_BLACKLIST_REQUEST = 'SUBMIT_NEW_BLACKLIST_REQUEST';
export const FETCH_BLACK_LIST_DRAFTS = 'FETCH_BLACK_LIST_DRAFTS';
/** *  -------  ** */

/** *  Parent Dashboard    ** */
export const CANCEL_USER_ENQUIRY = 'CANCEL_USER_ENQUIRY';
export const FETCH_ENQUIRY_STATES = 'FETCH_ENQUIRY_STATES';
export const DISPATCH_SCHEDULE_DATA = 'DISPATCH_SCHEDULE_DATA';
export const FETCH_USER_ENQUIRIES = 'FETCH_USER_ENQUIRIES';
export const FETCH_AVAILABLE_TIMES = 'FETCH_AVAILABLE_TIMES';
export const FETCH_ENROLMENT_STEPS = 'FETCH_ENROLMENT_STEPS';
export const BOOK_VISIT = 'BOOK_VISIT';
export const FETCH_ENROLMENT_DATA = 'FETCH_ENROLMENT_DATA';
export const FETCH_CHILDREN_BY_PARENT = 'FETCH_CHILDREN_BY_PARENT';
/** *  -------  ** */

/** *  Waitlist ** */
export const FETCH_WAITLISTS = 'FETCH_WAITLISTS';
export const FETCH_WAITLISTS_VACANCY_COUNT = 'FETCH_WAITLISTS_VACANCY_COUNT';
export const FETCH_WAITLISTS_FILTER = 'FETCH_WAITLISTS_FILTER';
export const FETCH_ALL_WAITLIST_VACANCY = 'FETCH_ALL_WAITLIST_VACANCY';
export const FETCH_WEIGHTLIST_PARAM_TAGS = 'FETCH_WEIGHTLIST_PARAM_TAGS';
export const SEARCH_CHILD_IN_WAITLIST = 'SEARCH_CHILD_IN_WAITLIST';
/** *  -------  ** */

/** *  Principal/Staff Dashboard    ** */
export const FETCH_PRINCIPAL_ENQUIRY = 'FETCH_PRINCIPAL_ENQUIRY';
export const FETCH_ENQUIRY_BY_ID = 'FETCH_ENQUIRY_BY_ID';
export const FETCH_SCHEDULED_VISITS = 'FETCH_SCHEDULED_VISITS';
export const SET_EXPANDED_ENQUIRY_ID = 'SET_EXPANDED_ENQUIRY_ID';
export const CLEAR_SCHEDULED_VISITS = 'CLEAR_SCHEDULED_VISITS';
export const QR_CODE_CICO_TOKEN = 'QR_CODE_CICO_TOKEN';
/** *  -------  ** */

/** * Calendar ** */
export const FETCH_APPOINTMENTS = 'FETCH_APPOINTMENTS';
export const FETCH_ALL_CALENDAR_DATA = 'FETCH_ALL_CALENDAR_DATA';
/** *  -------  ** */

/* Fee Managment Type */
export const FETCH_PENDING_FEE_DRAFTS_COUNT = 'FETCH_PENDING_FEE_DRAFTS_COUNT';
export const FETCH_CURRENT_FEE_TIER = 'FETCH_CURRENT_FEE_TIER';
export const FETCH_UPCOMING_FEE_TIER = 'FETCH_UPCOMING_FEE_TIER';
export const FETCH_FEE_DRAFT = 'FETCH_FEE_DRAFT';
export const FETCH_FEE_GROUP = 'FETCH_FEE_GROUP';
export const SUBMIT_FEE_DRAFT = 'SUBMIT_FEE_DRAFT';
export const RECALL_FEE_CHANGE_REQUEST = 'RECALL_FEE_CHANGE_REQUEST';
export const APPROVE_FEE_CHANGE_REQUEST = 'APPROVE_FEE_CHANGE_REQUEST';
export const REJECT_FEE_CHANGE_REQUEST = 'APPROVE_FEE_CHANGE_REQUEST';
export const RESCHEDULE_VISIT = 'RESCHEDULE_VISIT';
export const FETCH_GROUP_CATEGORY_FEE_LIST = 'FETCH_GROUP_CATEGORY_FEE_LIST';
export const FETCH_ALL_FEE_BULK_UPLOADS = 'FETCH_ALL_FEE_BULK_UPLOADS';
/** *  -------  ** */

/* Vacancy */
export const FETCH_CENTER_VACANCIES = 'FETCH_CENTER_VACANCIES';
export const FETCH_NEGATIVE_CENTER_VACANCIES =
  'FETCH_NEGATIVE_CENTER_VACANCIES';
export const FETCH_RECALL_REASONS = 'FETCH_RECALL_REASONS';
export const FETCH_VACANCY_HISTORY = 'FETCH_VACANCY_HISTORY';
export const STORE_VACANCY_CAP_BY_YEARS = 'STORE_VACANCY_CAP_BY_YEARS';
export const STORE_EDITED_PLANS = 'STORE_EDITED_PLANS';
export const RECALL_PENDING_PLAN = 'RECALL_PENDING_PLAN';
export const FETCH_MAKER_PENDING = 'FETCH_MAKER_PENDING';
export const APPROVE_PLAN = 'APPROVE_PLAN';
export const REJECT_PLAN = 'REJECT_PLAN';
export const REJECT_MODAL_DATA = 'REJECT_MODAL_DATA';
export const UPDATE_MODAL_DATA = 'UPDATE_MODAL_DATA';
export const SUBMIT_PLAN_FOR_REVIEW = 'SUBMIT_PLAN_FOR_REVIEW';
export const RECALL_MODAL_DATA = 'RECALL_MODAL_DATA';
/* Vacancy */

/* User & Role Management */
export const FETCH_ALL_ROLES = 'FETCH_ALL_ROLES';
export const ADD_NEW_ROLE = 'ADD_NEW_ROLE';
export const FETCH_ALL_MODULE_CATEGORY = 'FETCH_ALL_MODULE_CATEGORY';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const FETCH_ALL_STAFF = 'FETCH_ALL_STAFF';
export const FETCH_ALL_STAFF_V2 = 'FETCH_ALL_STAFF_V2';
export const FETCH_ALL_REPORTING_STAFF = 'FETCH_ALL_REPORTING_STAFF';
export const SEARCH_STAFF = 'SEARCH_STAFF';
export const SEARCH_STAFF_V2 = 'SEARCH_STAFF_V2';
export const SEARCH_USER_BY_NAME = 'SEARCH_USER_BY_NAME';
export const SEARCH_USER_BY_EMAIL = 'SEARCH_USER_BY_EMAIL';
export const FETCH_ROLE_FOR_CREATE_USER = 'FETCH_ROLE_FOR_CREATE_USER';
/* User & Role Management */

/* Level Setting Management */
export const FETCH_ALL_MANAGEMENT_LEVELS = 'FETCH_ALL_MANAGEMENT_LEVELS';
/* Level Setting Management */

/* Bus Setting Management */
export const LIST_ALL_BUSES = 'LIST_ALL_BUSES';
export const FETCH_ALL_BUSSES = 'FETCH_ALL_BUSSES';
export const FETCH_ALL_CHILDREN_FOR_BUS = 'FETCH_ALL_CHILDREN_FOR_BUS';
export const FETCH_ALL_BUS_DRIVER = 'FETCH_ALL_BUS_DRIVER';
export const UPDATE_BUS_DRIVER = 'UPDATE_BUS_DRIVER';
export const SHOW_DELETE_BUS_POPUP = 'SHOW_DELETE_BUS_POPUP';
export const FETCH_ALL_BUS_CHILDREN = 'FETCH_ALL_BUS_CHILDREN';
/* Bus Setting Management */

/* Centre Settings Management */
export const FETCH_CENTRES_FOR_MANAGEMENT = 'FETCH_CENTRES_FOR_MANAGEMENT';
export const UPDATE_AUTO_PROMOTION_CONFIG = 'UPDATE_AUTO_PROMOTION_CONFIG';
export const FETCH_CENTRE_DOC_CATEGORIES = 'FETCH_CENTRE_DOC_CATEGORIES';
export const FETCH_CENTRE_DOCS = 'FETCH_CENTRE_DOCS';
export const GET_CENTRE_LICENSE_DOCS = 'GET_CENTRE_LICENSE_DOCS';

export const FETCH_CENTRE_MANAGEMENT_CONFIG = 'FETCH_CENTRE_MANAGEMENT_CONFIG';

export const FETCH_CENTRES_DRAFT_FOR_MANAGEMENT =
  'FETCH_CENTRES_DRAFT_FOR_MANAGEMENT';
export const CREATE_OR_UPDATE_CENTRE = 'CREATE_OR_UPDATE_CENTRE';
export const CREATE_OR_UPDATE_CENTRE_DRAFT = 'CREATE_OR_UPDATE_CENTRE_DRAFT';
export const DELETE_CENTRE_DRAFT = 'DELETE_CENTRE_DRAFT';
export const CANCEL_CENTRE_DRAFT = 'CANCEL_CENTRE_DRAFT';
export const SUBMIT_CENTRE_DRAFT = 'SUBMIT_CENTRE_DRAFT';
/* Centre Settings Management */

/* Class Management */
export const FETCH_UPDATED_CHILD_PARTICULAR = 'FETCH_UPDATED_CHILD_PARTICULAR';
export const FIND_ALL_PROGRAMS = 'FIND_ALL_PROGRAMS';
export const FETCH_PAST_CHILDREN = 'FETCH_PAST_CHILDREN';
export const FETCH_PAST_CHILDREN_SEARCH = 'FETCH_PAST_CHILDREN_SEARCH';
export const FETCH_ALL_CLASSES = 'FETCH_ALL_CLASSES';
export const FETCH_ALL_TEACHERS = 'FETCH_ALL_TEACHERS';
export const CREATE_NEW_CLASS = 'CREATE_NEW_CLASS';
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO';
export const UPDATE_CLASS_DETAILS_PAGE = 'UPDATE_CLASS_DETAILS_PAGE';
export const FETCH_CHILDREN_ASSIGN_CLASS = 'FETCH_CHILDREN_ASSIGN_CLASS';
export const FETCH_CURRENT_CHILDID = 'FETCH_CURRENT_CHILDID';
export const CLEAR_CURRENT_CHILD = 'CLEAR_CURRENT_CHILD';
export const FETCH_CHILDREN_DATA = 'FETCH_CHILDREN_DATA';
export const FETCH_ALL_CHILD = 'FETCH_ALL_CHILD';
export const FETCH_REQ_DOCS = 'FETCH_REQ_DOCS';
export const FETCH_UPLOAD_DOCS = 'FETCH_UPLOAD_DOCS';
export const CLEAR_UPLOAD_DOCS = 'CLEAR_UPLOAD_DOCS';
export const FETCH_UPLOAD_DOC_URL = 'FETCH_UPLOAD_DOC_URL';
export const FETCH_ALL_DOCS_TYPE = 'FETCH_ALL_DOCS_TYPE';
export const FETCH_DOCUMENT_BY_CATEGORY = 'FETCH_DOCUMENT_BY_CATEGORY';
export const FETCH_UPLOADED_DOCS = 'FETCH_UPLOADED_DOCS';
export const FETCH_ACTIVITY_LOG = 'FETCH_ACTIVITY_LOG';
export const SET_UPLOAD_IMAGES = 'SET_UPLOAD_IMAGES';
export const FETCH_ALL_DOCUMENT_TYPE = 'FETCH_ALL_DOCUMENT_TYPE';
export const FETCH_DOCUMENT_TYPE_TO_UPLOAD = 'FETCH_DOCUMENT_TYPE_TO_UPLOAD';
export const CLEAR_FETCH_ALL_CHILD = 'CLEAR_FETCH_ALL_CHILD';
export const CLEAR_ALL_CLASSES = 'CLEAR_ALL_CLASSES';
export const FETCH_ALL_GUARDIAN = 'FETCH_ALL_GUARDIAN';
export const SET_ASSIGN_OR_PROMOTE_DETAIL = 'SET_ASSIGN_OR_PROMOTE_DETAIL';
export const CLEAR_ASSIGN_OR_PROMOTE_DETAIL = 'CLEAR_ASSIGN_OR_PROMOTE_DETAIL';
export const FETCH_CLASS_DETAIL = 'FETCH_CLASS_DETAIL';
export const CLEAR_CLASS_DETAIL = 'CLEAR_CLASS_DETAIL';
export const FETCH_FORM2_COUNT = 'FETCH_FORM2_COUNT';
export const FETCH_FORM3_COUNT = 'FETCH_FORM3_COUNT';
export const GET_LATE_PICKUPS = 'GET_LATE_PICKUPS';
export const FETCH_CLASS_ACTIVITIES = 'FETCH_CLASS_ACTIVITIES';
export const FETCH_CLASS_ACTIVITY_TAGS = 'FETCH_CLASS_ACTIVITY_TAGS';
export const FETCH_CLASS_ACTIVITY_STICKERS = 'FETCH_CLASS_ACTIVITY_STICKERS';
export const FETCH_CLASS_RESOURCES = 'FETCH_CLASS_RESOURCES';
export const FETCH_LEARNING_GOALS_OPTIONS_LIST =
  'FETCH_LEARNING_GOALS_OPTIONS_LIST';
export const FETCH_FEE_TIER_FOR_CHILD = 'FETCH_FEE_TIER_FOR_CHILD';
export const FETCH_APPLICABLE_FEE_TIER_FOR_CHILD =
  'FETCH_APPLICABLE_FEE_TIER_FOR_CHILD';
export const FETCH_CHILD_FEE_TIER_FOR_CHILD = 'FETCH_CHILD_FEE_TIER_FOR_CHILD';
export const FETCH_UPCOMING_FEE_TIER_FOR_CHILD =
  'FETCH_UPCOMING_FEE_TIER_FOR_CHILD';
export const FETCH_EMERGENCY_CONTACT_INFO = 'FETCH_EMERGENCY_CONTACT_INFO';
export const FETCH_EMERGENCY_CONTACT_INFO_SUCCESS =
  'FETCH_EMERGENCY_CONTACT_INFO_SUCCESS';
export const FETCH_EMERGENCY_CONTACT_INFO_ERROR =
  'FETCH_EMERGENCY_CONTACT_INFO_ERROR';
export const CLEAR_EMERGENCY_CONTACT_INFO = 'CLEAR_EMERGENCY_CONTACT_INFO';
export const FETCH_CHILD_HEALTH_INFO = 'FETCH_CHILD_HEALTH_INFO';
export const FETCH_CHILD_HEALTH_INFO_SUCCESS =
  'FETCH_CHILD_HEALTH_INFO_SUCCESS';
export const FETCH_CHILD_HEALTH_INFO_ERROR = 'FETCH_CHILD_HEALTH_INFO_ERROR';
export const UPDATE_CHILD_HEALTH_INFO_REQUEST =
  'UPDATE_CHILD_HEALTH_INFO_REQUEST';
export const UPDATE_CHILD_HEALTH_INFO_SUCCESS =
  'UPDATE_CHILD_HEALTH_INFO_SUCCESS';
export const UPDATE_CHILD_HEALTH_INFO_ERROR = 'UPDATE_CHILD_HEALTH_INFO_ERROR';

export const REMOVE_ENROLLED_CHILD_DOC_REQUEST =
  'REMOVE_ENROLLED_CHILD_DOC_REQUEST';
export const REMOVE_ENROLLED_CHILD_DOC_SUCCESS =
  'REMOVE_ENROLLED_CHILD_DOC_SUCCESS';
export const REMOVE_ENROLLED_CHILD_DOC_ERROR =
  'REMOVE_ENROLLED_CHILD_DOC_ERROR';
export const REMOVE_ENROLLED_CHILD_DOC_TYPE_REQUEST =
  'REMOVE_ENROLLED_CHILD_DOC_TYPE_REQUEST';
export const REMOVE_ENROLLED_CHILD_DOC_TYPE_SUCCESS =
  'REMOVE_ENROLLED_CHILD_DOC_TYPE_SUCCESS';
export const REMOVE_ENROLLED_CHILD_DOC_TYPE_ERROR =
  'REMOVE_ENROLLED_CHILD_DOC_TYPE_ERROR';

export const FETCH_UPLOAD_DOCS_REQUEST = 'FETCH_UPLOAD_DOCS_REQUEST';
export const FETCH_UPLOAD_DOCS_SUCCESS = 'FETCH_UPLOAD_DOCS_SUCCESS';
export const FETCH_UPLOAD_DOCS_ERROR = 'FETCH_UPLOAD_DOCS_ERROR';
export const UPDATE_DOCS_UPLOADED_BY_TYPE_REQUEST =
  'UPDATE_DOCS_UPLOADED_BY_TYPE_REQUEST';
export const UPDATE_DOCS_UPLOADED_BY_TYPE_SUCCESS =
  'UPDATE_DOCS_UPLOADED_BY_TYPE_SUCCESS';
export const UPDATE_DOCS_UPLOADED_BY_TYPE_ERROR = 'FETCH_UPLOAD_DOCS_ERROR';

export const FETCH_ALL_GUARDIAN_SEARCH = 'FETCH_ALL_GUARDIAN_SEARCH';

/* Class Management */

/* Transfer Management */
export const FETCH_ALL_TRANSFER_DRAFTS = 'FETCH_ALL_TRANSFER_DRAFTS';
export const FETCH_ALL_COMPLETED_DRAFTS = 'FETCH_ALL_COMPLETED_DRAFTS';
export const IS_VACANCY_AVAILABLE = 'IS_VACANCY_AVAILABLE';
export const FETCH_TRANSFER_DRAFTS_LOADING = 'FETCH_TRANSFER_DRAFTS_LOADING';
export const FETCH_TRANSFER_DRAFTS_SUCCESS = 'FETCH_TRANSFER_DRAFTS_SUCCESS';
export const FETCH_TRANSFER_DRAFTS_ERROR = 'FETCH_TRANSFER_DRAFTS_ERROR';
export const FETCH_ALL_VACANCY = 'FETCH_ALL_VACANCY';
export const FETCH_CENTRE_VACANCY = 'FETCH_CENTRE_VACANCY';
export const HAS_LEAD_OFFERED = 'HAS_LEAD_OFFERED';
export const FEE_TIERS_BY_CHILD_CENTRE = 'FEE_TIERS_BY_CHILD_CENTRE';

/* Transfer Management */

/* Regisration Payment */
export const FETCH_AVAILABLE_BANKS = 'FETCH_AVAILABLE_BANKS';
export const FETCH_PAYMENT_SESSION = 'FETCH_PAYMENT_SESSION';
export const CLEAR_PAYMENT_SESSION = 'CLEAR_PAYMENT_SESSION';
export const SET_GIRO_SETUP_STEP = 'SET_GIRO_SETUP_STEP';
export const CLEAR_GIRO_SETUP_STEP = 'CLEAR_GIRO_SETUP_STEP';
/* Regisration Payment */

/* Registration State */
export const FETCH_DEFAULT_STATE_MAPPING = 'FETCH_DEFAULT_STATE_MAPPING';
export const FETCH_CURRENT_STATE_MAPPING = 'FETCH_CURRENT_STATE_MAPPING';
export const FETCH_ALL_STATE = 'FETCH_ALL_STATE';
export const SAVE_CURRENT_STATE_MAPPING = 'SAVE_CURRENT_STATE_MAPPING';
export const FETCH_ENQUIRY_PAYMENT_DETAILS_BY_ID =
  'FETCH_ENQUIRY_PAYMENT_DETAILS_BY_ID';
export const FETCH_ENQUIRY_MONTHLY_PAYMENT_DETAILS_BY_ID =
  'FETCH_ENQUIRY_MONTHLY_PAYMENT_DETAILS_BY_ID';
export const FETCH_UPLOADED_DOCUMENTS = 'FETCH_UPLOADED_DOCUMENTS';
export const FETCH_USER_DOCUMENTS = 'FETCH_USER_DOCUMENTS';
export const FETCH_USER_ACCOUNT_LISTING = 'FETCH_USER_ACCOUNT_LISTING';
export const FETCH_ADDITIONAL_INFO = 'FETCH_ADDITIONAL_INFO';
export const FETCH_ACCOUNT_INFO_BY_ENQUIRY = 'FETCH_ACCOUNT_INFO_BY_ENQUIRY';
export const FETCH_ACCOUNT_INFO_BY_REG = 'FETCH_ACCOUNT_INFO_BY_REG';
export const FETCH_ACCOUNT_INFO_BY_CHILD = 'FETCH_ACCOUNT_INFO_BY_CHILD';
export const FETCH_ACCOUNT_INFO_BY_ENQUIRY_GIRO =
  'FETCH_ACCOUNT_INFO_BY_ENQUIRY_GIRO';
export const FETCH_EGIRO_ACCOUNT_INFO_BY_REG =
  'FETCH_EGIRO_ACCOUNT_INFO_BY_REG';
export const FETCH_EGIRO_ACCOUNT_INFO_BY_CHILD =
  'FETCH_EGIRO_ACCOUNT_INFO_BY_CHILD';
export const FETCH_EGIRO_REDIRECTION = 'FETCH_EGIRO_REDIRECTION';

/* Registration State */

/* Holiday Management */
export const FETCH_ALL_HOLIDAYS = 'FETCH_ALL_HOLIDAYS';
export const FETCH_CLOSURE_DAYS = 'FETCH_CLOSURE_DAYS';

/* Withdrawal Request */
export const ALL_WITHDRAWAL_REQUEST = 'ALL_WITHDRAWAL_REQUEST';
export const ALL_WITHDRAWAL = 'ALL_WITHDRAWAL';
export const ALL_REFUNDS = 'ALL_REFUNDS';
export const FETCH_WITHDRAWAL_DRAFTS_LOADING =
  'FETCH_WITHDRAWAL_DRAFTS_LOADING';
export const FETCH_WITHDRAWAL_DRAFTS_SUCCESS =
  'FETCH_WITHDRAWAL_DRAFTS_SUCCESS';
export const FETCH_WITHDRAWAL_DRAFTS_ERROR = 'FETCH_WITHDRAWAL_DRAFTS_ERROR';

/* Admissions */
export const FETCH_ADMISSIONS = 'FETCH_ADMISSIONS';
export const FETCH_ADMISSION_FILTERS = 'FETCH_ADMISSION_FILTERS';
export const FETCH_REGISTRATIONS = 'FETCH_REGISTRATIONS';
export const SEARCH_CHILD_IN_ADMISSIONS = 'SEARCH_CHILD_IN_ADMISSIONS';
export const FETCH_ADMISSIONS_WITH_FILTER = 'FETCH_ADMISSIONS_WITH_FILTER';
export const FETCH_EXISTING_PARENT = 'FETCH_EXISTING_PARENT';
export const FETCH_EXISTING_CHILD = 'FETCH_EXISTING_CHILD';
export const FETCH_SUGGESTED_FEE_TIER = 'FETCH_SUGGESTED_FEE_TIER';
export const FETCH_REGISTRATION_STATES = 'FETCH_REGISTRATION_STATES';
export const FETCH_REGISTRATION_STATES_SUCCESS =
  'FETCH_REGISTRATION_STATES_SUCCESS';
export const FETCH_REGISTRATION_STATES_ERROR =
  'FETCH_REGISTRATION_STATES_ERROR';
/* Admissions */

/* Attendance Management */
export const FETCH_CLASS_ATTENDANCE = 'FETCH_CLASS_ATTENDANCE';
export const FETCH_CHILD_ATTENDANCE = 'FETCH_CHILD_ATTENDANCE';
export const CURRENT_CHILD_ATTENDANCE = 'CURRENT_CHILD_ATTENDANCE';
export const FETCH_FILTER_FOR_CLASS_ATTENDANCE =
  'FETCH_FILTER_FOR_CLASS_ATTENDANCE';
export const FETCH_ATTENDANCE_DETAILS = 'FETCH_ATTENDANCE_DETAILS';
export const CURRENT_CHILD_ATTENDANCE_HISTORY =
  'CURRENT_CHILD_ATTENDANCE_HISTORY';
export const GET_FILTERS_COUNTS = 'GET_FILTERS_COUNTS';
export const FETCH_ATTENDANCE_MODAL_DETAILS = 'FETCH_ATTENDANCE_MODAL_DETAILS';
/* Attendance ManageMent */

/* Health Management */
export const FETCH_CLASS_HEALTH_DATA = 'FETCH_CLASS_HEALTH_DATA';
export const FETCH_CHILD_HEALTH_DATA = 'FETCH_CHILD_HEALTH_DATA';
export const FETCH_DOCTOR_ALLERGY_INFO = 'FETCH_DOCTOR_ALLERGY_INFO';
export const FETCH_VISUAL_CHECK_INFO = 'FETCH_VISUAL_CHECK_INFO';
export const FETCH_HEALTH_DOCUMENTS = 'FETCH_HEALTH_DOCUMENTS';
/* Health Management */

/* Broadcast */
export const FETCH_BROADCASTS = 'FETCH_BROADCASTS';
export const SEARCH_BROADCASTS = 'SEARCH_BROADCASTS';
export const FETCH_BROADCAST_BY_ID = 'FETCH_BROADCAST_BY_ID';
export const MARK_BROADCAST_AS_READ = 'MARK_BROADCAST_AS_READ';
/* Broadcast */

/* Event Management */
export const FETCH_EVENT_MANAGEMENT_LISTING = 'FETCH_EVENT_MANAGEMENT_LISTING';
export const CURRENT_EVENT_CATEGORY = 'CURRENT_EVENT_CATEGORY';
export const FETCH_EVENT_DETAILS = 'FETCH_EVENT_DETAILS';
export const EVENT_RSVP_LIST = 'EVENT_RSVP_LIST';
export const FETCH_GET_RSVP_COUNT = 'FETCH_GET_RSVP_COUNT';
export const FETCH_RSVP_LIST = 'FETCH_RSVP_LIST';
export const FETCH_RSVP_DATA = 'FETCH_RSVP_DATA';
export const FETCH_CURRENT_EVENT = 'FETCH_CURRENT_EVENT';
export const FETCH_EVENT_PHOTOS = 'FETCH_EVENT_PHOTOS';
export const FETCH_GET_EXPORT_EVENT_RSVP = 'FETCH_GET_EXPORT_EVENT_RSVP';
/* Event Management */

/* Finance */
export const FETCH_ALL_PURCHASE_ITEMS = 'FETCH_ALL_PURCHASE_ITEMS';
export const FETCH_ALL_INVOICE_BY_FKCHILD = 'FETCH_ALL_INVOICE_BY_FKCHILD';
export const FETCH_ALL_INVOICES_BY_CHILD = 'FETCH_ALL_INVOICES_BY_CHILD';
export const FETCH_CHILD_BALANCE = 'FETCH_CHILD_BALANCE';
export const FETCH_ALL_BULK_INVOICES_LOADING =
  'FETCH_ALL_BULK_INVOICES_LOADING';
export const FETCH_ALL_BULK_INVOICES_SUCCESS =
  'FETCH_ALL_BULK_INVOICES_SUCCESS';
export const FETCH_ALL_BULK_INVOICES_ERROR = 'FETCH_ALL_BULK_INVOICES_ERROR';
export const FETCH_CHILD_OUTSTANDING_AMOUNT_SUCCESS =
  'FETCH_CHILD_OUTSTANDING_AMOUNT_SUCCESS';
export const FETCH_CHILD_OUTSTANDING_AMOUNT_ERROR =
  'FETCH_CHILD_OUTSTANDING_AMOUNT_ERROR';
export const FETCH_STATEMENT_OF_ACCOUNT_LOADING =
  'FETCH_STATEMENT_OF_ACCOUNT_LOADING';
export const FETCH_STATEMENT_OF_ACCOUNT_SUCCESS =
  'FETCH_STATEMENT_OF_ACCOUNT_SUCCESS';
export const FETCH_STATEMENT_OF_ACCOUNT_ERROR =
  'FETCH_STATEMENT_OF_ACCOUNT_ERROR';
export const FETCH_CHILD_SOA_BY_YEAR_LOADING =
  'FETCH_CHILD_SOA_BY_YEAR_LOADING';
export const FETCH_CHILD_SOA_BY_YEAR_SUCCESS =
  'FETCH_CHILD_SOA_BY_YEAR_SUCCESS';
export const FETCH_CHILD_SOA_BY_YEAR_ERROR = 'FETCH_CHILD_SOA_BY_YEAR_ERROR';
export const CLEAR_CHILD_OUTSTANDING_AMOUNT = 'CLEAR_CHILD_OUTSTANDING_AMOUNT';
export const CREATE_INVOICE_FINANCE = 'CREATE_INVOICE_FINANCE';
export const CREATE_RECEIPT_FINANCE = 'CREATE_RECEIPT_FINANCE';
export const FETCH_CHILD_BANK_ACCOUNTS = 'FETCH_CHILD_BANK_ACCOUNTS';
export const FETCH_ALL_BANKS = 'FETCH_ALL_BANKS';
export const FETCH_ALL_INVOICES_FOR_ALL_CENTRES =
  'FETCH_ALL_INVOICES_FOR_ALL_CENTRES';
export const FETCH_FINANCE = 'SEARCH_FINANCE';
export const FETCH_CHILD_REFUNDS = 'FETCH_CHILD_REFUNDS';
export const FETCH_ENROLLED_CENTRES = 'FETCH_ENROLLED_CENTRES';
export const FETCH_SEARCH_INVOICE = 'SEARCH_INVOICE';
export const FETCH_ALL_REFUND = 'FETCH_ALL_REFUND';
export const ALL_CONSOLIDATED_REFUNDS = 'ALL_CONSOLIDATED_REFUNDS';
export const FETCH_CHILD_BALANCE_SHEET = 'FETCH_CHILD_BALANCE_SHEET';
export const FETCH_ALL_UPLOADED_SUBSIDY_FILES =
  'FETCH_ALL_UPLOADED_SUBSIDY_FILES';
export const FETCH_ALL_DOWNLOADED_GIRO_FILES =
  'FETCH_ALL_DOWNLOADED_GIRO_FILES';
export const FETCH_ALL_VALID_DOWNLOADED_GIRO_FILES =
  'FETCH_ALL_VALID_DOWNLOADED_GIRO_FILES';
export const FETCH_TAX_RATE_AND_AMOUNT = 'FETCH_TAX_RATE_AND_AMOUNT';
export const CLEAR_TAX_RATE_AND_AMOUNT = 'CLEAR_TAX_RATE_AND_AMOUNT';
export const FETCH_PAYMENT_TYPES = 'FETCH_PAYMENT_TYPES';
export const BANK_ACC_STATUS_CHANGE_REASON_BY_STATUS =
  'BANK_ACC_STATUS_CHANGE_REASON_BY_STATUS';
export const UPDATE_GIRO_STATUS_WITH_REASON = 'UPDATE_GIRO_STATUS_WITH_REASON';
export const EMPTY_REASONS_GIRO = 'EMPTY_REASONS_GIRO';
export const ALL_CUSTOM_SUBSIDY_TYPE = 'ALL_CUSTOM_SUBSIDY_TYPE';
export const ACTIVE_SUBSIDIES = 'ACTIVE_SUBSIDIES';
export const FETCH_HISTORY_SUBSIDIES_LOADING =
  'FETCH_HISTORY_SUBSIDIES_LOADING';
export const FETCH_HISTORY_SUBSIDIES_SUCCESS =
  'FETCH_HISTORY_SUBSIDIES_SUCCESS';
export const FETCH_HISTORY_SUBSIDIES_ERROR = 'FETCH_HISTORY_SUBSIDIES_ERROR';
export const FETCH_SUBSIDIES_LOADING = 'FETCH_SUBSIDIES_LOADING';
export const FETCH_SUBSIDIES_SUCCESS = 'FETCH_SUBSIDIES_SUCCESS';
export const FETCH_SUBSIDIES_ERROR = 'FETCH_SUBSIDIES_ERROR';
export const SUBSIDY_DEBIT_AMOUNT = 'SUBSIDY_DEBIT_AMOUNT';
export const CREATE_ADVANCE_PAYMENT = 'CREATE_ADVANCE_PAYMENT';
export const FETCH_ADVANCE_PAYMENT_RECEIPTS = 'FETCH_ADVANCE_PAYMENT_RECEIPTS';
export const ALL_ABSENT_CHILD_VOID_SUBSIDY = 'ALL_ABSENT_CHILD_VOID_SUBSIDY';
export const FETCH_ADVANCE_PAYMENT_ITEM_AMOUNT =
  'FETCH_ADVANCE_PAYMENT_ITEM_AMOUNT';
export const FETCH_CHILD_FINANCIAL_ASSISTANCE_STATUS =
  'FETCH_CHILD_FINANCIAL_ASSISTANCE_STATUS';
export const FETCH_ADVANCE_PAYMENT_RECEIPTS_FOR_REGISTRATION =
  'FETCH_ADVANCE_PAYMENT_RECEIPTS_FOR_REGISTRATION';
export const FETCH_DEBT_WRITE_OFF_LOADING = 'FETCH_DEBT_WRITE_OFF_LOADING';
export const FETCH_DEBT_WRITE_OFF_SUCCESS = 'FETCH_DEBT_WRITE_OFF_SUCCESS';
export const FETCH_DEBT_WRITE_OFF_ERROR = 'FETCH_DEBT_WRITE_OFF_ERROR';
export const FETCH_ALL_PGM_BILLING_LOADING = 'FETCH_ALL_PGM_BILLING_LOADING';
export const FETCH_ALL_PGM_BILLING_SUCCESS = 'FETCH_ALL_PGM_BILLING_SUCCESS';
export const FETCH_ALL_PGM_BILLING_ERROR = 'FETCH_ALL_PGM_BILLING_ERROR';
export const FETCH_CREDIT_DEBIT_NOTE_LOADING =
  'FETCH_CREDIT_DEBIT_NOTE_LOADING';
export const FETCH_CREDIT_DEBIT_NOTE_SUCCESS =
  'FETCH_CREDIT_DEBIT_NOTE_SUCCESS';
export const FETCH_CREDIT_DEBIT_NOTE_ERROR = 'FETCH_CREDIT_DEBIT_NOTE_ERROR';
export const FETCH_ALL_REG_CHILD_BALANCE_LOADING =
  'FETCH_ALL_REG_CHILD_BALANCE_LOADING';
export const FETCH_ALL_REG_CHILD_BALANCE_SUCCESS =
  'FETCH_ALL_REG_CHILD_BALANCE_SUCCESS';
export const FETCH_ALL_REG_CHILD_BALANCE_ERROR =
  'FETCH_ALL_REG_CHILD_BALANCE_ERROR';

export const FETCH_ALL_CHILD_REFUNDS_LOADING =
  'FETCH_ALL_CHILD_REFUNDS_LOADING';
export const FETCH_ALL_CHILD_REFUNDS_SUCCESS =
  'FETCH_ALL_CHILD_REFUNDS_SUCCESS';
export const FETCH_ALL_CHILD_REFUNDS_ERROR = 'FETCH_ALL_CHILD_REFUNDS_ERROR';

export const FETCH_ALL_REG_CHILD_OUTSTANDING_LOADING =
  'FETCH_ALL_REG_CHILD_OUTSTANDING_LOADING';
export const FETCH_ALL_REG_CHILD_OUTSTANDING_SUCCESS =
  'FETCH_ALL_REG_CHILD_OUTSTANDING_SUCCESS';
export const FETCH_ALL_REG_CHILD_OUTSTANDING_ERROR =
  'FETCH_ALL_REG_CHILD_OUTSTANDING_ERROR';

export const FETCH_ALL_REG_CHILD_REFUNDS_LOADING =
  'FETCH_ALL_REG_CHILD_REFUNDS_LOADING';
export const FETCH_ALL_REG_CHILD_REFUNDS_SUCCESS =
  'FETCH_ALL_REG_CHILD_REFUNDS_SUCCESS';
export const FETCH_ALL_REG_CHILD_REFUNDS_ERROR =
  'FETCH_ALL_REG_CHILD_REFUNDS_ERROR';
export const FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_LOADING =
  'FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_LOADING';
export const FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_SUCCESS =
  'FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_SUCCESS';
export const FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_ERROR =
  'FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_ERROR';
export const FETCH_ADJUST_INVOICE_AMT_LOADING =
  'FETCH_ADJUST_INVOICE_AMT_LOADING';
export const FETCH_ADJUST_INVOICE_AMT_SUCCESS =
  'FETCH_ADJUST_INVOICE_AMT_SUCCESS';
export const FETCH_ADJUST_INVOICE_AMT_ERROR = 'FETCH_ADJUST_INVOICE_AMT_ERROR';
export const FETCH_TRADE_DEBT_NOTIFICATION_LOADING =
  'FETCH_TRADE_DEBT_NOTIFICATION_LOADING';
export const FETCH_TRADE_DEBT_NOTIFICATION_SUCCESS =
  'FETCH_TRADE_DEBT_NOTIFICATION_SUCCESS';
export const FETCH_TRADE_DEBT_NOTIFICATION_ERROR =
  'FETCH_TRADE_DEBT_NOTIFICATION_ERROR';
export const FETCH_CHILD_REFUND_STATUS_LOADING =
  'FETCH_CHILD_REFUND_STATUS_LOADING';
export const FETCH_CHILD_REFUND_STATUS_SUCCESS =
  'FETCH_CHILD_REFUND_STATUS_SUCCESS';
export const FETCH_CHILD_REFUND_STATUS_ERROR =
  'FETCH_CHILD_REFUND_STATUS_ERROR';

/* Finance */

/* Discount Settings */
export const FETCH_ALL_CHILD_DISCOUNTS = 'FETCH_ALL_CHILD_DISCOUNTS';
export const FETCH_ALL_APPLICABLE_CHILD_DISCOUNTS =
  'FETCH_ALL_APPLICABLE_CHILD_DISCOUNTS';
export const FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS =
  'FETCH_ALL_APPLICABLE_ENQUIRY_DISCOUNTS';
export const FETCH_DISCOUNT_GROUPS = 'FETCH_DISCOUNT_GROUPS';
export const FETCH_DISCOUNT_OPTIONS_CONFIG = 'FETCH_DISCOUNT_OPTIONS_CONFIG';
export const ACTION_REMOVE_CHILD_DISCOUNT = 'ACTION_REMOVE_CHILD_DISCOUNT';
export const ACTION_DEACTIVATE_CHILD_DISCOUNT =
  'ACTION_DEACTIVATE_CHILD_DISCOUNT';
export const FETCH_ALL_CHILD_DISCOUNTS_REGISTRATION =
  'FETCH_ALL_CHILD_DISCOUNTS_REGISTRATION';
/* Discount Settings */

/* Staff Attendance */
export const FETCH_STAFF_ATTENDANCE = 'FETCH_STAFF_ATTENDANCE';
export const FETCH_STAFF_ATTENDANCE_FILTERS = 'FETCH_STAFF_ATTENDANCE_FILTERS';
/* Staff Attendance */

/* EnrichmentProgramme */
export const FETCH_ENRICHMENT_CLASS_DETAIL = 'FETCH_ENRICHMENT_CLASS_DETAIL';
export const FETCH_ENRICHMENT_PROGRAMME_DRAFT =
  'FETCH_ENRICHMENT_PROGRAMME_DRAFT';
export const FETCH_ENRICHMENT_PROGRAMME_DRAFT_SUCCESS =
  'FETCH_ENRICHMENT_PROGRAMME_DRAFT_SUCCESS';
export const FETCH_ENRICHMENT_PROGRAMME_DRAFT_ERROR =
  'FETCH_ENRICHMENT_PROGRAMME_DRAFT_ERROR';
export const CLEAR_ENRICHMENT_PROGRAMME_DRAFT =
  'CLEAR_ENRICHMENT_PROGRAMME_DRAFT';
export const GET_ALL_CHILD_REFUND_LIST = 'GET_ALL_CHILD_REFUND_LIST';
export const FETCH_ENRICHMENT_CHILD_INVOICES =
  'FETCH_ENRICHMENT_CHILD_INVOICES';
export const FETCH_ENRICHMENT_WAITLIST_MANAGEMENT =
  'FETCH_ENRICHMENT_WAITLIST_MANAGEMENT';
export const FETCH_ENRICHMENT_CLASS_LIST = 'FETCH_ENRICHMENT_CLASS_LIST';
export const FETCH_ENRICHMENT_VENDOR_INVOICES =
  'FETCH_ENRICHMENT_VENDOR_INVOICES';
export const FETCH_VENDOR_PAYABLE_LIST = 'FETCH_VENDOR_PAYABLE_LIST';
export const FETCH_VENDOR_PAYABLE_LIST_SUCCESS =
  'FETCH_VENDOR_PAYABLE_LIST_SUCCESS';
export const FETCH_VENDOR_PAYABLE_LIST_ERROR =
  'FETCH_VENDOR_PAYABLE_LIST_ERROR';
export const FETCH_PARENT_CLASS_INFO = 'FETCH_PARENT_CLASS_INFO';
export const FETCH_PARENT_CLASS_INFO_SUCCESS =
  'FETCH_PARENT_CLASS_INFO_SUCCESS';
export const FETCH_PARENT_CLASS_INFO_ERROR = 'FETCH_PARENT_CLASS_INFO_ERROR';
export const FETCH_ATTACHMENT = 'FETCH_ATTACHMENT';
export const FETCH_ATTACHMENT_ERROR = 'FETCH_ATTACHMENT_ERROR';
export const FETCH_ATTACHMENT_PHOTO_SUCCESS = 'FETCH_ATTACHMENT_PHOTO_SUCCESS';
export const FETCH_ATTACHMENT_VIDEO_SUCCESS = 'FETCH_ATTACHMENT_VIDEO_SUCCESS';
export const FETCH_ATTACHMENT_DOCUMENT_SUCCESS =
  'FETCH_ATTACHMENT_DOCUMENT_SUCCESS';
/* Consent Form */
export const FETCH_CONSENT_FORM_SIGNER = 'FETCH_CONSENT_FORM_SIGNER';
export const SEND_CONSENT_FORM = 'SEND_CONSENT_FORM';

// Dashboard
export const FETCH_ALL_STAFF_DASHBOARD_REQUEST =
  'FETCH_ALL_STAFF_DASHBOARD_REQUEST';

// Reports
export const FETCH_ALL_REPORT_SCHEMA = 'FETCH_ALL_REPORT_SCHEMA';
export const FETCH_ALL_REPORT_LIST = 'FETCH_ALL_REPORT_LIST';
export const FETCH_ALL_QQL_CHECKLIST_REPORT = 'FETCH_ALL_QQL_CHECKLIST_REPORT';
export const FETCH_QQL_CHECKLIST_REPORT_CLASS_BY_CENTER =
  'FETCH_QQL_CHECKLIST_REPORT_CLASS_BY_CENTER';

/* REGISTRATION DOCUMENT UPLOAD V2 */
export const FETCH_DOCUMENTS_TO_UPLOAD = 'FETCH_DOCUMENTS_TO_UPLOAD';
export const SAVE_UPLOADED_REG_DOCUMENTS = 'SAVE_UPLOADED_REG_DOCUMENTS';
// Registration revamp
export const GET_REG_ADDITIONAL_INFORMATION = 'GET_REG_ADDITIONAL_INFORMATION';
export const SAVE_REG_ADDITIONAL_INFORMATION =
  'SAVE_REG_ADDITIONAL_INFORMATION';

/* REGISTRATION CONSENT V2 */
export const FETCH_REG_CONSENT_FORM_SIGNER = 'FETCH_REG_CONSENT_FORM_SIGNER';
export const GET_ALL_OFFLINE_CONSENT_FORM_ACTION =
  'GET_ALL_OFFLINE_CONSENT_FORM_ACTION';

/* ENROLLMENT PLANNING UTILISATION */
export const GET_ENROLLMENT_PLANNING_UTILISATION_LOADING =
  'GET_ENROLLMENT_PLANNING_UTILISATION_LOADING';
export const GET_ENROLLMENT_PLANNING_UTILISATION_SUCCESS =
  'GET_ENROLLMENT_PLANNING_UTILISATION_SUCCESS';

export const GET_ENROLLMENT_PLANNING_UTILISATION_ERROR =
  'GET_ENROLLMENT_PLANNING_UTILISATION_ERROR';

/* ENROLLMENT PLANNING */
export const GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_LOADING =
  'GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_LOADING';
export const GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_SUCCESS =
  'GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_SUCCESS';

export const GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_ERROR =
  'GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_ERROR';
export const GET_LIST_ENROLLMENT_PLANNING_ACTIVITY_LOG =
  'GET_LIST_ENROLLMENT_PLANNING_ACTIVITY_LOG';
// ENQUIRIES MANAGEMENT
export const SAVE_LEAD_FILTERS = 'SAVE_LEAD_FILTERS';
export const FETCH_ALL_LEADS = 'FETCH_ALL_LEADS';
export const FETCH_ALL_LEADS_SUCCESS = 'FETCH_ALL_LEADS_SUCCESS';
export const FETCH_ALL_LEADS_ERROR = 'FETCH_ALL_LEADS_ERROR';
export const FETCH_LEAD_BY_ID = 'FETCH_LEAD_BY_ID';
export const FETCH_LEAD_BY_ID_SUCCESS = 'FETCH_LEAD_BY_ID_SUCCESS';
export const FETCH_LEAD_BY_ID_ERROR = 'FETCH_LEAD_BY_ID_ERROR';
export const FETCH_ALL_POC_USER = 'FETCH_ALL_POC_USER';
export const FETCH_ALL_POC_USER_SUCCESS = 'FETCH_ALL_POC_USER_SUCCESS';
export const FETCH_ALL_POC_USER_ERROR = 'FETCH_ALL_POC_USER_ERROR';

// Vendor trainer
export const GET_LISTING_VENDOR_TRAINER = 'GET_LISTING_VENDOR_TRAINER';

export const GET_MARKETING_CONSENT_BY_PARENT_REQUEST =
  'GET_MARKETING_CONSENT_BY_PARENT_REQUEST';
export const GET_MARKETING_CONSENT_BY_PARENT_SUCCESS =
  'GET_MARKETING_CONSENT_BY_PARENT_SUCCESS';
export const GET_MARKETING_CONSENT_BY_PARENT_ERROR =
  'GET_MARKETING_CONSENT_BY_PARENT_ERROR';

// Marketing consent
export const FETCH_ALL_BUSINESS_ENTITIES = 'FETCH_ALL_BUSINESS_ENTITIES';
export const FETCH_ALL_BUSINESS_ENTITIES_SUCCESS =
  'FETCH_ALL_BUSINESS_ENTITIES_SUCCESS';
export const FETCH_ALL_BUSINESS_ENTITIES_ERROR =
  'FETCH_ALL_BUSINESS_ENTITIES_ERROR';

export const FETCH_SUGGEST_LEVELS = 'FETCH_SUGGEST_LEVELS';
export const FETCH_SUGGEST_LEVELS_SUCCESS = 'FETCH_SUGGEST_LEVELS_SUCCESS';
export const FETCH_SUGGEST_LEVELS_ERROR = 'FETCH_SUGGEST_LEVELS_ERROR';

// System Announcements
export const FETCH_SYSTEM_ANNOUNCEMENTS_LOADING =
  'FETCH_SYSTEM_ANNOUNCEMENTS_LOADING';
export const FETCH_SYSTEM_ANNOUNCEMENTS_SUCCESS =
  'FETCH_SYSTEM_ANNOUNCEMENTS_SUCCESS';
export const FETCH_SYSTEM_ANNOUNCEMENTS_ERROR =
  'FETCH_SYSTEM_ANNOUNCEMENTS_ERROR';

export const FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_LOADING =
  'FETCH_CURRENT_SYSTEM_ANNOUNCEMENTS_LOADING';
export const FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_SUCCESS =
  'FETCH_CURRENT_SYSTEM_ANNOUNCEMENTS_SUCCESS';
export const FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_ERROR =
  'FETCH_CURRENT_SYSTEM_ANNOUNCEMENTS_ERROR';

export const FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_LOADING =
  'FETCH_ALL_CURRENT_SYSTEM_ANNOUNCEMENTS_SUCCESS';
export const FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_SUCCESS =
  'FETCH_ALL_CURRENT_SYSTEM_ANNOUNCEMENTS_SUCCESS';
export const FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_ERROR =
  'FETCH_ALL_CURRENT_SYSTEM_ANNOUNCEMENTS_SUCCESS';

export const FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_LOADING =
  'FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_LOADING';
export const FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_SUCCESS =
  'FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_SUCCESS';
export const FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_ERROR =
  'FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_ERROR';

// PSS
export const FETCH_SURVEY_DETAILS_PARENT = 'FETCH_SURVEY_DETAILS_PARENT';

// PIM
export const FETCH_PIM_CENTRE_LIST_LOADING = 'FETCH_PIM_CENTRE_LIST_LOADING';
export const FETCH_PIM_CENTRE_LIST_SUCCESS = 'FETCH_PIM_CENTRE_LIST_SUCCESS';
export const FETCH_PIM_CENTRE_LIST_ERROR = 'FETCH_PIM_CENTRE_LIST_ERROR';

export const FETCH_PIM_CENTRE_DETAILS_LOADING =
  'FETCH_PIM_CENTRE_DETAILS_LOADING';
export const FETCH_PIM_CENTRE_DETAILS_SUCCESS =
  'FETCH_PIM_CENTRE_DETAILS_SUCCESS';
export const FETCH_PIM_CENTRE_DETAILS_ERROR = 'FETCH_PIM_CENTRE_DETAILS_ERROR';
