import { parseGraphQLError } from 'utils';
import { SkApolloRequest } from '../../utils/apolloUtils';

import {
  FETCH_PIM_CENTRE_LIST,
  FETCH_PIM_CENTRE_DETAILS,
  UPDATE_PIM_CENTRE_DETAILS,
  UPDATE_PIM_CENTRE_DETAILS_BULK,
} from '../models/parentInteractionModel';

import {
  FETCH_PIM_CENTRE_LIST_LOADING,
  FETCH_PIM_CENTRE_LIST_SUCCESS,
  FETCH_PIM_CENTRE_LIST_ERROR,
  FETCH_PIM_CENTRE_DETAILS_LOADING,
  FETCH_PIM_CENTRE_DETAILS_SUCCESS,
  FETCH_PIM_CENTRE_DETAILS_ERROR,
} from './actionTypes';

const pimCentreListingLoading = () => ({
  type: FETCH_PIM_CENTRE_LIST_LOADING,
  key: 'fetchPIMCentres',
  value: {
    inProgress: true,
  },
});

const pimCentreListingSuccess = data => ({
  type: FETCH_PIM_CENTRE_LIST_SUCCESS,
  key: 'fetchPIMCentres',
  value: {
    inProgress: false,
    data,
  },
});

const pimCentreListingError = error => ({
  type: FETCH_PIM_CENTRE_LIST_ERROR,
  key: 'fetchPIMCentre',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const pimCentreDetailsLoading = () => ({
  type: FETCH_PIM_CENTRE_DETAILS_LOADING,
  key: 'fetchPIMCentreDetails',
  value: {
    inProgress: true,
  },
});

const pimCentreDetailsSuccess = data => ({
  type: FETCH_PIM_CENTRE_DETAILS_SUCCESS,
  key: 'fetchPIMCentreDetails',
  value: {
    inProgress: false,
    data,
  },
});

const pimCentreDetailsError = error => ({
  type: FETCH_PIM_CENTRE_DETAILS_ERROR,
  key: 'fetchPIMCentreDetails',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

export const fetchAllPIMCentres = reqData => async dispatch => {
  dispatch(pimCentreListingLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: FETCH_PIM_CENTRE_LIST,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(pimCentreListingSuccess(data.data));
    } else {
      dispatch(pimCentreListingError(data.error));
    }
  } catch (ex) {
    dispatch(pimCentreListingError(ex.ex.message));
  }
};

export const getPIMCentreDetails = reqData => async dispatch => {
  dispatch(pimCentreDetailsLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: FETCH_PIM_CENTRE_DETAILS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(pimCentreDetailsSuccess(data.data));
    } else {
      dispatch(pimCentreDetailsError(data.error));
    }
  } catch (ex) {
    dispatch(pimCentreDetailsError(ex.ex.message));
  }
};

export const updatePIMCentreDetails = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_PIM_CENTRE_DETAILS,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};

export const updatePIMCentreDetailsBulk = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_PIM_CENTRE_DETAILS_BULK,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return parseGraphQLError(ex);
  }
};
