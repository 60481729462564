import React from 'react';
import { get, isEmpty } from 'lodash';
import { Grid } from '@material-ui/core';
import { LabelField } from '../../../common/InputFields';
import Text from '../../../common/Text';
import Table from '../../../common/Table';
import Loader from '../../../common/Loader';

const ViewPIMCentreDetails = props => {
  const { centreDetails, t, showLoader } = props;
  const tData = [];
  const centreLabels = [
    {
      title: t('pim.mainCategory'),
      active: false,
    },
    {
      title: t('pim.touchpoint'),
      active: false,
    },
    {
      title: t('pim.subCategory'),
      active: false,
    },
    {
      title: t('pim.mapping'),
      active: false,
    },
  ];

  if (
    get(centreDetails, 'Categories') &&
    get(centreDetails, 'Categories.length') > 0
  ) {
    centreDetails.Categories.filter(cat => cat.IsShown).forEach(
      eachCategory => {
        const subCategories = eachCategory.Categories.filter(
          item => item.IsShown
        ).map(item => <p key={item.Name}>{item.Name}</p>);

        let roleInfo = '-';
        const centreName = get(eachCategory, 'Name', '');
        const touchLevel = get(eachCategory, 'TouchLevel')
          ? get(eachCategory, 'TouchLevel')
          : '-';
        if (get(eachCategory, 'TouchLevel')) {
          roleInfo =
            eachCategory.MappedTo === 'roles'
              ? eachCategory.Mapping.filter(
                  item =>
                    item.TouchLevel === eachCategory.TouchLevel &&
                    item.IsChecked
                )
                  .map(item => item.Role.Label)
                  .join(',\n')
              : t('pim.centreMailbox');
        }

        tData.push([
          centreName,
          touchLevel,
          get(subCategories, 'length') > 0 ? subCategories : '-',
          roleInfo,
        ]);
      }
    );
  }

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <LabelField
                labelTextColor
                label={t('centre.centreName')}
                value={get(centreDetails, 'Name', '-')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <LabelField
                labelTextColor
                label={t('common.centreCode')}
                value={get(centreDetails, 'Code', '-')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <LabelField
                labelTextColor
                label="Parent Interaction"
                value={(() => {
                  if (!isEmpty(centreDetails) && 'IsEnabled' in centreDetails) {
                    return centreDetails.IsEnabled ? (
                      <Text color="success">{t('common.enabled')}</Text>
                    ) : (
                      <Text>{t('common.disabled')}</Text>
                    );
                  }
                  return <Text>-</Text>;
                })()}
              />
            </Grid>
          </Grid>
          <Grid
            container
            md={12}
            justify="space-between"
            className="w3-margin-bottom"
          >
            <Text
              body
              bold
              fontSize="18px"
              className="w3-margin-bottom w3-margin-top"
            >
              {t('pim.categoriesAndMapping')}
            </Text>
          </Grid>
          <>
            <Table
              tableCls="s7t-centre-management-table"
              enableHover={false}
              labels={centreLabels}
              tableData={tData}
              noContentText="No centre found."
            />
          </>
        </>
      )}
    </>
  );
};

export default ViewPIMCentreDetails;
