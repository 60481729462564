import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import get from 'lodash/get';
import moment from 'moment';
import { Delete } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Create';
import StopIcon from '@material-ui/icons/Stop';
import {
  CREATE_SYSTEM_ANNOUNCEMENT,
  SETTINGS,
  SYSTEM_ANNOUNCEMENT_DETAILS,
} from 'routes/constants';
import {
  getAllSystemAnnouncements,
  getCurrentSystemAnnouncementByTarget,
  deleteSystemAnnouncement,
  showSnackBarMessage,
  hideSnackBarMessage,
  getAllSystemAnnouncementTargets,
  endSystemAnnouncement,
  setAnnouncementAlert,
} from 'redux/actions';
import { withTranslation, Trans } from 'react-i18next';
import { SelectField } from 'components/common/InputFields';
import UrlPersistenceHOC from '../../../HOC/UrlPersistenceHOC';
import {
  getSortObj,
  getQueryParams,
  parseObjFields,
  replaceUrlParam,
} from '../../../../../utils';
import CommonPageLayout from '../../../../common/Page';
import Button from '../../../../common/Button';
import CommonTable from '../../../../common/Table';
import Loader from '../../../../common/Loader';
import Pagination from '../../../../common/Pagination';
import Dialog from '../../../../common/Modal';
import Text from '../../../../common/Text';
import SystemAnnouncementHandler from './SystemAnnouncement.handler';

const parseQuery = () => {
  const fields = {
    page: 'int',
    perPage: 'int',
    activeSort: 'string',
  };

  const obj = parseObjFields(getQueryParams(), fields);
  return Object.assign(
    {
      page: 1,
      perPage: 10,
      activeSort: '',
    },
    obj
  );
};

const SystemAnnouncement = props => {
  const {
    getAllSystemAnnouncements,
    allSystemAnnouncements,
    getCurrentSystemAnnouncementByTarget,
    currentSystemAnnouncementByTarget,
    deleteSystemAnnouncement,
    history,
    t,
    urlParams,
    changeParam,
    showSnackBarMessage,
    allSystemAnnouncementTargets,
    getAllSystemAnnouncementTargets,
  } = props;

  const { activeSort, perPage, page } = urlParams;
  const { sortVal, sortOrder } = getSortObj(activeSort);
  const [showLoader, setShowLoader] = useState(
    get(getAllSystemAnnouncements, 'inProgress', false)
  );

  const [selectedEndID, setselectedEndID] = useState(null);
  const [endCount, setEndCount] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [targetId, setTargetId] = useState(null);

  const { handleDismiss, endAnnouncement } = SystemAnnouncementHandler(
    props,
    selectedEndID,
    setselectedEndID,
    setEndCount,
    setShowDeleteModal,
    setShowEndModal,
    endCount
  );

  const pageLabel = t('systemAnnouncement.systemAnnouncementName');
  const crumbValues = [
    {
      label: t('common.settingsLabel'),
      link: SETTINGS,
    },
    {
      label: pageLabel,
    },
  ];

  const upComingHeadLabels = [
    {
      title: t('common.title'),
    },
    {
      title: t('common.message'),
    },
    {
      title: 'Targets',
    },
    {
      title: t('systemAnnouncement.startDate'),
    },
    {
      title: t('systemAnnouncement.endDate'),
    },
    {
      title: '',
    },
  ];

  const headLabels = [
    {
      title: t('common.title'),
    },
    {
      title: t('common.message'),
    },
    {
      title: 'Targets',
    },
    {
      title: t('systemAnnouncement.startDate'),
      sortField: 'from',
      active: sortVal.indexOf('from') > -1 ? sortOrder : '',
    },
    {
      title: t('systemAnnouncement.endDate'),
      sortField: 'to',
      active: sortVal.indexOf('to') > -1 ? sortOrder : '',
    },
    {
      title: '',
    },
    {
      title: '',
    },
  ];

  const announcementCallBack = (page, perPage, activeSort) => {
    const pagination = {
      page: parseInt(page, 10),
      perPage: parseInt(perPage, 10),
    };
    if (activeSort) {
      pagination.sort = [getSortObj(activeSort).sortVal];
    }

    getAllSystemAnnouncements({
      pagination,
      target: targetId || 0,
    });
  };

  useEffect(() => {
    announcementCallBack(page, perPage, activeSort);
  }, [deleteCount, targetId]);

  useEffect(() => {
    getAllSystemAnnouncementTargets();
  }, []);

  useEffect(() => {
    getCurrentSystemAnnouncementByTarget({
      target: targetId || 0,
    });
  }, [targetId, endCount, deleteCount]);

  const redirectToAnnouncementDetailsPage = id => {
    history.push({
      pathname: replaceUrlParam(SYSTEM_ANNOUNCEMENT_DETAILS, {
        id,
      }),
      state: {
        isEdit: true,
      },
    });
  };

  const deleteAnnoncement = () => {
    if (selectedAnnouncement) {
      deleteSystemAnnouncement({ ID: selectedAnnouncement }).then(response => {
        setShowDeleteModal(false);
        setShowLoader(false);
        setSelectedAnnouncement(null);
        if (response.success) {
          setDeleteCount(deleteCount + 1);
          handleDismiss();
          showSnackBarMessage(t('systemAnnouncement.deleteSuccess'));
        } else {
          showSnackBarMessage(get(response, 'error[0].message'), 'error');
        }
      });
    }
  };

  const upComingTableItems = [];
  const latestAnnouncement = get(
    currentSystemAnnouncementByTarget,
    'getCurrentSystemAnnouncementV2'
  );

  if (latestAnnouncement && get(latestAnnouncement, 'ID')) {
    const announcementId = get(latestAnnouncement, 'ID');
    const title = get(latestAnnouncement, 'title');
    const message = get(latestAnnouncement, 'message');
    const from = get(latestAnnouncement, 'from');
    const to = get(latestAnnouncement, 'to');

    const systemAnnouncementByTargets = get(
      latestAnnouncement,
      'systemAnnouncementTargetMappings.data'
    );
    const nameArr = systemAnnouncementByTargets.map(
      item => item?.systemAnnouncementTarget?.displayName
    );
    const targetName = nameArr.length > 0 ? nameArr.join(', ') : '';

    upComingTableItems.push([
      title,
      message,
      targetName,
      moment(from).format('YYYY-MM-DD hh:mm A'),
      moment(to).format('YYYY-MM-DD hh:mm A'),
      <Button
        button="icon"
        color="error"
        iconSize="large"
        edge="end"
        onClick={() => {
          setShowEndModal(true);
          setselectedEndID(announcementId);
        }}
      >
        <StopIcon />
      </Button>,
    ]);
  }

  let tableData = [];
  const allAnnouncements = get(allSystemAnnouncements, 'data');

  if (get(allAnnouncements, 'length')) {
    tableData = allAnnouncements.map(item => {
      const announcementId = get(item, 'ID');
      const title = get(item, 'title');
      const message = get(item, 'message');
      const from = get(item, 'from');
      const to = get(item, 'to');

      const systemAnnouncementTargets = get(
        item,
        'systemAnnouncementTargetMappings.data'
      );
      const nameArray = systemAnnouncementTargets.map(
        item => item?.systemAnnouncementTarget?.displayName
      );
      const targetNames = nameArray.length > 0 ? nameArray.join(', ') : '';

      return [
        title,
        message,
        targetNames,
        moment(from).format('YYYY-MM-DD hh:mm A'),
        moment(to).format('YYYY-MM-DD hh:mm A'),
        <Button
          button="icon"
          color="tab"
          iconSize="large"
          edge="end"
          onClick={e => {
            e.stopPropagation();
            redirectToAnnouncementDetailsPage(announcementId);
          }}
        >
          <EditIcon />
        </Button>,
        <Button
          button="icon"
          color="error"
          iconSize="large"
          edge="end"
          onClick={e => {
            e.stopPropagation();
            setSelectedAnnouncement(announcementId);
            setShowDeleteModal(true);
          }}
        >
          <Delete />
        </Button>,
        announcementId,
      ];
    });
  }

  return (
    <CommonPageLayout
      title={pageLabel}
      breadcrumbList={crumbValues}
      className="w3-margin-bottom"
      whiteBackground={false}
    >
      <Paper className="w3-padding w3-padding-24">
        <Grid container>
          <Grid item xs={12}>
            <Grid className="w3-right w3-margin-bottom">
              <Button link={CREATE_SYSTEM_ANNOUNCEMENT}>
                <Trans i18nKey="systemAnnouncement.createAnnouncement" />
              </Button>
            </Grid>
            <Grid>
              <Text h4 bold>
                <Trans i18nKey="systemAnnouncement.currentAnnouncement" />
              </Text>
              <Grid item xs={2} className="w3-margin-top w3-margin-bottom">
                <SelectField
                  name="targetList"
                  id="select-target"
                  emptyMenu
                  onChange={e => {
                    setTargetId(e.target.value);
                  }}
                  value={targetId}
                  options={allSystemAnnouncementTargets.map(item => ({
                    label: item.ID,
                    description: item.displayName,
                  }))}
                />
              </Grid>
            </Grid>
            <Grid container className="s7t-overflow s7t-table-overflow">
              <CommonTable
                noContentText={t(
                  'systemAnnouncement.noActiveSystemAnnouncements'
                )}
                labels={upComingHeadLabels}
                tableData={upComingTableItems}
                bodyRowCls="sn2-latest-announcement"
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper className="w3-padding w3-margin-top w3-padding-24">
        <Grid container>
          <Grid item xs={12}>
            {showLoader ? (
              <Loader />
            ) : (
              <Grid container className="s7t-overflow s7t-table-overflow">
                <CommonTable
                  noContentText={t('systemAnnouncement.noSystemAnnouncements')}
                  labels={headLabels}
                  handleSort={(sort, order) => {
                    changeParam(
                      {
                        activeSort: `${sort}--${order}`,
                      },
                      announcementCallBack(page, perPage, sort)
                    );
                  }}
                  tableData={tableData}
                />
                <Grid item xs={12} md={12} className="w3-padding-16">
                  <Pagination
                    onChange={(page, perPage, activeSort) => {
                      changeParam(
                        {
                          page,
                          perPage,
                        },
                        announcementCallBack(page, perPage, activeSort)
                      );
                    }}
                    defaultPerPage={perPage}
                    currentPage={page}
                    data={{
                      totalCount: get(allSystemAnnouncements, 'totalCount', 0),
                      totalLabel: t('common.total'),
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          {showDeleteModal && (
            <Dialog
              showClose
              fullWidth
              maxWidth="sm"
              isOpen={showDeleteModal}
              dialogTitle={
                <Text h3>
                  <Trans i18nKey="common.delete" />
                </Text>
              }
              dialogContent={
                <Text body>
                  <Trans i18nKey="systemAnnouncement.deleteConfirmation" />
                </Text>
              }
              onPrimaryClick={() => {
                setShowLoader(true);
                deleteAnnoncement();
              }}
              onSecondaryClick={() => {
                setShowDeleteModal(false);
                setSelectedAnnouncement(null);
              }}
              buttonPrimaryName={t('common.delete')}
              buttonSecondaryName={t('common.cancelButton')}
              loading={showLoader}
              onClose={() => setShowDeleteModal(false)}
            />
          )}
          {showEndModal && (
            <Dialog
              showClose
              fullWidth
              maxWidth="sm"
              isOpen={showEndModal}
              dialogTitle={
                <Text h3>
                  <Trans i18nKey="systemAnnouncement.endAnnouncement" />
                </Text>
              }
              dialogContent={
                <Text body>
                  <Trans i18nKey="systemAnnouncement.stopConfirmation" />
                </Text>
              }
              onPrimaryClick={() => {
                setShowEndModal(true);
                endAnnouncement();
              }}
              onSecondaryClick={() => {
                setShowEndModal(false);
                setSelectedAnnouncement(null);
              }}
              buttonPrimaryName={t('common.stop')}
              buttonSecondaryName={t('common.cancelButton')}
              loading={showLoader}
              onClose={() => setShowEndModal(false)}
            />
          )}
        </Grid>
      </Paper>
    </CommonPageLayout>
  );
};
const mapStateToProps = state => ({
  allSystemAnnouncements: get(state, 'allSystemAnnouncements.data', []),
  currentSystemAnnouncementByTarget: get(
    state,
    'currentSystemAnnouncementByTarget.data',
    []
  ),
  allSystemAnnouncementTargets: get(
    state,
    'allSystemAnnouncementTargets.data.data',
    []
  ),
  currentSystemAnnouncement: get(state, 'currentSystemAnnouncement', []),
});

const mapDispatchToProps = {
  showSnackBarMessage,
  hideSnackBarMessage,
  getAllSystemAnnouncements,
  getCurrentSystemAnnouncementByTarget,
  deleteSystemAnnouncement,
  getAllSystemAnnouncementTargets,
  endSystemAnnouncement,
  setAnnouncementAlert,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(UrlPersistenceHOC(withRouter(SystemAnnouncement), parseQuery));
