import React, { Component } from 'react';
import { get, trim } from 'lodash';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import { fetchAllChildrenForBus } from '../../../../redux/actions';
import { SearchField } from '../../../common/InputFields';
import SearchResultComponent from './SearchResultComponent';

class BusChildSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      showLoader: false,
    };
  }

  render() {
    const { search, showLoader } = this.state;
    const {
      cdnUrl,
      centreID,
      modelChildData,
      childrenForBus,
      handleChildSelect,
      fetchAllUnassignedChildren,
      t,
    } = this.props;

    const isLoading = showLoader || get(childrenForBus, 'inProgress', false);

    let searchResults = [];
    if (get(childrenForBus, 'data.length')) {
      searchResults = childrenForBus.data.map(eachResult => {
        const modeledData = modelChildData({
          child: eachResult,
        });

        return {
          data: modeledData,
          comp: (
            <SearchResultComponent
              bc={modeledData.bc}
              name={trim(modeledData.name)}
              cdnUrl={cdnUrl}
              childClass={modeledData.childClass}
              imageKey={eachResult.imageKeyPresignedURL || eachResult.imageKey}
            />
          ),
        };
      });
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SearchField
            name="search"
            placeholder={t('bus_management.childSearchPlaceholder')}
            showLoader={isLoading}
            value={search}
            handleSearch={e => {
              const { value } = e.target;

              this.setState(
                {
                  search: value,
                  showLoader: true,
                },
                () => {
                  fetchAllUnassignedChildren({
                    centreID,
                    search: value,
                  });
                  this.setState({
                    showLoader: false,
                  });
                }
              );
            }}
            handleOptionClick={selected => {
              this.setState(
                {
                  search: selected.name,
                },
                () => {
                  handleChildSelect(selected);
                }
              );
            }}
            handleClear={() => {
              this.setState(
                {
                  search: '',
                },
                handleChildSelect(null)
              );
            }}
            searchResults={searchResults}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  childrenForBus: get(state, 'availableChildrenForBus'),
});

const mapDispatchToProps = {
  fetchAllUnassignedChildren: fetchAllChildrenForBus,
};

export default compose(withTranslation())(
  connect(mapStateToProps, mapDispatchToProps)(BusChildSearch)
);
