import styled from 'styled-components';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const PageContent = styled.div`
  box-shadow: none;
  width: 100%;
  ${props => {
    if (props.whiteBG) {
      return `
                padding: ${props.customPadding ? '12px' : '40px'};
                background-color: ${props.theme.baseColor.neutral.white};
                border-radius: 4px;

                @media ${props.theme.device.mobileL} {
                  background-color: transparent;
                  padding: 0;
                }
            `;
    }
    return `
            border-radius: 0;
            background-color: transparent;
        `;
  }}
`;

export const BreadcrumbComponent = styled(Breadcrumbs)`
  padding: 30px 0;
  margin-bottom: 10px;

  & li.MuiBreadcrumbs-separator {
    color: ${props => get(props, 'theme.baseColor.text.label')};
  }
`;
export const GridWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${props =>
    `
    @media ${props.theme.device.mobileL} {
      justify-content: flex-start;
      margin-top: 10px;
    }
    `}
`;
