import gql from 'graphql-tag';

export const LIST_ALL_BUS_MODEL = gql`
  query listAllBusEntries(
    $centreID: Int
    $status: Boolean
    $year: String
    $direction: BusEntryDirection
    $pagination: Pagination
    $search: String
    $busEntryID: Int
  ) {
    listAllBusEntries(
      centreID: $centreID
      status: $status
      year: $year
      direction: $direction
      pagination: $pagination
      search: $search
      busEntryID: $busEntryID
    ) {
      totalCount
      data {
        ID
        direction
        yearTime
        fkBus
        fkBusDriver
        fkBusAttendant
        active
        status
        label
        busCompany
        bus {
          fkCentre
          plateNumber
          centre {
            label
            ID
          }
        }
        busUserByFkBusDriver {
          firstname
          lastname
          mobilePhoneCountryCode
          mobilePhone
          passcode
        }
        busUserByFkBusAttendant {
          firstname
          lastname
          mobilePhoneCountryCode
          mobilePhone
          passcode
        }
      }
    }
  }
`;

export const ADD_BUS_ROUTE = gql`
  mutation insertBusRoute($busDetail: InsertBusRoute!) {
    insertBusRoute(data: $busDetail) {
      ID
    }
  }
`;

export const UPDATE_BUS_DETAILS = gql`
  mutation updateBusRoute($data: UpdateBusRoute!) {
    updateBusRoute(data: $data) {
      ID
      busCompany
    }
  }
`;

export const LIST_ALL_BUS_CHILDREN = gql`
  query listAllBusChildren(
    $busID: Int
    $centreID: Int
    $levelID: Int
    $classID: Int
    $filter: BusChildFilter
    $pagination: Pagination
  ) {
    listAllBusChildren(
      busID: $busID
      centreID: $centreID
      levelID: $levelID
      classID: $classID
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkChild
        fkBus
        serviceStartDate
        serviceEndDate
        active
        remarks
        locationText
        child {
          ID
          firstname
          lastname
          birthCertificate
          currentLevel {
            fkLevel
            fkCentre
          }
          currentClass {
            fkClass
            class {
              label
            }
          }
          upcomingClass {
            fkClass
            class {
              label
            }
          }
        }
        bus {
          ID
          plateNumber
        }
      }
    }
  }
`;

export const DELETE_BUS_DETAILS = gql`
  mutation deleteBusEntry($ID: Int!) {
    deleteBusEntry(ID: $ID)
  }
`;

export const FETCH_ALL_UNASSIGNED_CHILDREN = gql`
  query findAllChildrenNotAssignedToBus(
    $centreID: Int
    $search: String
    $filter: ChildFilter
    $pagination: Pagination
  ) {
    findAllChildrenNotAssignedToBus(
      centreID: $centreID
      search: $search
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        firstname
        lastname
        birthCertificate
        imageKey
        imageKeyPresignedURL
        currentClass {
          class {
            label
          }
        }
        upcomingClass {
          class {
            label
          }
        }
        upcomingClass {
          class {
            label
          }
        }
        enquiries {
          data {
            ID
            fkCentre
            fkEnquiryState
          }
        }
      }
    }
  }
`;

export const ASSIGN_CHILD_TO_BUS = gql`
  mutation assignChildToBus($data: BusChildCreate!) {
    assignChildToBus(data: $data) {
      ID
    }
  }
`;

export const UPDATE_BUS_CHILD = gql`
  mutation updateChildInBus($busChildID: Int!, $data: BusChildUpdate!) {
    updateChildInBus(busChildID: $busChildID, data: $data) {
      ID
    }
  }
`;

export const DELETE_CHILD_FROM_BUS = gql`
  mutation deleteBusChildById($id: Int!) {
    deleteBusChildById(ID: $id)
  }
`;

export default LIST_ALL_BUS_MODEL;
