import React, { useEffect, useRef, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import CheckedIcon from '@material-ui/icons/CheckBox';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@material-ui/core';
import UncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

import { orderBy, groupBy, get, isEqual, isEmpty } from 'lodash';
import FieldWrapper from '../FieldWrapper';
import {
  InputWrapper,
  Listbox,
  StyledChip,
  CustomWrapper,
  StyledGrid,
} from './style';

/**
 * @desc Used to select multiple tags with search
 *
 *
 * @param string $label: required - label for the field
 * @param string $name: optional - name of the field
 * @param boolean $required: optional - * to denote whether the field is required or not
 * @param string $error: optional - errors for the field
 * @param array $fieldValue: required - array of selected option in format: [{ value: 1, description: abcd }]
 * @param object $options: {value, description} : required - options to list out in the field
 * @param function $onChange: required - function triggered to handle the change in options
 * @param boolean $hasAllOption: optional - boolean to have/ not have the 'All' option
 * @param boolean $allowNew: optional - boolean used to have/not have the create option on the list
 * @param function $handleAddNewItem: optional - function to trigger on create onclick on the list
 * @param function $handleChange: optional - function to get the field change value
 * @param boolean $customColor - custom color for the checkbox in the dropdown
 *
 * @return Component SearchWithMultiSelectField
 */

const LIST_BOX_MAX_HEIGHT = 400;

const flatOptionsProp = groupedOptionList => {
  let order = 0;
  const options = groupedOptionList.reduce((result, row) => {
    const rows = [];
    const { options: optionList, ...group } = row;
    rows.push({ ...group, order: ++order });
    optionList.map(o => {
      rows.push({
        groupId: row.ID,
        ...o,
        order: ++order,
      });
    });
    const newResult = result.concat(rows);
    return newResult;
  }, []);
  return options;
};

const populateAllGroup = (options, hasAllOption) => {
  if (!hasAllOption) {
    return options;
  }
  const group = {
    ID: 'All',
    label: 'All',
    description: 'All',
    value: 'All',
    isGroup: true,
  };
  const newOptions = options.map(o => ({
    ID: !o.ID ? o.description : o.ID,
    ...o,
    groupId: 'All',
  }));
  const newOptionsWithGroup = [group, ...newOptions];
  return newOptionsWithGroup;
};
const populateGroup = (values, options) => {
  const grouped = groupBy(values, v => {
    return v.groupId;
  });
  let newValues = [];
  Object.entries(grouped).forEach(([key, value]) => {
    const _options = options.filter(o => o.groupId === key);
    const _group = options.find(o => o.ID === key);
    if (value.length === _options.length) {
      // add group label
      newValues.push(_group);
    }
    newValues = newValues.concat(value);
  });

  return newValues;
};

const hasValueChanged = (oldValue, newValue) => {
  return !isEqual(
    oldValue.map(o => o.ID),
    newValue.map(o => o.ID)
  );
};

const formatFieldValue = (fvData, optionsParam) => {
  const options = [...optionsParam];
  const result = [];
  const filteredFvData = fvData.filter(fv => !isEmpty(fv));
  filteredFvData.forEach(fv => {
    const newFV = options.find(
      o =>
        (o.ID && fv.ID && o.ID === fv.ID) ||
        o.value === fv.value ||
        o.description === fv.description
    );
    if (newFV) {
      result.push(newFV);
    }
  });
  return result;
};

export const SearchWithMultiSelectField = props => {
  const {
    label,
    name,
    required,
    error,
    fieldValue: fvData,
    options: groupedOptionList,
    onChange,
    hasAllOption,
    allowNew,
    handleAddNewItem,
    handleChange,
    disabled,
    noBackground = false,
    placeholder = 'Search...',
    labelTextColor,
    customColor,
    customStyle,
    hideUnderline = false,
    fieldName = '',
    onClose,
    disableCloseOnSelect = true,
    hideOptionsView = false,
    hideEndorment = false,
    hideInputView = false,
    hideSelectedOption = false,
    inputFieldDisable = false,
    wrapperPadding,
    customMaxHeight = null,
    showLoader = false,
    hint = null,
    customHint = false,
    customHintChild,
    maxItemSelect = 0,
  } = props;

  const options = get(groupedOptionList, '[0].options')
    ? flatOptionsProp(groupedOptionList)
    : populateAllGroup(groupedOptionList, hasAllOption);

  const [populatedValues, setPopulatedValues] = useState([]);

  const [fieldValue, setFieldValue] = useState([]);

  const includeAllOption =
    hasAllOption || get(groupedOptionList, '[0].options');

  const fieldValueProps = formatFieldValue(fvData, options);

  const isFieldValue =
    fieldValueProps && fieldValueProps.length && !hasAllOption;

  useEffect(() => {
    setFieldValue(fieldValueProps);
  }, [isFieldValue]);

  useEffect(() => {
    setFieldValue(fieldValueProps);
  }, [fvData]);

  useEffect(() => {
    setPopulatedValues(populateGroup(fieldValue, options));
  }, [fieldValue]);

  const onRemoveGroupElement = (group, showPopup) => {
    const optionElements = fieldValue.filter(
      fv => fv.groupId !== group.ID && fv.ID !== group.ID
    );
    setFieldValue(optionElements);
    if (!showPopup) {
      onChange(optionElements);
    }
  };
  const onRemoveOptionElement = (option, showPopup) => {
    const optionElements = fieldValue.filter(fv => fv.value !== option.value);
    setFieldValue(optionElements);
    if (!showPopup) {
      onChange(optionElements, get(option, 'ID', null));
    }
  };

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl,
    inputValue,
    popupOpen: showPopup,
    value,
  } = useAutocomplete({
    disableCloseOnSelect,
    multiple: true,
    options,

    value: populatedValues,
    onChange: (event, selectedOptions, reason, detail) => {
      const selectedOption = detail.option;
      if (reason === 'remove-option') {
        if (selectedOption.isGroup) {
          onRemoveGroupElement(selectedOption, showPopup);
        } else {
          onRemoveOptionElement(selectedOption, showPopup);
        }
      }
      if (reason === 'select-option') {
        if (selectedOption.isGroup) {
          // need to check all options of group
          let newSelectedOptions = fieldValue.filter(
            item => item.groupId !== selectedOption.ID
          );
          newSelectedOptions.push(selectedOption);
          options.forEach(item => {
            if (item.groupId === selectedOption.ID) {
              newSelectedOptions.push(item);
            }
          });
          newSelectedOptions = orderBy(
            newSelectedOptions.filter(so => !so.isGroup),
            ['order'],
            ['asc']
          );
          setFieldValue(newSelectedOptions);
        } else {
          const newSelectedOptions = orderBy(
            selectedOptions.filter(so => !so.isGroup),
            ['order'],
            ['asc']
          );

          setFieldValue(newSelectedOptions);
        }
      }
    },
    getOptionSelected: (option, value) => {
      return value && option.value === value.value;
    },
    getOptionLabel: option => option.description,
    onInputChange: handleChange,
    onOpen: () => {
      setTimeout(() => {
        document.body.style.overflow = 'unset';
      }, 300);
    },
    onClose: (e, reason) => {
      if (hasValueChanged(fieldValueProps, fieldValue)) {
        onChange(fieldValue);
      }
      if (onClose) {
        onClose(e, reason);
      }
    },
    ...(maxItemSelect > 0
      ? {
          getOptionDisabled: () =>
            get(populatedValues, 'length', 0) >= maxItemSelect,
        }
      : {}),
  });

  const [maxHeight, setMaxHeight] = useState(LIST_BOX_MAX_HEIGHT);
  const inputRef = useRef(null);
  const inputElmRef = useRef(null);

  const isExistingInOption = (groupedOptions || []).some(
    option => option.description.toLowerCase() === inputValue.toLowerCase()
  );

  const getOptionElements = () => {
    const groupIds = value.filter(item => item.isGroup).map(item => item.ID);
    if (hideSelectedOption) return null;
    return (
      <>
        {value.map((option, index) => {
          if (groupIds.includes(option.groupId)) {
            return null;
          }
          return (
            <StyledChip
              disabled={disabled}
              key={`tag-${index}`}
              label={option.description}
              {...getTagProps({ index })}
            />
          );
        })}
      </>
    );
  };

  let inputActive = false;
  if (document.activeElement.name === fieldName) {
    inputActive = true;
  }

  const getListBoxMaxHeight = () => {
    if (inputRef && inputRef.current) {
      const { top } = inputRef.current.getBoundingClientRect();
      const maxHeightLB = window.innerHeight - top - 50;
      return maxHeightLB > LIST_BOX_MAX_HEIGHT
        ? LIST_BOX_MAX_HEIGHT
        : maxHeightLB;
    }
    return LIST_BOX_MAX_HEIGHT;
  };
  const onClickInput = () => {
    if (!showPopup) {
      setMaxHeight(getListBoxMaxHeight());
    }
  };

  const inputElement = (
    <div {...getRootProps()}>
      <Input
        inputRef={inputElmRef}
        name={fieldName || name}
        className={disabled ? 'sn2-hide-input' : ''}
        disabled={inputFieldDisable}
        placeholder={placeholder}
        {...getInputProps()}
        onClick={onClickInput}
        disableUnderline={hideUnderline}
        endAdornment={
          !hideEndorment && (
            <InputAdornment disablePointerEvents position="end">
              <ArrowDropDownIcon color="action" />
            </InputAdornment>
          )
        }
      />
    </div>
  );

  return (
    <FieldWrapper
      required={required}
      label={label}
      name={name}
      error={error}
      disabled={disabled}
      labelTextColor={labelTextColor}
      wrapperPadding={wrapperPadding}
      hint={hint}
      customHintChild={customHintChild}
      customHint={customHint}
    >
      <InputWrapper
        noBackground={noBackground}
        ref={setAnchorEl}
        style={
          !customStyle
            ? { display: 'block', flexWrap: 'none', background: 'inherit' }
            : {}
        }
      >
        {customStyle ? (
          <StyledGrid container xs={12} inputActive={inputActive}>
            <Grid item style={{ display: 'contents' }}>
              {getOptionElements()}
            </Grid>
            <CustomWrapper
              item
              style={{
                minWidth: fieldName === 'tagSearchField' ? '200px' : '400px',
              }}
            >
              {!hideInputView && inputElement}
            </CustomWrapper>
          </StyledGrid>
        ) : (
          <>
            {getOptionElements()}
            {!hideInputView && inputElement}
          </>
        )}
      </InputWrapper>
      {!hideOptionsView && (
        <Listbox
          {...getListboxProps()}
          style={{ maxHeight: customMaxHeight || maxHeight }}
          ref={inputRef}
          customColor={customColor}
        >
          {!disabled &&
            (groupedOptions || []).map((option, index) => {
              const optProps = getOptionProps({ option, index });
              const disabled =
                get(optProps, 'aria-disabled') &&
                !value.map(v => v.value).includes(option.value);
              return (
                <MenuItem
                  key={`menu-item-${index}`}
                  {...{ ...optProps, disabled }}
                >
                  <div
                    style={{
                      display: 'flex',
                      marginLeft:
                        includeAllOption && !option.isGroup ? '30px' : 0,
                      flexGrow: 1,
                    }}
                  >
                    <CheckedIcon data-checked="true" />
                    <UncheckedIcon data-checked="false" />
                    <span>{option.description}</span>
                  </div>
                </MenuItem>
              );
            })}
          {allowNew && inputValue && !isExistingInOption && (
            <MenuItem>
              <span
                onClick={() => handleAddNewItem(inputValue)}
              >{`+ Create ${inputValue}`}</span>
            </MenuItem>
          )}
          {showLoader && <MenuItem>Loading</MenuItem>}
        </Listbox>
      )}
    </FieldWrapper>
  );
};

SearchWithMultiSelectField.defaultProps = {
  handleAddNewItem: () => {},
  fieldValue: [],
  name: '',
  handleChange: () => {},
  required: false,
  allowNew: false,
  hasAllOption: false,
  disableCloseOnSelect: true,
  placeholder: 'Search...',
  error: '',
  hideOptionsView: false,
  hideEndorment: false,
  hideInputView: false,
  maxItemSelect: 0,
};
SearchWithMultiSelectField.propTypes = {
  handleAddNewItem: PropTypes.func,
  fieldValue: PropTypes.arrayOf(PropTypes.shape),
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onChange: PropTypes.func.isRequired,
  hasAllOption: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
  allowNew: PropTypes.bool,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  hideOptionsView: PropTypes.bool,
  hideEndorment: PropTypes.bool,
  hideInputView: PropTypes.bool,
  maxItemSelect: PropTypes.number,
};

export default SearchWithMultiSelectField;
