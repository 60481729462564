import gql from 'graphql-tag';

export const ME = gql`
  query {
    me {
      ID
      firstname
      lastname
      email
      linkpassUID
      userRoleRelations {
        data {
          ID
          role {
            ID
            label
            name
            isStaff
            roleTemplates {
              data {
                fkModuleCategory
                access
              }
            }
          }
        }
      }
      birthdate
      imageKey
      imageKeyPresignedURL
      gender
      homePhone
      mobilePhone
      mobilePhoneCountryCode
      isHQStaff
      hasCentreScopeAccess
      hasClassScopeAccess
      userAccessControls {
        data {
          school {
            ID
            name
          }
          centre {
            ID
            label
            school {
              ID
              name
            }
          }
        }
      }
      parent {
        ID
        monthlyIncome
        workplaceStaff
      }
      registrationParent {
        fkParent
        registrationChildParents {
          data {
            registrationChild {
              ID
              fkChild
              firstname
              lastname
              race
              nationality
              dateOfBirth
              birthCertificate
              householdIncomeRange
            }
          }
        }
      }
      childRelations {
        data {
          child {
            ID
            firstname
            lastname
            race
            nationality
            imageKey
            imageKeyPresignedURL
            dateOfBirth
            birthCertificate
            householdIncomeRange
            childClasses {
              data {
                fkClass
                from
                to
                program {
                  from
                  to
                  label
                }
                class {
                  ID
                  fkCentre
                  fkLevel
                  label
                }
              }
            }
            currentLevel {
              fkCentre
              centre {
                ID
                label
              }
            }
            closestLevel {
              ID
              fkCentre
              centre {
                ID
                label
              }
            }
          }
        }
      }
    }
  }
`;

export const RegisterWithLinkPassInfo = gql`
  query registerWithLinkPassInfo($token: String!, $linkPassIdToken: String!) {
    registerWithLinkPassInfo(token: $token, linkPassIdToken: $linkPassIdToken) {
      LinkPassAuthTokenClaimDto {
        UID
        Name
        Email
        CountryCode
        PhoneNumber
      }
      User {
        ID
        firstname
        lastname
        email
        mobilePhone
        mobilePhoneCountryCode
      }
      LinkPassCompareWithUser {
        IsDiffEmail
        IsDiffPhone
      }
    }
  }
`;

export const LinkpassSN2UserInfo = gql`
  query linkpassSn2UserInfo(
    $email: String!
    $password: String!
    $linkPassIdToken: String!
  ) {
    linkpassSn2UserInfo(
      email: $email
      password: $password
      linkPassIdToken: $linkPassIdToken
    ) {
      LinkPassAuthTokenClaimDto {
        UID
        Name
        Email
        CountryCode
        PhoneNumber
      }
      User {
        ID
        firstname
        lastname
        email
        mobilePhone
        mobilePhoneCountryCode
      }
      LinkPassCompareWithUser {
        IsDiffEmail
        IsDiffPhone
      }
    }
  }
`;

export const LinkUserWithLinkPassAndLogin = gql`
  query linkUserWithLinkPassAndLogin(
    $email: String!
    $password: String!
    $linkPassIdToken: String!
  ) {
    linkUserWithLinkPassAndLogin(
      email: $email
      password: $password
      linkPassIdToken: $linkPassIdToken
    )
  }
`;

export const RegisterWithLinkpass = gql`
  mutation RegisterWithLinkPass($token: String!, $linkPassIdToken: String!) {
    registerWithLinkPass(token: $token, linkPassIdToken: $linkPassIdToken) {
      ID
    }
  }
`;

export const UpdateElectronicConsentStatusGQL = gql`
  mutation updateElectronicConsentStatus(
    $tokenString: String!
    $consentStatus: Boolean!
  ) {
    updateElectronicConsentStatus(
      tokenString: $tokenString
      consentStatus: $consentStatus
    )
  }
`;

export const LinkUserWithLinkPass = gql`
  mutation linkUserWithLinkPass($email: String!, $linkPassIdToken: String!) {
    linkUserWithLinkPass(email: $email, linkPassIdToken: $linkPassIdToken)
  }
`;

export const LinkpassUserInfoFromClaim = gql`
  query linkpassUserInfoFromClaim($linkPassIdToken: String!) {
    linkpassUserInfoFromClaim(linkPassIdToken: $linkPassIdToken) {
      LinkPassAuthTokenClaimDto {
        UID
        Email
        CountryCode
        PhoneNumber
      }
      User {
        ID
        email
        mobilePhoneCountryCode
        mobilePhone
      }
      LinkPassCompareWithUser {
        IsDiffEmail
        IsDiffPhone
      }
    }
  }
`;

export const LoginByLinkPass = gql`
  query loginByLinkPass($linkPassIdToken: String!) {
    loginByLinkPass(linkPassIdToken: $linkPassIdToken)
  }
`;

export const LOGIN = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const LOGIN_FOR_EP_TOKEN = gql`
  query loginEP {
    loginEP
  }
`;

export const GET_APPROVER_LIST = gql`
  query getAccessibleCentresForModule(
    $module: String!
    $accessType: AccessType
    $pagination: Pagination
    $includeClosedCentres: Boolean
  ) {
    getAccessibleCentresForModule(
      module: $module
      accessType: $accessType
      pagination: $pagination
      includeClosedCentres: $includeClosedCentres
    ) {
      data {
        ID
        fkSchool
        label
        code
        ecdaCode
      }
    }
  }
`;
