/* eslint-disable camelcase */
import menu from './menu.json';
import common from './common.json';
import enrichment_programme from './enrichment_programme.json';
import enquiry from './enquiry.json';
import principalDashboard from './principalDashboard.json';
import registration from './registration.json';
import parentDashboard from './parentDashboard.json';
import auth from './auth.json';
import waitlist from './waitlist.json';
import waitlistParameters from './waitlistParameters.json';
import broadcast from './broadcast.json';
import childprofile from './childprofile.json';
import withdrawal from './withdrawal.json';
import calendar from './calendar.json';
import vacancy from './vacancy.json';
import classManagement from './classManagement.json';
import userRoleManagement from './userRoleManagement.json';
import finance from './finance.json';
import event from './event.json';
import discount from './discount.json';
import feemanagement from './feemanagement.json';
import centreManagement from './centreManagement.json';
import centre from './centre.json';
import bus_management from './bus_management.json';
import error from './error.json';
import holiday_management from './holiday_management.json';
import level_management from './level_management.json';
import attendance from './attendance.json';
import blacklist from './blacklist.json';
import classActivity from './classActivity.json';
import enrichment_class from './enrichment_class.json';
import portfolioManagement from './portfolioManagement.json';
import observation from './observation.json';
import myDownload from './myDownload.json';
import enquiriesManagement from './enquiriesManagement.json';
import linkpassFAQs from './linkpassFAQs.json';
import marketingConsent from './marketingConsent.json';
import systemAnnouncement from './systemAnnouncement.json';
import pim from './pim.json';

export default {
  ...menu,
  ...common,
  ...enrichment_programme,
  ...enquiry,
  ...principalDashboard,
  ...registration,
  ...parentDashboard,
  ...auth,
  ...waitlist,
  ...waitlistParameters,
  ...broadcast,
  ...childprofile,
  ...withdrawal,
  ...calendar,
  ...vacancy,
  ...classManagement,
  ...userRoleManagement,
  ...finance,
  ...event,
  ...discount,
  ...feemanagement,
  ...centreManagement,
  ...centre,
  ...bus_management,
  ...error,
  ...holiday_management,
  ...level_management,
  ...attendance,
  ...blacklist,
  ...classActivity,
  ...enrichment_class,
  ...portfolioManagement,
  ...observation,
  ...myDownload,
  ...enquiriesManagement,
  ...linkpassFAQs,
  ...marketingConsent,
  ...systemAnnouncement,
  ...pim,
};
