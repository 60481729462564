import gql from 'graphql-tag';

export const ADD_NEW_SYSTEM_ANNOUNCEMENT_V2 = gql`
  mutation addSystemAnnouncementV2(
    $announcement: SystemAnnouncementCreate!
    $targetIDs: [Int!]
  ) {
    addSystemAnnouncementV2(
      announcement: $announcement
      targetIDs: $targetIDs
    ) {
      ID
    }
  }
`;

export const UPDATE_SYSTEM_ANNOUNCEMENT_V2 = gql`
  mutation updateSystemAnnouncementV2(
    $ID: Int!
    $announcement: SystemAnnouncementUpdate!
    $targetIDs: [Int!]
  ) {
    updateSystemAnnouncementV2(
      ID: $ID
      announcement: $announcement
      targetIDs: $targetIDs
    ) {
      ID
    }
  }
`;

export const DELETE_SYSTEM_ANNOUNCEMENT = gql`
  mutation deleteSystemAnnouncement($ID: Int!) {
    deleteSystemAnnouncement(ID: $ID)
  }
`;

export const END_SYSTEM_ANNOUNCEMENT = gql`
  mutation endSystemAnnouncement($ID: Int!) {
    endSystemAnnouncement(ID: $ID)
  }
`;

export const GET_ALL_SYSTEM_ANNOUNCEMENTS = gql`
  query getAllSystemAnnouncementsV2($pagination: Pagination, $target: Int!) {
    getAllSystemAnnouncementsV2(pagination: $pagination, target: $target) {
      totalCount
      data {
        ID
        title
        message
        from
        to
        fkSchool
        createdAt
        updatedAt
        systemAnnouncementTargetMappings {
          data {
            ID
            systemAnnouncementTarget {
              ID
              name
              displayName
            }
          }
        }
      }
    }
  }
`;

export const GET_CURRENT_SYSTEM_ANNOUNCEMENT_V2 = gql`
  query getCurrentSystemAnnouncementV2($target: Int!) {
    getCurrentSystemAnnouncementV2(target: $target) {
      ID
      title
      message
      from
      to
    }
  }
`;

export const GET_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET = gql`
  query getCurrentSystemAnnouncementV2($target: Int!) {
    getCurrentSystemAnnouncementV2(target: $target) {
      ID
      title
      message
      from
      to
      systemAnnouncementTargetMappings {
        data {
          ID
          systemAnnouncementTarget {
            ID
            name
            displayName
          }
        }
      }
    }
  }
`;

export const GET_ALL_SYSTEM_ANNOUNCEMENT_TARGETS = gql`
  query getAllSystemAnnouncementTargets {
    getAllSystemAnnouncementTargets {
      data {
        ID
        name
        displayName
        active
      }
    }
  }
`;
