import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import Text from 'components/common/Text';
import Pagination from 'components/common/Pagination';
import compose from 'recompose/compose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SearchField } from 'components/common/InputFields';
import Loader from 'components/common/Loader';
import UrlPersistenceHOC from '../HOC/UrlPersistenceHOC';
import ParentInteractionHandler from './ParentInteraction.handler';
import { getQueryParams, parseObjFields } from '../../../utils';
import Table from '../../common/Table';
import CommonPage from '../../common/Page';
import { fetchAllPIMCentres } from '../../../redux/actions';
import { SETTINGS, PARENT_INTERACTION } from '../../../routes/constants';

const parseQuery = () => {
  const fields = {
    page: 'int',
    activeSort: 'string',
    searchVal: 'string',
  };

  const obj = parseObjFields(getQueryParams(), fields);
  return Object.assign(
    {
      page: 1,
      activeSort: '',
      searchVal: '',
    },
    obj
  );
};

const ParentInteraction = props => {
  const {
    t,
    urlParams = {},
    showLoader,
    handleChange,
    handleSearchClear,
    showTableLoader,
    redirectToCentreDetailsPage,
    onPaginationChanged,
    pimCentres,
    totalCount,
  } = ParentInteractionHandler(props);

  const { page, perPage, searchVal, activeSort } = urlParams;
  const crumbValues = [
    {
      label: t('common.centreManagementLabel'),
      link: PARENT_INTERACTION,
    },
    {
      label: t('pim.parentInterAction'),
      link: SETTINGS,
    },
  ];

  const tData = [];
  const centreLabels = [
    {
      title: t('common.name'),
      active: false,
    },
    {
      title: t('common.centreCode'),
      active: false,
    },
    {
      title: t('pim.parentInterAction'),
      active: false,
    },
    {
      title: t('common.updatedAt'),
      active: false,
    },
    {
      title: t('common.updatedBy'),
      active: false,
    },
  ];

  if (pimCentres?.data) {
    if (pimCentres.data.length > 0) {
      pimCentres.data.forEach(eachCentre => {
        tData.push([
          eachCentre.Name,
          eachCentre.Code,
          eachCentre.IsEnabled ? (
            <Text color="success">{t('common.enabled')}</Text>
          ) : (
            <Text color="grey">{t('common.disabled')}</Text>
          ),
          moment(eachCentre.UpdatedAt).format('YYYY-MM-DD hh:mm A'),
          eachCentre.UpdatedBy ? eachCentre.UpdatedBy : '',
        ]);
      });
    }
  }

  if (activeSort) {
    const activeParts = activeSort.split('---');
    const activeKey = activeParts[0];
    const activeOrder = activeParts[1];

    centreLabels.forEach(eachCentre => {
      if (activeKey.indexOf(eachCentre.sortField) > -1) {
        eachCentre.active = activeOrder;
      }
    });
  }

  return (
    <CommonPage
      title={t('pim.parentInterAction')}
      breadcrumbList={crumbValues}
      whiteBackground={false}
    >
      <Grid
        container
        className="s7t-full-width sn2-feeContentWrapper s7t-background-white"
      >
        <Grid item xs={12} className="w3-margin-bottom">
          <Grid container>
            <Grid item xs={6} className="w3-left-align w3-margin-bottom">
              <SearchField
                name="searchVal"
                showLoader={showLoader}
                value={searchVal}
                placeholder={t('pim.centreCodeSearchPlaceHolder')}
                handleSearch={handleChange}
                handleClear={handleSearchClear}
                hideSearchResults
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="s7t-centre-listing-container">
            {showTableLoader ? (
              <Loader />
            ) : (
              <>
                <Table
                  tableCls="s7t-centre-management-table"
                  enableHover
                  onRowClick={(item, idx) => {
                    redirectToCentreDetailsPage(pimCentres.data[idx]);
                  }}
                  labels={centreLabels}
                  tableData={tData}
                  showTableLoader={showLoader}
                  noContentText={t('pim.noCentreText')}
                />

                <Grid item xs={12}>
                  <Pagination
                    onChange={onPaginationChanged}
                    defaultPerPage={perPage}
                    currentPage={page}
                    data={{
                      totalCount,
                    }}
                  />
                </Grid>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </CommonPage>
  );
};

const mapStateToProps = state => ({
  totalCount: get(state, 'fetchPIMCentres.data.totalCount', 0),
  pimCentres: get(state, 'fetchPIMCentres.data'),
});

const mapDispatchToProps = {
  fetchAllPIMCentres,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(UrlPersistenceHOC(withRouter(ParentInteraction), parseQuery));
