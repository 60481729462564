import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  ADD_NEW_SYSTEM_ANNOUNCEMENT_V2,
  GET_ALL_SYSTEM_ANNOUNCEMENTS,
  UPDATE_SYSTEM_ANNOUNCEMENT_V2,
  DELETE_SYSTEM_ANNOUNCEMENT,
  GET_CURRENT_SYSTEM_ANNOUNCEMENT_V2,
  GET_ALL_SYSTEM_ANNOUNCEMENT_TARGETS,
  GET_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET,
  END_SYSTEM_ANNOUNCEMENT,
} from '../models/systemAnnouncementModel';
import {
  FETCH_SYSTEM_ANNOUNCEMENTS_LOADING,
  FETCH_SYSTEM_ANNOUNCEMENTS_SUCCESS,
  FETCH_SYSTEM_ANNOUNCEMENTS_ERROR,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_LOADING,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_SUCCESS,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_ERROR,
  FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_LOADING,
  FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_SUCCESS,
  FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_ERROR,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_LOADING,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_SUCCESS,
  FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_ERROR,
  SET_ALERT,
} from './actionTypes';
import DatadogHandler from '../../utils/datadog';

const systemAnnouncementsLoading = () => ({
  type: FETCH_SYSTEM_ANNOUNCEMENTS_LOADING,
  key: 'allSystemAnnouncements',
  value: {
    inProgress: true,
  },
});

const systemAnnouncementsSuccess = data => ({
  type: FETCH_SYSTEM_ANNOUNCEMENTS_SUCCESS,
  key: 'allSystemAnnouncements',
  value: {
    inProgress: false,
    data,
  },
});

const systemAnnouncementsError = error => ({
  type: FETCH_SYSTEM_ANNOUNCEMENTS_ERROR,
  key: 'allSystemAnnouncements',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const currentSystemAnnouncementLoading = () => ({
  type: FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_LOADING,
  key: 'currentSystemAnnouncement',
  value: {
    inProgress: true,
  },
});

const currentSystemAnnouncementSuccess = data => ({
  type: FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_SUCCESS,
  key: 'currentSystemAnnouncement',
  value: {
    inProgress: false,
    data,
  },
});

const currentSystemAnnouncementError = error => ({
  type: FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_ERROR,
  key: 'currentSystemAnnouncement',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const currentSystemAnnouncementByTargetLoading = () => ({
  type: FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_LOADING,
  key: 'currentSystemAnnouncementByTarget',
  value: {
    inProgress: true,
  },
});

const currentSystemAnnouncementByTargetSuccess = data => ({
  type: FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_SUCCESS,
  key: 'currentSystemAnnouncementByTarget',
  value: {
    inProgress: false,
    data,
  },
});

const currentSystemAnnouncementByTargetError = error => ({
  type: FETCH_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET_ERROR,
  key: 'currentSystemAnnouncementByTarget',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const systemAnnouncementTargetsLoading = () => ({
  type: FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_LOADING,
  key: 'systemAnnouncementTargets',
  value: {
    inProgress: true,
  },
});

const systemAnnouncementTargetsSuccess = data => ({
  type: FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_SUCCESS,
  key: 'systemAnnouncementTargets',
  value: {
    inProgress: false,
    data,
  },
});

const systemAnnouncementTargetsError = error => ({
  type: FETCH_SYSTEM_ANNOUNCEMENTS_TARGETS_ERROR,
  key: 'systemAnnouncementTargets',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

export const addNewSystemAnnouncement = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: ADD_NEW_SYSTEM_ANNOUNCEMENT_V2,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    DatadogHandler.sendLog(ex.message, {}, 'error');
  }
};

export const updateSystemAnnouncement = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_SYSTEM_ANNOUNCEMENT_V2,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    DatadogHandler.sendLog(ex.message, {}, 'error');
  }
};

export const deleteSystemAnnouncement = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: DELETE_SYSTEM_ANNOUNCEMENT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    DatadogHandler.sendLog(ex.message, {}, 'error');
  }
};

export const endSystemAnnouncement = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: END_SYSTEM_ANNOUNCEMENT,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    DatadogHandler.sendLog(ex.message, {}, 'error');
  }
};

export const getAllSystemAnnouncements = reqData => async dispatch => {
  dispatch(systemAnnouncementsLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ALL_SYSTEM_ANNOUNCEMENTS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(systemAnnouncementsSuccess(data.data));
    } else {
      dispatch(systemAnnouncementsError(data.error));
    }
  } catch (ex) {
    dispatch(systemAnnouncementsError(ex.message));
  }
};

export const getCurrentSystemAnnouncement = reqData => async dispatch => {
  dispatch(currentSystemAnnouncementLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CURRENT_SYSTEM_ANNOUNCEMENT_V2,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(currentSystemAnnouncementSuccess(data.data));
    } else {
      dispatch(currentSystemAnnouncementError(data.error));
    }
  } catch (ex) {
    dispatch(currentSystemAnnouncementError(ex.message));
  }
};

export const getAllSystemAnnouncementTargets = () => async dispatch => {
  dispatch(systemAnnouncementTargetsLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ALL_SYSTEM_ANNOUNCEMENT_TARGETS,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(systemAnnouncementTargetsSuccess(data.data));
    } else {
      dispatch(systemAnnouncementTargetsError(data.error));
    }
  } catch (ex) {
    dispatch(systemAnnouncementTargetsError(ex.message));
  }
};

export const getCurrentSystemAnnouncementByTarget = reqData => async dispatch => {
  dispatch(currentSystemAnnouncementByTargetLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CURRENT_SYSTEM_ANNOUNCEMENT_BY_TARGET,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(currentSystemAnnouncementByTargetSuccess(data.data));
    } else {
      dispatch(currentSystemAnnouncementByTargetError(data.error));
    }
  } catch (ex) {
    dispatch(currentSystemAnnouncementByTargetError(ex.message));
  }
};

export const setAnnouncementAlert = value => ({
  type: SET_ALERT,
  value,
});
