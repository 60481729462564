import { get } from 'lodash';
import {
  FETCH_PIM_CENTRE_LIST_LOADING,
  FETCH_PIM_CENTRE_LIST_SUCCESS,
  FETCH_PIM_CENTRE_LIST_ERROR,
  FETCH_PIM_CENTRE_DETAILS_LOADING,
  FETCH_PIM_CENTRE_DETAILS_SUCCESS,
  FETCH_PIM_CENTRE_DETAILS_ERROR,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

export const fetchPIMCentres = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_PIM_CENTRE_LIST_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_PIM_CENTRE_LIST_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.listPimCentreDetails'),
      };
    case FETCH_PIM_CENTRE_LIST_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const fetchPIMCentreDetails = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_PIM_CENTRE_DETAILS_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_PIM_CENTRE_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getPimCentreDetails'),
      };
    case FETCH_PIM_CENTRE_DETAILS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};
