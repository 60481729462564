import React, { useState, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import compose from 'recompose/compose';
import { get } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Create';
import CommonPage from '../../../common/Page';
import Text from '../../../common/Text';
import Button from '../../../common/Button';
import { SETTINGS, PARENT_INTERACTION } from '../../../../routes/constants';
import ViewPIMCentreDetails from './ViewPIMCentreDetails';
import UpdateCentreConfig from './UpdateCentreConfig';
import {
  getPIMCentreDetails,
  showSnackBarMessage,
  updatePIMCentreDetails,
  updatePIMCentreDetailsBulk,
} from '../../../../redux/actions';

const CentreDetailsPIM = props => {
  const path = window.location.pathname;
  const segments = path.split('/');
  const pimCentreID = segments[segments.length - 1];
  const {
    t,
    getPIMCentreDetails,
    pimCentreDetails,
    updatePIMCentreDetails,
    showSnackBarMessage,
    updatePIMCentreDetailsBulk,
    userRole,
  } = props;

  const isUserEditAccess =
    get(userRole, 'data.length') > 0 &&
    get(userRole, 'data').some(
      role => role.fkModuleCategory === 'pim_setting' && role.access === 7
    );
  const [isEdit, setIsEdit] = useState(false);
  const [showLoader, setShowLoader] = useState(
    get(pimCentreDetails, 'inProgress', false)
  );

  const crumbValues = [
    {
      label: t('common.centreManagementLabel'),
      link: PARENT_INTERACTION,
    },
    {
      label: t('pim.parentInterAction'),
      link: SETTINGS,
    },
  ];

  const fetchPimCentreData = () => {
    setShowLoader(true);
    getPIMCentreDetails({
      pimCentreID: parseInt(pimCentreID, 10),
    }).then(() => {
      setShowLoader(false);
    });
  };

  useEffect(() => {
    fetchPimCentreData();
  }, [pimCentreID, isEdit]);

  return (
    <CommonPage
      title={t('pim.parentInterAction')}
      breadcrumbList={crumbValues}
      whiteBackground={false}
    >
      <Grid
        container
        className="s7t-full-width sn2-feeContentWrapper s7t-background-white"
      >
        <Grid item xs={12}>
          <Paper elevation={0} className="s7t-padding-44 ">
            <Grid container md={12} justify="space-between">
              <Text body bold fontSize="18px" className="w3-margin-bottom">
                <Trans i18nKey="pim.pimSettings" />
              </Text>
              {isUserEditAccess && get(pimCentreDetails, 'data.ID') && (
                <Button
                  isNewDesign
                  size="medium"
                  variant="none"
                  onClick={() => setIsEdit(true)}
                  color="#38576B"
                  disabled={isEdit}
                >
                  <EditIcon />
                  <span>{t('common.edit')}</span>
                </Button>
              )}
            </Grid>
            {isEdit ? (
              <UpdateCentreConfig
                t={t}
                centreDetails={get(pimCentreDetails, 'data')}
                setIsEdit={setIsEdit}
                updatePIMCentreDetails={updatePIMCentreDetails}
                showSnackBarMessage={showSnackBarMessage}
                setShowLoader={setShowLoader}
                showLoader={showLoader}
                updatePIMCentreDetailsBulk={updatePIMCentreDetailsBulk}
              />
            ) : (
              <ViewPIMCentreDetails
                t={t}
                centreDetails={get(pimCentreDetails, 'data')}
                centreID={pimCentreID}
                showLoader={showLoader}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </CommonPage>
  );
};

const mapStateToProps = state => ({
  pimCentreDetails: get(state, 'fetchPIMCentreDetails'),
  userRole: get(
    state,
    'userDetails.data.userRoleRelations.data[0].role.roleTemplates'
  ),
});

const mapDispatchToProps = {
  getPIMCentreDetails,
  updatePIMCentreDetails,
  showSnackBarMessage,
  updatePIMCentreDetailsBulk,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(CentreDetailsPIM));
