import gql from 'graphql-tag';

export const FETCH_ALL_CENTRE_WAITLIST = gql`
  query findAllLeads(
    $centreIds: [Int!]
    $searchKey: String
    $active: String
    $tags: [Int!]
    $enrollmentFrom: Datetime
    $enrollmentTo: Datetime
    $status: [String!]
    $leadFilter: LeadFilter
    $pagination: Pagination
  ) {
    findAllLeads(
      centreIds: $centreIds
      searchKey: $searchKey
      active: $active
      tags: $tags
      enrollmentFrom: $enrollmentFrom
      enrollmentTo: $enrollmentTo
      status: $status
      leadFilter: $leadFilter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        type
        status
        expireAt
        firstEnquireDate
        centrePreference
        enrolmentDate
        createdAt
        weightage
        CurrentLeadFrontendStatus
        AnotherActiveLeadStatus
        centre {
          label
          code
          ID
        }
        level {
          label
          ID
        }
        program {
          label
          ID
        }

        leadChild {
          childBirthCertificate
          parentName
          parentEmail
          parentMobilePhone
          parentMobilePhoneCountryCode
          childName
          childDateOfBirth
        }
      }
    }
  }
`;

export const FETCH_DETAIL_ENQUIRY = gql`
  query leadByID(
    $ID: Int!
    $filter: LeadFilter
    $filterBusinessEntity: MarketingConsentBusinessEntityFilter
  ) {
    leadByID(ID: $ID, filter: $filter) {
      ID
      fkLeadChild
      enrolmentDate
      updatedAt
      CurrentLeadFrontendStatus
      referenceNumber
      firstEnquireDate
      centrePreference
      marketingConsentByFkMarketingConsent {
        ID
        marketingConsentUser {
          email
        }
        user {
          email
        }
        marketingConsentBusinessEntities(filter: $filterBusinessEntity) {
          data {
            phone
            sms
            email
          }
        }
      }
      leadChild {
        ID
        isUnborn
        childName
        childDateOfBirth
        childOriginBirthCertificate
        childBirthCertificate
        nationality
        gender
        race
        parentIdentificationNo
        fkParentRelationship
        LastUpdatedBy {
          firstname
          lastname
        }
        codeByFkParentRelationship {
          ID
          label
          description
        }
        parentName
        parentEmail
        parentMobilePhone
        parentMobilePhoneCountryCode
        parentWorkplace
        updatedAt
        postalCode
        householdIncome
        address
        building
        unit
        floor
        blockNo
        codeByFkFirstChannel {
          ID
          label
          description
        }
        codeByFkFirstLeadSource {
          ID
          label
          description
        }
        codeByFkFirstMedium {
          ID
          label
          description
        }
        adContent
        campaign
        referralCode
        notes
        marketingConsent
        leads {
          data {
            ID
            CurrentLeadFrontendStatus
            centrePreference
            enrolmentDate
            firstEnquireDate
            expireAt
            weightage
            type
            updatedAt
            createdAt
            remarks
            code {
              ID
              label
              description
            }
            LastUpdatedBy {
              ID
              firstname
              lastname
            }
            centre {
              ID
              label
            }
            program {
              ID
              label
            }
            level {
              ID
              label
            }
            registrations {
              data {
                ID
                fkUser
                registrationChildMappings {
                  data {
                    fkRegistrationChild
                  }
                }
              }
            }
            AllTagOfLeads {
              data {
                ID
                label
                weight
                description
                createdAt
                active
              }
            }
          }
        }
        leadActivityLogs {
          data {
            staff {
              ID
              userByFkUser {
                ID
                firstname
                lastname
              }
            }
            codeByFkChannel {
              ID
              label
              description
            }
            codeByFkLeadSource {
              ID
              label
              description
            }
            codeByFkMedium {
              ID
              label
              description
            }
            centre {
              label
              ID
              code
            }
            campaign
            adContent
            referralCode
            ID
            enquireDate
            parentName
            parentEmail
            correspondence
            timeOfCorrespondence
            enquireDate
            subject
            parentMobilePhoneCountryCode
            parentMobilePhone
          }
        }
      }
    }
  }
`;

export const OFFER_LEAD = gql`
  mutation offerLead(
    $centreID: Int!
    $leadID: Int!
    $BypassExceeding: Boolean
  ) {
    offerLead(
      IDCentre: $centreID
      IDLead: $leadID
      BypassExceeding: $BypassExceeding
    ) {
      ID
      fkLeadChild
      fkCentre
      fkProgram
    }
  }
`;

export const EDIT_LEAD_WLTAGS = gql`
  mutation updateLeadTags($leadChildID: Int!, $leadID: Int!, $tagIDs: [Int!]!) {
    updateLeadTags(IDLeadChild: $leadChildID, IDLead: $leadID, IDTags: $tagIDs)
  }
`;

export const UPDATE_LEAD_CHILD = gql`
  mutation updateLeadChildMgmtDetail(
    $leadChildId: Int!
    $leadChildUpdate: LeadChildUpdate!
  ) {
    updateLeadChildMgmtDetail(
      leadChildId: $leadChildId
      leadChildUpdate: $leadChildUpdate
    )
  }
`;

export const UPDATE_LEAD_ENQUIRY_DETAIL = gql`
  mutation UpdateLeadEnquiryDetail(
    $leadID: Int!
    $dateOfEnquiry: Datetime
    $dateOfEnrolment: Datetime
    $programingType: Int
    $levelId: Int
    $bypassExceeding: Boolean
  ) {
    UpdateLeadEnquiryDetail(
      leadId: $leadID
      dateOfEnquiry: $dateOfEnquiry
      dateOfEnrolment: $dateOfEnrolment
      programingType: $programingType
      levelId: $levelId
      bypassExceeding: $bypassExceeding
    ) {
      ID
    }
  }
`;

export const APPROVE_LEAD = gql`
  mutation assignLead(
    $registrationDto: RegistrationDto!
    $BypassExceedingCap: Boolean
  ) {
    assignLead(
      registrationDto: $registrationDto
      BypassExceedingCap: $BypassExceedingCap
    ) {
      ID
    }
  }
`;

export const CANCEL_LEAD = gql`
  mutation cancelLead(
    $leadID: Int!
    $centreID: Int!
    $reasonID: Int!
    $remarks: String
    $BypassExceeding: Boolean
  ) {
    cancelLead(
      IDLead: $leadID
      IDCentre: $centreID
      IDCancellationReason: $reasonID
      remarks: $remarks
      BypassExceeding: $BypassExceeding
    ) {
      ID
    }
  }
`;

export const FIND_ALL_POC_USER = gql`
  query findAllPOCUser(
    $centreID: Int
    $searchString: String
    $filter: StaffFilter
    $pagination: Pagination
  ) {
    findAllPOCUser(
      centreID: $centreID
      searchString: $searchString
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkUser
        staffID
        userByFkUser {
          ID
          firstname
          lastname
        }
      }
    }
  }
`;

export const ADD_INTERACTION = gql`
  mutation addInteraction($fkCentreIds: [Int!], $dto: LeadActivityLogCreate!) {
    addInteraction(fkCentreIds: $fkCentreIds, dto: $dto)
  }
`;

export const GET_MODIFIED_LEAD_VACANCY = gql`
  query getModifiedLeadVacancy(
    $leadID: Int!
    $levelID: Int!
    $programID: Int!
    $centreID: Int!
    $enrolmentDate: Datetime!
  ) {
    getModifiedLeadVacancy(
      IDLead: $leadID
      IDCentre: $centreID
      IDProgram: $programID
      IDLevel: $levelID
      enrolmentDate: $enrolmentDate
    )
  }
`;

export const GET_AVAILABLE_VACANCY = gql`
  query getAvailableVacancy(
    $levelID: Int!
    $programID: Int!
    $centreID: Int!
    $enrolmentDate: Datetime!
  ) {
    getAvailableVacancy(
      levelID: $levelID
      programID: $programID
      centreID: $centreID
      enrolmentDate: $enrolmentDate
    )
  }
`;
