import React from 'react';
import { get } from 'lodash';
import { Trans } from 'react-i18next';
import { Grid } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Loader from 'components/common/Loader';
import UpdateCentrePIMHandler from './UpdateCentrePIM.handler';
import {
  SelectField,
  RadioGroupField,
  LabelField,
  SearchWithMultiSelectField,
} from '../../../common/InputFields';
import Text from '../../../common/Text';
import Button from '../../../common/Button';
import Dialog from '../../../common/Modal';
import {
  CENTRE_EMAIL,
  TECHNICAL_ASSISTANCE,
} from '../../../../utils/constants';

const UpdateCentreConfig = props => {
  const { t, setIsEdit, showLoader } = props;

  const tableHeadLabels = [
    {
      title: t('pim.mainCategory'),
      size: 3,
    },
    {
      title: t('pim.touchpoint'),
      size: 2,
    },
    {
      title: t('pim.subCategory'),
      size: 3,
    },
    {
      title: t('pim.mapping'),
      size: 3,
    },
    {
      title: t('pim.showHide'),
      size: 1,
    },
  ];

  const touchPoints = [
    {
      label: 'high',
      description: 'high',
    },
    {
      label: 'low',
      description: 'low',
    },
  ];

  const {
    centreData,
    tempIconShowValue,
    showModal,
    showModal1,
    showModal2,
    showModal3,
    showModal4,
    setShowModal,
    setShowModal1,
    setShowModal2,
    setShowModal3,
    setShowModal4,
    setTempTouchLevel,
    setTempRole,
    setTempIconShowValue,
    handleSelectChange,
    handleChange,
    handleMappingChange,
    handleRoleChange,
    handleIconClick,
    handleSubmit,
    handleHideSub,
    checkSubCategoryStatus,
  } = UpdateCentrePIMHandler(props);

  const styleDisable = {
    opacity: '0.4',
    pointerEvents: 'none',
  };
  const subDiv = {
    padding: '10px 20px',
  };

  const SubCategory = ({ subCat, category }) => {
    return (
      <Grid
        key={subCat.ID}
        container
        alignItems="center"
        justify="space-between"
        spacing={2}
        style={{
          ...subDiv,
          ...(!get(centreData, 'IsEnabled') || !get(category, 'IsShown')
            ? styleDisable
            : { padding: '10px 20px' }),
        }}
      >
        <Grid item style={subCat.IsShown ? {} : styleDisable}>
          <span>{get(subCat, 'Name')}</span>
        </Grid>
        {subCat.IsShown ? (
          <VisibilityIcon
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={() => {
              if (checkSubCategoryStatus(get(category, 'Categories'))) {
                handleHideSub(false, category.ID, subCat.ID);
              } else {
                setShowModal4(true);
              }
            }}
          />
        ) : (
          <VisibilityOffIcon
            style={{ fontSize: '20px', cursor: 'pointer' }}
            onClick={() => handleHideSub(true, category.ID, subCat.ID)}
          />
        )}
      </Grid>
    );
  };

  const MainCategory = ({ category, idx }) => {
    return (
      <Grid
        item
        xs={12}
        key={`category-${category.ID}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          background: idx % 2 !== 0 && '#F8F8F8',
          padding: '0 20px',
          minHeight: '150px',
        }}
      >
        <Grid item xs={3}>
          <Text
            style={
              !get(centreData, 'IsEnabled') || !category.IsShown
                ? styleDisable
                : {}
            }
            key={`${category.ID}-${category.IsShown}`}
          >
            {get(category, 'Name')}
          </Text>
        </Grid>
        <Grid item xs={2} style={{ paddingRight: '100px' }}>
          {get(category, 'TouchLevel') ? (
            <SelectField
              name={`TouchLevel-${idx}`}
              value={get(category, 'TouchLevel')}
              onChange={e => {
                setTempTouchLevel({
                  value: e.target.value,
                  catId: category.ID,
                });
                setShowModal1(true);
              }}
              options={touchPoints}
              disabled={
                !get(centreData, 'IsEnabled') ||
                !category.IsShown ||
                get(category, 'MappedTo') === CENTRE_EMAIL
              }
            />
          ) : (
            <Text style={styleDisable}>{t('pim.NA')}</Text>
          )}
        </Grid>
        {get(category, 'Categories.length') > 0 ? (
          <Grid item xs={3}>
            {get(category, 'Categories').map(subCat => (
              <SubCategory
                key={subCat.ID}
                subCat={subCat}
                category={category}
              />
            ))}
          </Grid>
        ) : (
          <Grid item xs={3}>
            -
          </Grid>
        )}
        <Grid item xs={3}>
          {get(category, 'TouchLevel') ? (
            <RadioGroupField
              name={`MappedTo-${idx}`}
              key={get(category, 'MappedTo') + idx}
              value={get(category, 'MappedTo')}
              onChange={e => {
                const value = e.target.value;
                if (value === CENTRE_EMAIL) {
                  setTempRole({ value, id: category.ID });
                  setShowModal3(true);
                } else {
                  handleRoleChange(value, category.ID);
                }
              }}
              disabled={!get(centreData, 'IsEnabled') || !category.IsShown}
              options={[
                {
                  label: 'roles',
                  description: (
                    <SearchWithMultiSelectField
                      fieldValue={
                        category.Mapping && category.Mapping.length > 0
                          ? category.Mapping.filter(
                              item =>
                                item.TouchLevel === category.TouchLevel &&
                                item.IsChecked
                            ).map(r => ({
                              value: r.Role.ID,
                            }))
                          : []
                      }
                      hasAllOption
                      name="Role"
                      options={
                        category.Mapping && category.Mapping.length > 0
                          ? category.Mapping.filter(
                              m => m.TouchLevel === category.TouchLevel
                            ).map(item => ({
                              label: item.Role.ID,
                              value: item.Role.ID,
                              description: item.Role.Label,
                              mappingId: item.ID,
                              IsChecked: item.IsChecked,
                            }))
                          : []
                      }
                      onChange={value => {
                        handleMappingChange(value, category.ID);
                      }}
                      disabled={
                        !get(centreData, 'IsEnabled') ||
                        get(category, 'MappedTo') === CENTRE_EMAIL ||
                        !category.IsShown
                      }
                    />
                  ),
                },
                {
                  label: CENTRE_EMAIL,
                  description: 'Centre Mailbox',
                },
              ]}
            />
          ) : (
            <Text style={styleDisable}>{t('pim.freshDesk')}</Text>
          )}
        </Grid>
        {category.Name === TECHNICAL_ASSISTANCE ? (
          <Grid item xs={1}>
            <Text style={{ marginLeft: '5px' }}> - </Text>
          </Grid>
        ) : (
          <Grid
            item
            xs={1}
            style={get(centreData, 'IsEnabled') ? {} : styleDisable}
          >
            {category.IsShown ? (
              <VisibilityIcon
                style={{ marginRight: '5px', cursor: 'pointer' }}
                onClick={() => {
                  setShowModal2(true);
                  setTempIconShowValue({
                    value: false,
                    catId: category.ID,
                  });
                }}
              />
            ) : (
              <VisibilityOffIcon
                style={{ marginRight: '5px', cursor: 'pointer' }}
                onClick={() => {
                  setShowModal2(true);
                  setTempIconShowValue({
                    value: true,
                    catId: category.ID,
                  });
                }}
              />
            )}
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <LabelField
                labelTextColor
                label={t('centre.centreName')}
                value={get(centreData, 'Name')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <LabelField
                labelTextColor
                label={t('common.centreCode')}
                value={get(centreData, 'Code')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <RadioGroupField
                name="IsEnabled"
                label="Parent Interaction"
                showHorizontal
                value={get(centreData, 'IsEnabled') ? '0' : '1'}
                onChange={handleChange}
                options={[
                  {
                    description: 'Enabled',
                    label: '0',
                  },
                  {
                    description: 'Disabled',
                    label: '1',
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container md={12} justify="space-between">
            <Text
              body
              bold
              fontSize="18px"
              className="w3-margin-bottom w3-margin-top"
            >
              {t('pim.categoriesAndMapping')}
            </Text>
          </Grid>
          <Grid
            container
            md={12}
            justifyContent="center"
            className="s7t-overflow s7t-table-overflow"
            style={{ padding: '20px 40px', background: '#FAF7F3' }}
          >
            {tableHeadLabels.map((label, index) => (
              <Grid
                item
                key={index}
                xs={label.size}
                style={{ fontFamily: 'Lato-Regular', fontSize: '16px' }}
              >
                {label.title}
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              {get(centreData, 'Categories', []).map((category, idx) => (
                <MainCategory key={category.ID} category={category} idx={idx} />
              ))}
            </Grid>
          </Grid>
          <hr />
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <Grid>
              <Grid container justify="flex-end" alignItems="center">
                <Grid style={{ paddingRight: '8px' }}>
                  <Button
                    button="secondary"
                    width="120px"
                    data-button
                    onClick={() => setIsEdit(false)}
                  >
                    <Trans i18nKey="common.cancelButton" />
                  </Button>
                </Grid>
                <Grid style={{ paddingLeft: '8px' }}>
                  <Button
                    width="120px"
                    data-button
                    type="submit"
                    onClick={() => setShowModal(true)}
                  >
                    <Trans i18nKey="common.saveChanges" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showModal && (
            <Dialog
              data-testid="dialog-id"
              showClose
              fullWidth
              maxWidth="sm"
              isOpen={showModal}
              dialogTitle={<Text h3>{t('pim.applyChanges')}</Text>}
              dialogContent={
                <>
                  <Text body>{t('pim.centreChange')}</Text>
                  <Text body>{t('pim.centreChangeConfirm')}</Text>
                </>
              }
              onPrimaryClick={() => {
                setShowModal(false);
                handleSubmit('');
              }}
              onSecondaryClick={() => {
                setShowModal(false);
                handleSubmit('all');
              }}
              buttonPrimaryName={t('pim.applyCentre')}
              buttonSecondaryName={t('pim.applyAllCentre')}
              buttonSize="120px"
              onClose={() => setShowModal(false)}
            />
          )}
          {showModal1 && (
            <Dialog
              data-testid="dialog-id1"
              showClose
              fullWidth
              maxWidth="md"
              isOpen={showModal1}
              dialogTitle={<Text h3>{t('pim.touchpointChange')}</Text>}
              dialogContent={
                <>
                  <Text body>{t('pim.touchpointChangeDescription')}</Text>
                  <br />
                  <Text body>{t('pim.touchpointChangeConfirm')}</Text>
                </>
              }
              onPrimaryClick={() => {
                setShowModal1(false);
                handleSelectChange();
              }}
              onSecondaryClick={() => {
                setTempTouchLevel(null);
                setShowModal1(false);
              }}
              buttonPrimaryName="Confirm"
              buttonSecondaryName="Cancel"
              onClose={() => setShowModal1(false)}
            />
          )}
          {showModal3 && (
            <Dialog
              data-testid="dialog-id3"
              showClose
              fullWidth
              maxWidth="md"
              isOpen={showModal3}
              dialogTitle={<Text h3>{t('pim.touchpointChange')}</Text>}
              dialogContent={
                <>
                  <Text body>{t('pim.roleChangeChangeDescription')}</Text>
                  <br />
                  <Text body>{t('pim.touchpointChangeConfirm')}</Text>
                </>
              }
              onPrimaryClick={() => {
                setShowModal3(false);
                handleRoleChange();
              }}
              onSecondaryClick={() => {
                setTempRole(null);
                setShowModal3(false);
              }}
              buttonPrimaryName="Confirm"
              buttonSecondaryName="Cancel"
              onClose={() => {
                setShowModal3(false);
                setTempRole(null);
              }}
            />
          )}
          {showModal2 && (
            <Dialog
              data-testid="dialog-id2"
              showClose
              maxWidth="sm"
              isOpen={showModal2}
              dialogTitle={
                <Text h4>{`${t('common.confirm')} ${
                  tempIconShowValue.value ? 'Show' : 'Hide'
                }`}</Text>
              }
              dialogContent={
                <>
                  <Text body>{`${t('pim.confirmHide')}  ${
                    tempIconShowValue.value ? 'Show' : 'Hide'
                  } ${t('pim.thisEntry')}`}</Text>
                </>
              }
              onPrimaryClick={() => {
                handleIconClick();
                setShowModal2(false);
              }}
              onSecondaryClick={() => {
                setShowModal2(false);
              }}
              buttonSecondaryVariant="text"
              buttonPrimaryVariant="text"
              buttonSize="small"
              primaryButtonStyle={{
                fontWeight: 'bold',
              }}
              secondButtonStyle={{
                fontWeight: 'bold',
              }}
              buttonPrimaryName="Confirm"
              buttonSecondaryName="Cancel"
              customJustifyContent="flex-end"
              onClose={() => setShowModal2(false)}
            />
          )}
          {showModal4 && (
            <Dialog
              data-testid="dialog-id4"
              showClose
              maxWidth="sm"
              isOpen={showModal4}
              dialogContent={
                <>
                  <Text h4>{t('pim.subCategoryWarning')}</Text>
                  <br />
                  <Text body>{t('pim.subCategoryWarningDesc')}</Text>
                </>
              }
              onPrimaryClick={() => {
                setShowModal4(false);
              }}
              onClose={() => setShowModal4(false)}
              contentStyle="w3-padding-16"
              hideSecondaryBtn
              buttonPrimaryVariant="text"
              buttonSize="small"
              primaryButtonStyle={{
                fontWeight: 'bold',
              }}
              buttonPrimaryName={t('common.okay2')}
              customJustifyContent="flex-end"
            />
          )}
        </>
      )}
    </>
  );
};

export default UpdateCentreConfig;
