import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

const ActionWrapper = styled(Grid)`
  margin-top: 1em;

  & .MuiButton-root {
    float: right;
    margin-right: 16px;
  }
`;

export const AlertTextWrapper = styled.div`
  display: block;
  background-color: ${props => (props.isLSH ? '#fdf8ec' : '#f7f2ec')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  color: black !important;
`;

export default ActionWrapper;
