import { get } from 'lodash';
import {
  FETCH_PAYMENT_SESSION,
  FETCH_AVAILABLE_BANKS,
  FETCH_USER_ACCOUNT_LISTING,
  FETCH_ENQUIRY_PAYMENT_DETAILS_BY_ID,
  FETCH_ENQUIRY_MONTHLY_PAYMENT_DETAILS_BY_ID,
  FETCH_ACCOUNT_INFO_BY_ENQUIRY,
  FETCH_ACCOUNT_INFO_BY_CHILD,
  CLEAR_PAYMENT_SESSION,
  SET_GIRO_SETUP_STEP,
  CLEAR_GIRO_SETUP_STEP,
  FETCH_ACCOUNT_INFO_BY_REG,
  FETCH_ACCOUNT_INFO_BY_ENQUIRY_GIRO,
  FETCH_EGIRO_ACCOUNT_INFO_BY_REG,
  FETCH_EGIRO_ACCOUNT_INFO_BY_CHILD,
  FETCH_EGIRO_REDIRECTION,
} from '../actions/actionTypes';

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "getPaymentSession": {
 *       "Id": "1",
 *       "Version": "1",
 *       "UpdateStatus": "SUCCESS",
 *       "SuccessIndicator": "SUCCESS",
 *       "MerchantId": "MERCHANT_ID",
 *       "Order": {
 *         "Id": "122",
 *         "Amount": 1,
 *         "Currency": "SGD",
 *         "Description": "..."
 *       }
 *     }
 *   }
 * }
 *
 */

export const paymentSession = (state = {}, action) => {
  if (action.type === FETCH_PAYMENT_SESSION) {
    const data = get(action, 'value.data.data.getRegistrationPaymentAccess');
    if (data) {
      return Object.assign({}, state, {
        inProgress: action.value.inProgress,
        error: action.value.error,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  if (action.type === CLEAR_PAYMENT_SESSION) {
    return Object.assign({}, state, {
      data: null,
      error: null,
    });
  }

  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findAllBankBicCode": {
 *       "data": [
 *         {
 *           "ID": 1,
 *           "bankCode": "9353",
 *           "bankName": "CHINATRUST COMMERCIAL BANK CO. LTD",
 *           "bicCode": "CTCBSGSGXXX",
 *           "displayOrder": 8
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const availableBanks = (state = {}, action) => {
  if (action.type === FETCH_AVAILABLE_BANKS) {
    const data = get(action, 'value.data.data.findAllBankBicCode.data');

    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *Example data
 * {
 *   "data": {
 *     "getAccountListing": [
 *       {
 *         "AccountName": "Liew Huat Min",
 *         "AccountNumber": "190221232221",
 *         "AccountBankCode": "7339",
 *         "AccountBranchCode": "901"
 *       }
 *     ]
 *   }
 * }
 */

export const userAccounts = (state = {}, action) => {
  if (action.type === FETCH_USER_ACCOUNT_LISTING) {
    const data = get(action, 'value.data.data.getRegistrationAccountListing');
    if (data) {
      const cda = data;
      const nonCda = data;

      return Object.assign({}, state, {
        ...action.value,
        data: {
          cda,
          nonCda,
        },
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

const modelPaymentInvoiceItems = invoiceData => {
  const retArr = [...invoiceData.invoiceItems.data];
  return retArr;
};

/**
 *Example data
 *
 * {
 *   "data": {
 *     "getEnquiryDraftMonthlyFeeInvoice": {
 *       "totalAmount": 1200,
 *       "outstandingAmount": 0,
 *       "status": "draft",
 *       "grandTotal": 1326,
 *       "invoiceItems": {
 *         "data": [
 *           {
 *             "label": "Fee (Infant, Full Day, 01/21)",
 *             "totalAmount": 2086.5,
 *             "displayTotalAmount": "$2086.50"
 *           },
 *         ]
 *       }
 *     }
 *   }
 * }
 */

export const enquiryMonthlyPaymentDetailById = (state = {}, action) => {
  if (action.type === FETCH_ENQUIRY_MONTHLY_PAYMENT_DETAILS_BY_ID) {
    const data = get(
      action,
      'value.data.data.getRegistrationDraftMonthlyFeeInvoice'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data: {
          ID: data.ID,
          invoiceItems: modelPaymentInvoiceItems(data),
        },
        totalAmount: data.totalAmount,
        outstandingAmount: data.outstandingAmount,
        status: data.status,
        grandTotal: data.grandTotal,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *Example data
 *
 * {
 *  "data": {
 *    "getEnquiryRegistrationInvoice": {
 *      "ID": 21588,
 *      "totalAmount": 2150,
 *      "outstandingAmount": 2164,
 *      "invoiceNo": "INV-TC2-2011-000031",
 *      "invoiceDate": "2020-11-13 16:10:34",
 *      "invoiceDueDate": "2020-11-16 16:10:34",
 *      "status": "pending",
 *      "invoiceType": "registration",
 *      "invoiceItems": {
 *        "data": [
 *          {
 *            "ID": 47740,
 *            "label": "Deposit",
 *            "totalAmount": 1950,
 *            "displayTotalAmount": "$1950.00"
 *          },
 *        ]
 *      },
 *      "grandTotal": 2164,
 *      "updatedAt": "2020-11-13 16:10:34"
 *    }
 *  }
 *}
 */

export const regPaymentDetail = (state = {}, action) => {
  if (action.type === FETCH_ENQUIRY_PAYMENT_DETAILS_BY_ID) {
    const data = get(action, 'value.data.data.getRegistrationInvoice');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data: {
          ID: data.ID,
          invoiceItems: modelPaymentInvoiceItems(data),
          invoiceNo: data.invoiceNo,
          status: data.status,
          updatedAt: data.updatedAt,
        },
        totalAmount: data.totalAmount,
        outstandingAmount: data.outstandingAmount,
        status: data.status,
        grandTotal: data.grandTotal,
        invoiceDate: data.invoiceDate,
        invoiceDueDate: data.invoiceDueDate,
        receipts: data.receipts,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 *Example data
 * {
 *  "data": {
 *    "bankAccountInfoByIDEnquiry": [
 *      {
 *        "BankAccount": {
 *          "ID": 1358,
 *          "payerAccountNumber": "860007800112",
 *          "payerAccountName": "Liew Huat Min 2",
 *          "bankBicCode": {
 *            "bankName": "OVERSEA-CHINESE BANKING CORPN LTD"
 *          },
 *          "source": "ocbc_online",
 *          "fkBankBicCode": 27,
 *          "status": "approved",
 *          "billReferenceNumber": "T1231230J",
 *          "isCda": false,
 *          "effectiveFrom": "2020-10-26 16:14:29",
 *          "documentTypes": {
 *            "data": []
 *          },
 *          "updatedAt": "2020-10-26 16:14:29"
 *        },
 *        "GiroApplication": null
 *      },
 *      {
 *        "BankAccount": {
 *          "ID": 1373,
 *          "payerAccountNumber": "21343535666",
 *          "payerAccountName": "bella",
 *          "bankBicCode": {
 *            "bankName": "CHINATRUST COMMERCIAL BANK CO. LTD"
 *          },
 *          "source": "offline",
 *          "fkBankBicCode": 1,
 *          "status": "pending",
 *          "billReferenceNumber": "T1231230J-CDA",
 *          "isCda": true,
 *          "effectiveFrom": null,
 *          "documentTypes": {
 *            "data": [
 *              {
 *                "ID": 2753,
 *                "label": "Giro Application Form",
 *                "fkChild": 1283,
 *                "documentTags": {
 *                  "data": []
 *                }
 *              }
 *            ]
 *          },
 *          "updatedAt": "2020-10-28 18:23:10"
 *        },
 *        "GiroApplication": {
 *          "BankAccountNumber": "21343535666",
 *          "IsCDA": true,
 *          "NameOfAccountHolders": [
 *            "bella"
 *          ],
 *          "MobilePhoneNumber": "96859685",
 *          "MobilePhoneCountryCode": "+65",
 *          "ApplicationDate": "2020-10-28 00:00:00"
 *        }
 *      }
 *    ]
 *  }
 *}
 */

export const accountInfoByEnquiry = (state = {}, action) => {
  if (action.type === FETCH_ACCOUNT_INFO_BY_ENQUIRY) {
    const data = get(action, 'value.data.getBankAccountByFkRegistrationChild');

    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const accountInfoByReg = (state = {}, action) => {
  if (action.type === FETCH_ACCOUNT_INFO_BY_REG) {
    const data = get(action, 'value.data.getBankAccountByFkRegistrationChild');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const accountInfoByEnquiryForGiro = (state = {}, action) => {
  if (action.type === FETCH_ACCOUNT_INFO_BY_ENQUIRY_GIRO) {
    const data = get(action, 'value.data.data.bankAccountInfoByIDEnquiry');

    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const bankAccountByChild = (state = {}, action) => {
  if (action.type === FETCH_ACCOUNT_INFO_BY_CHILD) {
    return Object.assign({}, state, action.value);
  }

  return state;
};

export const giroSetupStepDetail = (state = {}, action) => {
  const defaultState = {
    isBankSelected: false,
    accountType: null,
    bank: null,
  };

  if (action.type === SET_GIRO_SETUP_STEP) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data: {
          ...data,
        },
      });
    }
    return Object.assign({}, state, {
      data: {
        ...defaultState,
      },
    });
  }

  if (action.type === CLEAR_GIRO_SETUP_STEP) {
    return Object.assign({}, state, {
      data: {
        ...defaultState,
      },
    });
  }

  return state;
};

export const egiroAccountByRegChild = (state = {}, action) => {
  if (action.type === FETCH_EGIRO_ACCOUNT_INFO_BY_REG) {
    const data = get(
      action,
      'value.data.getEgiroAccountDetailsByFkRegistrationChild'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const egiroAccountByChild = (state = {}, action) => {
  if (action.type === FETCH_EGIRO_ACCOUNT_INFO_BY_CHILD) {
    const data = get(action, 'value.data.getEgiroBankAccountByChild');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const egiroRedirection = (state = {}, action) => {
  if (action.type === FETCH_EGIRO_REDIRECTION) {
    const data = get(action, 'value.data.getEddaRedirection');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};
