import styled from 'styled-components';
import { Button, CircularProgress, IconButton } from '@material-ui/core';

export const StyledButton = styled(Button)`
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  font-size: ${props => props.theme.fonts.body.fontSize};
  font-family: ${props => props.theme.fonts.body.fontFamily};
  box-shadow: none;
  min-width: ${props => {
    let minWidthValue = '130px';
    if (props.fullWidth) {
      minWidthValue = 'auto';
    } else if (props.size === 'small') {
      minWidthValue = '80px';
    }
    return minWidthValue;
  }};

  border-radius: 6px;
  letter-spacing: 0.35px;
  line-height: 1.25em;
  ${props => {
    if (!props.isNewDesign) {
      if (props.size === 'small') {
        return `min-height: 0px;`;
      }
      return `min-height: 48px;`;
    }
  }}
  ${props => {
    if (!props.isNewDesign) {
      if (props.size === 'rowButton') {
        return `
                  min-height: 32px;
                  font-family: ${props.theme.fonts.bold};
                  font-size: ${props.theme.fonts.smallTitle.fontSize};
              `;
      }

      if (props.size === 'medium') {
        return `
                  width: 200px;
              `;
      }

      if (props.size === 'large') {
        return `
                  width: 300px;
              `;
      }
    }
  }}

  ${props => {
    if (props.isNewDesign) {
      if (props.size === 'rowButton') {
        return `
                  min-height: 32px;
                  font-family: ${props.theme.fonts.bold};
                  font-size: ${props.theme.fonts.smallTitle.fontSize};
                  padding-top: 0px;
                  padding-bottom: 0px;
              `;
      }
      if (props.size === 'small') {
        return `     
                  padding-top: 6px;
                  padding-bottom: 6px;
                  min-width: 80px;
              `;
      }

      if (props.size === 'medium') {
        return `
             
                  padding-top: 9px;
                  padding-bottom: 9px;
                  min-width: 80px;
              `;
      }

      if (props.size === 'large') {
        return `
               
                  padding-top: 14px;
                  padding-bottom: 14px;
                  min-width: 150px;
              `;
      }
    }
  }}

  ${props => {
    if (props.button === 'destructive_secondary') {
      return `
                border: 1px solid ${props.theme.baseColor.status.error};
                color: ${props.theme.baseColor.status.error};

                &.MuiButton-outlinedPrimary:hover {
                    border: 1px solid ${props.theme.baseColor.status.error};
                }
            `;
    }
    if (props.button === 'secondary') {
      return `
                border: 1px solid ${props.theme.palette.primary.main};

                & a {
                    color: ${props.theme.palette.primary.main};
                }
            `;
    }

    if (props.button === 'destructive') {
      return `
                &.MuiButton-contained {
                    background-color: ${props.theme.baseColor.status.error} !important;
                    color: ${props.theme.baseColor.neutral.white};
                }
            `;
    }

    if (props.button === 'text') {
      let iconColor = props.theme.palette.primary.main;
      if (props.color === 'primary') {
        iconColor = props.theme.palette.primary.main;
      }
      if (props.color === 'secondary') {
        iconColor = props.theme.palette.secondary.main;
      }
      if (props.color === 'inherit') {
        iconColor = 'inherit';
      }
      if (props.theme.baseColor.neutral[props.color]) {
        iconColor = props.theme.baseColor.neutral[props.color];
      }
      if (props.theme.baseColor.status[props.color]) {
        iconColor = props.theme.baseColor.status[props.color];
      }
      if (props.theme.baseColor.text[props.color]) {
        iconColor = props.theme.baseColor.text[props.color];
      }
      return `
                color: ${iconColor};
                & .MuiButton-label {
                    color: ${iconColor};
                }
                & svg {
                    width: ${props.theme.iconSize[props.iconSize]};
                    height: ${props.theme.iconSize[props.iconSize]};
                }
            `;
    }

    return `
            & a {
                color: #fff;
            }
        `;
  }}

    &.MuiButton-contained.Mui-disabled {
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.baseColor.neutral.white};
    opacity: 0.5;
  }

  &.MuiButton-outlined.Mui-disabled {
    color: ${props => props.theme.palette.primary.main};
    border: 1px solid ${props => props.theme.palette.primary.main};
    opacity: 0.5;
  }

  &.MuiButton-root a {
    text-decoration: none;
  }
`;

export const Loading = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  margin-left: 10px;
  color: ${props => props.theme.baseColor.text.disabled};
`;

export const ButtonHint = styled.div`
  font-family: ${props => props.theme.fonts.copySmall.fontFamily};
  font-size: ${props => props.theme.fonts.smallTitle.fontSize};
  padding: 8px;
`;

export const StyledIconButton = styled(IconButton)`
  color: ${props => {
    if (props.color === 'primary') {
      return props.theme.palette.primary.main;
    }
    if (props.color === 'secondary') {
      return props.theme.palette.secondary.main;
    }
    if (props.color === 'inherit') {
      return 'inherit';
    }
    if (props.theme.baseColor.neutral[props.color]) {
      return props.theme.baseColor.neutral[props.color];
    }
    if (props.theme.baseColor.status[props.color]) {
      return props.theme.baseColor.status[props.color];
    }
    if (props.theme.baseColor.filter[props.color]) {
      return props.theme.baseColor.filter[props.color];
    }
    return props.theme.palette.primary.main;
  }};
  & svg {
    width: ${props => props.theme.iconSize[props.iconSize]};
    height: ${props => props.theme.iconSize[props.iconSize]};
  }
`;
