import gql from 'graphql-tag';

export const FETCH_PIM_CENTRE_LIST = gql`
  query listPimCentreDetails(
    $searchTerm: PimCentreSearchTerm
    $pagination: Pagination
  ) {
    listPimCentreDetails(searchTerm: $searchTerm, pagination: $pagination) {
      totalCount
      data {
        ID
        Name
        Code
        IsEnabled
        Email
        UpdatedBy
        UpdatedAt
      }
    }
  }
`;

export const FETCH_PIM_CENTRE_DETAILS = gql`
  query getPimCentreDetails($pimCentreID: Int!) {
    getPimCentreDetails(pimCentreID: $pimCentreID) {
      ID
      Name
      IsEnabled
      Code
      Email
      UpdatedBy
      UpdatedAt
      Categories {
        ID
        Name
        Ordering
        MappedTo
        IsShown
        TouchLevel
        Categories {
          ID
          Name
          Ordering
          IsShown
        }
        Mapping {
          ID
          Role {
            ID
            Name
            Label
          }
          IsChecked
          TouchLevel
        }
      }
    }
  }
`;

export const UPDATE_PIM_CENTRE_DETAILS = gql`
  mutation UpdatePimCentreDetails($update: PimCentreDetailsUpdate!) {
    updatePimCentreDetails(update: $update)
  }
`;

export const UPDATE_PIM_CENTRE_DETAILS_BULK = gql`
  mutation updatePimCentreDetailsBulk($update: PimCentreDetailsUpdate!) {
    updatePimCentreDetailsBulk(update: $update)
  }
`;
