import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import get from 'lodash/get';
import { Grid } from '@material-ui/core';
import { SYSTEM_ANNOUNCEMENT } from '../../../../../../routes/constants';
import Button from '../../../../../common/Button';
import {
  TextField,
  DateTimePicker,
  TextArea,
  SearchWithMultiSelectField,
} from '../../../../../common/InputFields';

const SystemAnnouncementForm = ({
  values,
  handleChange,
  touched,
  errors,
  t,
  isSubmitting,
  isEdit,
  allSystemAnnouncementTargets,
  handleTargets,
  targets,
}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <TextField
              name="title"
              label={t('common.title')}
              placeholder="Title"
              value={values.title}
              onChange={handleChange}
              required
              error={get(touched, 'title') && get(errors, 'title')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={8}>
            <TextArea
              name="message"
              label={t('common.message')}
              placeholder="Message"
              rows={6}
              variant="outlined"
              value={values.message}
              onChange={handleChange}
              required
              error={get(touched, 'message') && get(errors, 'message')}
            />
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <SearchWithMultiSelectField
            required
            hasAllOption
            noBackground
            fieldValue={targets}
            label="Target"
            name="target"
            options={allSystemAnnouncementTargets.map(item => ({
              value: item.ID,
              description: item.displayName,
            }))}
            onChange={value => handleTargets(value)}
            error={get(touched, 'target') && get(errors, 'target')}
          />
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <DateTimePicker
                required
                label={t('common.from')}
                placeholder="from"
                value={values.from}
                name="from"
                handleDateChange={handleChange}
                error={get(touched, 'from') && get(errors, 'from')}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                type="time"
                label={t('common.startTime')}
                name="startTime"
                value={values.startTime || null}
                handleDateChange={handleChange}
                error={get(touched, 'startTime') && get(errors, 'startTime')}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <DateTimePicker
                required
                label={t('common.to')}
                placeholder="to"
                value={values.to}
                name="to"
                handleDateChange={handleChange}
                error={get(touched, 'to') && get(errors, 'to')}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                type="time"
                label={t('common.endTime')}
                name="endTime"
                value={values.endTime || null}
                handleDateChange={handleChange}
                error={get(touched, 'endTime') && get(errors, 'endTime')}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid>
          <Grid container justify="flex-end" alignItems="center">
            <Grid style={{ paddingRight: '8px' }}>
              <Button
                button="secondary"
                width="120px"
                data-button
                link={SYSTEM_ANNOUNCEMENT}
                disabled={isSubmitting}
              >
                <Trans i18nKey="common.cancelButton" />
              </Button>
            </Grid>
            <Grid style={{ paddingLeft: '8px' }}>
              <Button
                width="120px"
                data-button
                type="submit"
                loading={isSubmitting}
              >
                {isEdit ? (
                  <Trans i18nKey="common.update" />
                ) : (
                  <Trans i18nKey="common.create" />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withTranslation()(SystemAnnouncementForm);
