import {
  SplitSdk,
  SplitFactory,
  SplitClient,
} from '@splitsoftware/splitio-react';
import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

export default class SplitReactSDK {
  static key = 'anonymous';

  static _factory;

  static config = {
    core: {
      authorizationKey: process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY,
      key: this.key,
    },
    impressionListener: {
      logImpression(data) {
        datadogRum.addFeatureFlagEvaluation(
          data.impression.feature,
          data.impression.treatment
        );
      },
    },
  };

  static get client() {
    return this._factory.client(this.key);
  }

  static init({ debug = false, streamingEnabled = false } = {}) {
    this.config = { ...this.config, debug, streamingEnabled };
    this._factory = SplitSdk(this.config);
  }

  static setUserId(userId) {
    this.key = userId;
  }
}

export const SplitReact = ({ children }) => {
  const user = useSelector(state => get(state, 'userDetails.data', {}));
  const userId = get(user, 'ID', 'anonymous');
  SplitReactSDK.setUserId(userId);

  const attributes = {
    platform: 'sn-frontend',
    email: get(user, 'email', ''),
    role: get(user, 'userRoleRelations.data[0].role.name', ''),
    isStaff: get(user, 'userRoleRelations.data[0].role.isStaff', false),
    centerIDs: (get(user, 'userAccessControls.data', []) || [])
      .map(c => get(c, 'centre.ID'))
      .filter(id => id)
      .map(d => d.toString()),
  };

  return (
    <SplitFactory
      factory={SplitReactSDK._factory}
      updateOnSdkReady
      updateOnSdkTimedout
      updateOnSdkUpdate
    >
      <SplitClient splitKey={userId} attributes={attributes}>
        {children}
      </SplitClient>
    </SplitFactory>
  );
};
