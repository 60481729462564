import gql from 'graphql-tag';

const ACCOUNT_INFO = `{
    BankAccount {
      ID
      childName
      childBirthCertificate
      child {
        firstname
        lastname
        birthCertificate
      }
      payerAccountNumber
      payerAccountName
      bankBicCode {
        bankName
        ID
      }
      bankAccountStateHistories {
        data {
          updatedAt
        }
      }
      source
      fkBankBicCode
      status
      accountType
      remarks
      billReferenceNumber
      transactionNumber
      source
      active
      isCda
      isSiblingCda
      effectiveFrom
      updatedAt
      createdAt
      documentTypes {
        data {
          ID
          label
          documentTypeConfig {
            ID
          }
          documentTags {
            data {
              document {
                ID
                filename
                uRL
                preSignedUrl
              }
            }
          }
        }
      }
    }
    GiroApplication {
      BankAccountNumber
      IsCDA
      NameOfAccountHolders
      MobilePhoneNumber
      MobilePhoneCountryCode
      ApplicationDate
    }
  }
`;

export const FIND_ACCOUNT_INFO_BY_ENQUIRY_ID = gql`
    query getBankAccountByFkRegistrationChild($regChildId: Int!) {
      getBankAccountByFkRegistrationChild(regChildId: $regChildId)
        ${ACCOUNT_INFO}
    }
`;

export const FIND_ACCOUNT_INFO_BY_CHILD_ID = gql`
    query getBankAccountByFkRegistrationChild($regChildId: Int!) {
      getBankAccountByFkRegistrationChild(regChildId: $regChildId)
        ${ACCOUNT_INFO}
    }
`;

export const GET_ACCOUNT_LISTING = gql`
  query($isCDA: Boolean!, $paymentType: String!) {
    getRegistrationAccountListing(isCDA: $isCDA, paymentType: $paymentType) {
      AccountName
      AccountNumber
      AccountBankCode
      AccountBranchCode
      AccountType
    }
  }
`;

export const ADD_GIRO = gql`
  mutation($giroAccount: NewGiroDto!, $paymentType: String!) {
    addRegistrationGiro(giroAccount: $giroAccount, paymentType: $paymentType)
  }
`;

export const MARK_GIRO_AS_COMPLETED = gql`
  mutation($IDRegistration: Int!) {
    markGiroAsCompleted(IDRegistration: $IDRegistration)
  }
`;

export const GET_PAYNOW_UEN_BY_SCHOOL = gql`
  query($schoolID: Int!) {
    getPayNowUENBySchool(schoolID: $schoolID) {
      uen
      qrCodeURL
    }
  }
`;

export const EXPORT_GIRO_PDF = gql`
  mutation($IDBankAccount: Int!, $centreId: Int!) {
    exportGiroApplicationPdf(IDBankAccount: $IDBankAccount, centreId: $centreId)
  }
`;

export const EMAIL_GIRO_PDF = gql`
  mutation(
    $IDBankAccount: Int!
    $centreId: Int!
    $childId: Int
    $IDRegChild: Int
  ) {
    emailGiroApplicationPdf(
      IDBankAccount: $IDBankAccount
      centreId: $centreId
      childId: $childId
      IDRegChild: $IDRegChild
    )
  }
`;

export const PAY_REGISTRATION_INVOICE_BY_GIRO = gql`
  mutation($IDRegChild: Int!, $IDInvoice: Int!) {
    payRegistrationInvoiceByGiro(IDRegChild: $IDRegChild, IDInvoice: $IDInvoice)
  }
`;

export const INITIATE_REFUND = gql`
  mutation(
    $IDRegistration: Int!
    $isCDA: Boolean!
    $isNonCDA: Boolean!
    $remarks: String!
  ) {
    initiateRefundForCancelledRegistration(
      IDRegistration: $IDRegistration
      isCDA: $isCDA
      isNonCDA: $isNonCDA
      remarks: $remarks
    )
  }
`;

export const GET_PAYMENT_ACCESS = gql`
  query($authCode: String!, $paymentType: String!, $sessionToken: String!) {
    getRegistrationPaymentAccess(
      authCode: $authCode
      paymentType: $paymentType
      sessionToken: $sessionToken
    )
  }
`;

export const EGIRO_DETAILS_BY_REG_CHILD = gql`
  query getEgiroAccountDetailsByFkRegistrationChild($registrationChildId: Int!) {
    getEgiroAccountDetailsByFkRegistrationChild(registrationChildId: $registrationChildId)
      ${ACCOUNT_INFO}
  }
`;

export const EGIRO_DETAILS_BY_CHILD = gql`
  query getEgiroBankAccountByChild($childId: Int!) {
    getEgiroBankAccountByChild(childId: $childId)
      ${ACCOUNT_INFO}
  }
`;

export const EDDA_REDIRECTION = gql`
  query getEddaRedirection(
    $childID: Int
    $registrationChildID: Int
    $userID: Int!
    $centreId: Int!
    $billingRefNumber: String
    $applicantBankCode: String!
  ) {
    getEddaRedirection(
      childID: $childID
      registrationChildID: $registrationChildID
      userID: $userID
      centreId: $centreId
      billingRefNumber: $billingRefNumber
      applicantBankCode: $applicantBankCode
    )
  }
`;

export const UPDATE_TRANSACTION_STATUS = gql`
  mutation updateTransactionStatus(
    $transactionNumber: String!
    $status: String!
    $childId: Int
    $registrationChildId: Int
  ) {
    updateTransactionStatus(
      transactionNumber: $transactionNumber
      status: $status
      childId: $childId
      registrationChildId: $registrationChildId
    )
  }
`;

export const ADD_BANK_ACCOUNT_FOR_REFUND = gql`
  mutation(
    $IDChild: Int
    $IDRegChild: Int
    $IDSchool: Int!
    $application: GiroApplication!
    $isSiblingCda: Boolean
  ) {
    addBankAccountForRefund(
      IDChild: $IDChild
      IDRegChild: $IDRegChild
      IDSchool: $IDSchool
      application: $application
      isSiblingCda: $isSiblingCda
    ) {
      ID
      documentTypes {
        data {
          ID
          documentTypeConfig {
            ID
          }
        }
      }
    }
  }
`;
