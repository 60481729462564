import { createHttpLink } from 'apollo-link-http';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { checkNetworkStatus } from 'check-network-status';
import { get, includes } from 'lodash';
import gql from 'graphql-tag';
import { isContainKey } from 'utils';
import DatadogHandler from './datadog';

import LocalStore from './localStorage';
import { parseGraphQLError } from '.';
import {
  SERVER_UNAVAILABLE_MSG,
  REQUEST_TIMEOUT_CODE,
  NOT_RESPONDING_CODE,
  IGNORE_ERROR_MESSAGE_APIS,
  UNAUTHORISED_LINKPASS_CODE,
} from './constants';
import { SHOW_SNACKBAR_MESSAGE } from '../redux/actions/actionTypes';
import i18n from '../i18n';
import { logoutUser } from './authUtil';

const cache = new InMemoryCache();
const userToken = LocalStore.getValue('userToken');
const headers = {};
if (userToken) {
  headers.authorization = `Bearer ${userToken}`;
}
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_REACT_API_URL,
  headers,
});

export const errorCodeErrorMessageMap = {
  [REQUEST_TIMEOUT_CODE]: 'error.timeoutError',
  [NOT_RESPONDING_CODE]: 'error.notRespondingError',
};

export const showSnackBarErrorMessage = errorMsgKey => {
  window.gSkoolstore.dispatch({
    type: SHOW_SNACKBAR_MESSAGE,
    key: 'snackbarMessage',
    value: {
      message: i18n.t(errorMsgKey),
      variant: 'error',
      priority: 'low',
      icon: null,
      anchor: 'top',
    },
  });
};

export const handleErrorMsgInterceptor = (
  errorCode,
  errorMsg,
  operationName
) => {
  // Ignore error message for some api endpoints
  if (isContainKey(IGNORE_ERROR_MESSAGE_APIS, operationName)) {
    return;
  }

  if (errorCodeErrorMessageMap[errorCode]) {
    showSnackBarErrorMessage(errorCodeErrorMessageMap[errorCode]);
    return;
  }

  if (includes(SERVER_UNAVAILABLE_MSG, errorMsg))
    showSnackBarErrorMessage('error.networkError');
};

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      if (
        get(graphQLErrors, '0.extensions.errorCode') ===
        UNAUTHORISED_LINKPASS_CODE
      ) {
        showSnackBarErrorMessage('auth.linkpassNotAvailableError');
        setTimeout(() => {
          logoutUser();
        }, 1500);
        return;
      }

      graphQLErrors.forEach(({ message, extensions }) => {
        let errorMessage = message;

        if (extensions) {
          errorMessage = get(extensions, 'errorCode');
        }
        DatadogHandler.sendLog(
          new Error(`Operation: ${operation.operationName} - ${errorMessage}`),
          {
            operation: operation.operationName,
            variables: operation.variables,
            cause: get(extensions, 'cause'),
          },
          'error'
        );
      });
    }

    if (networkError) {
      checkNetworkStatus().then(status => {
        if (!status) {
          const networkErrorCode = get(networkError, 'statusCode');
          const networkErrorMsg = get(networkError, 'message');

          handleErrorMsgInterceptor(
            networkErrorCode,
            networkErrorMsg,
            operation.operationName
          );
        }
      });

      DatadogHandler.sendLog(networkError, {}, 'error');
    }
  }
);

const link = ApolloLink.from([errorLink, httpLink]);

const isMutationFailed = result => {
  const nameOfMutation = Object.keys(get(result, 'data', {}))[0];
  return get(result, `data[${nameOfMutation}]`) === false;
};

export const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: { errorPolicy: 'all' },
};

const __Client = () => {
  const client = new ApolloClient({
    link,
    cache,
    defaultOptions,
  });

  return client;
};

const getSchemaReqDataMap = (key, args) => {
  const newObj = {};
  args.forEach(eachArgument => {
    newObj[`${key}${get(eachArgument, 'key', '')}`] = eachArgument;
    delete eachArgument.key;
  });
  return newObj;
};

const evalStrParam = str => {
  if (str.indexOf('@enum:') === 0) {
    return `${str.replace('@enum:', '')}`;
  }

  return `"${str}"`;
};

const requestParamsToString = (reqParams = null) => {
  let rStr = '';
  const rKeys = Object.keys(reqParams);

  rKeys.forEach(eachKey => {
    if (
      reqParams[eachKey] !== null &&
      typeof reqParams[eachKey] === 'object' &&
      Object.keys(reqParams[eachKey]).length &&
      Object.keys(reqParams[eachKey])[0] === '0'
    ) {
      rStr += `${eachKey}: [ `;

      reqParams[eachKey].forEach(arrItem => {
        if (
          arrItem &&
          typeof arrItem === 'object' &&
          typeof arrItem.length === 'undefined'
        ) {
          rStr += ` { ${requestParamsToString(arrItem)} }, `;
        } else if (arrItem && typeof arrItem === 'object') {
          rStr += `${requestParamsToString(arrItem)},`;
        } else if (arrItem && typeof arrItem === 'string') {
          rStr += `${evalStrParam(arrItem)}, `;
        } else {
          rStr += `${arrItem}, `;
        }
      });
      rStr += '],';
    } else if (
      reqParams[eachKey] !== null &&
      typeof reqParams[eachKey] === 'object'
    ) {
      if (reqParams[eachKey].length === 0) {
        rStr += `${eachKey}: []`;
      } else {
        rStr += `${eachKey}: { ${requestParamsToString(
          reqParams[eachKey]
        )} }, `;
      }
    } else if (typeof reqParams[eachKey] === 'string') {
      rStr += `${eachKey}: ${evalStrParam(reqParams[eachKey])}, `;
    } else {
      rStr += `${eachKey}: ${reqParams[eachKey]}, `;
    }
  });
  return rStr;
};

const generateGraphQLReqString = (queryKey, query, args, result) => {
  let str = '';
  Object.keys(args).forEach(key => {
    const paramString = requestParamsToString(args[key]);
    str += `${queryKey || key} : ${query}(${paramString}) ${result}`;
  });
  return str;
};

export const generateBulkQuerySchema = (reqData, type) => {
  let resultQueryString = '';
  reqData.forEach(api => {
    const eachSchemaReqDataMap = getSchemaReqDataMap(api.key, api.variables);
    const eachSchema = generateGraphQLReqString(
      api.queryKey,
      api.key,
      eachSchemaReqDataMap,
      api.query
    );
    resultQueryString += eachSchema;
  });

  return gql`${type} { ${resultQueryString} }`;
};

export const handleResponseForApollo = (resp, type = 'query') => {
  const retResp = {
    success: false,
    data: null,
    error: ['Something went wrong'],
  };
  if (get(resp, 'data.errors')) {
    retResp.error = parseGraphQLError(resp.data.errors);
  } else if (get(resp, 'data.error')) {
    retResp.error = parseGraphQLError(resp.data.error);
  } else if (resp.errors) {
    retResp.error = parseGraphQLError(resp.errors);
  } else if (type === 'mutation' && isMutationFailed(resp)) {
    retResp.error = null;
  } else {
    retResp.success = true;
    retResp.data = resp.data;
    retResp.error = null;
  }

  return retResp;
};

export const SkApolloRequest = async ({ params = {}, type }) => {
  const client = await __Client();
  let result = null;
  try {
    switch (type) {
      case 'mutation':
        result = await client.mutate(params);
        break;
      case 'bulkQuery':
        result = await client.query({
          query: generateBulkQuerySchema(params, 'query'),
        });
        break;
      case 'bulkMutation':
        result = await client.mutate({
          mutation: generateBulkQuerySchema(params, 'mutation'),
        });
        break;
      default:
        result = await client.query(params);
        break;
    }
    return handleResponseForApollo(result, type);
  } catch (ex) {
    return handleResponseForApollo({
      data: {
        errors: [
          {
            message: ex.message,
          },
        ],
      },
    });
  }
};

export default __Client;
